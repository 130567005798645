<script>
//VUEX
import { mapActions } from "vuex";
// COMPONENTS
import EditDetails from "@/components/EditDetails";
import Modal from "@/components/design/Modal.vue";
import { DATES } from "@/constants";
// UTILS
import Error from "@/utils/Error";

export default {
  name: "ProgramCreate",
  components: {
    EditDetails,
    Modal,
  },
  props: {
    program: Object,
  },
  data() {
    return {
      error: [],
      id: null,
      form: {
        name: "",
        startDate: "",
        endDate: "",
      },
      fields: [
        {
          id: "name",
          label: "Program Name",
          type: "text",
        },
        {
          id: "startDate",
          label: "Start Date",
          type: "date",
        },
        {
          id: "endDate",
          label: "End Date",
          type: "date",
        },
      ],
      disabled: ["startDate"],
    };
  },
  methods: {
    ...mapActions({
      terminateProgram: "program/terminateProgram",
    }),
    prePopulateForm() {
      this.form = {
        name: this.program.name,
        startDate: this.program.startDate,
        endDate: this.program.endDate,
      };
    },
    validateInput() {
      if (this.form.endDate && this.form.endDate < this.form.startDate) {
        this.error.push(
          new Error(
            "endDate",
            "End date needs to be later than the start date."
          )
        );
      }
      if (this.error.length > 0) return false;
      return true;
    },
    triggerCloseOverlay() {
      this.$emit("cancel");
    },
    triggerTerminatePopup() {
      this.error = [];
      if (this.validateInput()) {
        this.$swal({
          title: "Are you sure?",
          text: "All related donations will be terminated at the same date. You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText:
            "Yes, terminate program & donations at provided date!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.terminateProgram({ id: this.id, endDate: this.form.endDate });
            this.$emit("saved");
          }
        });
      }
    },
    setEndDateToday() {
      this.form.endDate = DATES.today;
    },
  },

  mounted() {
    this.id = this.program.id;
    this.prePopulateForm();
  },
};
</script>
<template>
  <div>
    <Modal @close="() => this.$emit('cancel')">
      <EditDetails
        title="Terminate Program"
        :fields="fields"
        :form="form"
        :errorProp="error"
        :disabled="disabled"
        :showLabelCancel="false"
        @update="(values) => (this.form = values)"
        @submit="triggerTerminatePopup"
        @cancel="triggerCloseOverlay"
        ><div
          class="underline text-red-default hover:font-bold cursor-pointer mt-4 ml-2"
          @click.prevent="setEndDateToday"
        >
          {{ $t("programs.terminateToday") }}
        </div>
      </EditDetails>
    </Modal>
  </div>
</template>
