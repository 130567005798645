<script>
import BasicLayout from "@/components/layout/BasicLayout";

import { mapGetters } from "vuex";
import { ROUTEPATHS } from "../../constants";

export default {
  name: "AdminDashboard",
  components: {
    BasicLayout,
  },
  data() {
    return {
      formType: "user",
      tabs: [
        {
          id: "user",
          title: "Account Details",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      activeTab: "ui/activeTab",
    }),
  },
  methods: {
    redirectLogout() {
      this.$router.push(ROUTEPATHS.guest.logout);
    },
    showForm(type) {
      this.formType = type;
    },
    jumpToTab(id) {
      this.activeTab = this.activeTab === id ? "" : id;
      this.showForm(id);
    },
  },
  watch: {
    activeTab(newValue, oldValue) {
      if (newValue !== oldValue && newValue) this.showForm(newValue);
    },
  },
};
</script>

<template>
  <BasicLayout
    :isLoading="isLoading || !user.email"
    title="Nothing implemented"
    :hideSidebar="true"
  >
    <div class="flex gap-8 flex-wrap cursor-default"></div>
  </BasicLayout>
</template>
