<script>
import Overlay from "../layout/Overlay.vue";
export default {
  name: "LoadingSpinner",
  components: {
    Overlay,
  },
  props: {
    color: {
      type: String,
      default: "red-default",
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    sizeClasses() {
      switch (this.size) {
        case "sm":
          return "h-6 w-6";
        case "md":
          return "h-10 w-10";
        case "lg":
          return "h-18 w-18";
        case "xl":
          return "h-32 w-32";
        default:
          return "h-10 w-10";
      }
    },
  },
};
</script>
<template>
  <div v-if="size !== 'full'" class="flex justify-center items-center">
    <div
      :class="[
        `${sizeClasses} animate-spin rounded-full  border-b-2 border-${color}`,
      ]"
    ></div>
  </div>
  <Overlay v-else :showClose="false">
    <div class="flex justify-center items-center w-full h-full">
      <div
        :class="[
          `h-32 w-32 animate-spin rounded-full m-auto border-b-2 border-${color}`,
        ]"
      ></div>
    </div>
  </Overlay>
</template>

<style scoped></style>
