import ProgramService from "../../services/ProgramService";

const state = {
  data: null,
  participants: null,
  isLoading: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
  error: {
    get: null,
    store: null,
    update: null,
    delete: null,
  },
  success: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
};

const getters = {
  data: (state) => state.data,
  participants: (state) => state.participants,
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
  success: (state) => state.success,
};

const actions = {
  async createProgram({ commit }, payload) {
    commit("SET_IS_LOADING", { store: true });
    try {
      await ProgramService.createProgram(payload);
      commit("SET_PROGRAM_SUCCESS", { store: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { store: e });
    } finally {
      commit("SET_IS_LOADING", {
        store: false,
      });
    }
  },

  async addParticipants({ commit }, { programId, employees }) {
    commit("SET_PROGRAM_SUCCESS", { update: false });
    commit("SET_IS_LOADING", { update: true });
    try {
      await ProgramService.addParticipants(programId, employees);
      commit("SET_PROGRAM_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { update: e });
    } finally {
      commit("SET_IS_LOADING", { update: false });
    }
  },

  async updateProgram({ commit }, { id, data }) {
    commit("SET_IS_LOADING", { update: true });
    try {
      await ProgramService.updateProgram(id, data);
      commit("SET_PROGRAM_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { update: e });
    } finally {
      commit("SET_IS_LOADING", { update: false });
    }
  },

  async terminateProgram({ commit }, { id, endDate }) {
    commit("SET_IS_LOADING", { update: true });
    try {
      await ProgramService.terminateProgram(id, endDate);
      commit("SET_PROGRAM_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { update: e });
    } finally {
      commit("SET_IS_LOADING", { update: false });
    }
  },

  async getProgram({ commit }, id) {
    commit("SET_IS_LOADING", { get: true });
    try {
      const response = await ProgramService.getProgram(id);
      commit("SET_PROGRAM_DATA", response);
      commit("SET_PROGRAM_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async getActiveProgram({ commit }, id) {
    commit("SET_IS_LOADING", { get: true });
    try {
      const response = await ProgramService.getActiveProgram(id);
      commit("SET_PROGRAM_DATA", response.data);
      commit("SET_PROGRAM_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async getAllPrograms({ commit }, filters) {
    commit("SET_IS_LOADING", { get: true });
    try {
      const response = await ProgramService.getAllPrograms(filters);
      commit("SET_PROGRAM_DATA", response);
      commit("SET_PROGRAM_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async getAllParticipants({ commit }, queryParamsObj) {
    commit("SET_PROGRAM_SUCCESS", { get: false });
    commit("SET_IS_LOADING", { get: true });
    try {
      const response = await ProgramService.getAllParticipants(queryParamsObj);
      commit("SET_PROGRAM_PARTICIPANTS", response);
      commit("SET_PROGRAM_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async deleteProgram({ commit }, id) {
    commit("SET_IS_LOADING", { delete: true });
    try {
      await ProgramService.deleteProgram(id);
      commit("SET_PROGRAM_SUCCESS", { delete: true });
    } catch (e) {
      commit("SET_PROGRAM_ERROR", { delete: e });
    } finally {
      commit("SET_IS_LOADING", { delete: false });
    }
  },

  resetError({ commit }) {
    commit("SET_PROGRAM_ERROR", {
      get: null,
      store: null,
      update: null,
      delete: null,
    });
  },
  resetSuccess({ commit }) {
    commit("SET_PROGRAM_SUCCESS", {
      get: false,
      store: false,
      update: false,
      delete: false,
    });
  },
};

const mutations = {
  SET_PROGRAM_DATA: (state, data) => (state.data = data),
  SET_PROGRAM_PARTICIPANTS: (state, data) => (state.participants = data),
  SET_PROGRAM_SUCCESS: (state, success) =>
    (state.success = { ...state.success, ...success }),
  SET_PROGRAM_ERROR: (state, error) =>
    (state.error = { ...state.error, ...error }),
  SET_IS_LOADING: (state, loading) =>
    (state.isLoading = { ...state.isLoading, ...loading }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
