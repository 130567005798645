<script>
import { mapGetters } from "vuex";
import Table from "@/components/Table.vue";

export default {
  name: "EmployeeIndex",
  components: {
    Table,
  },
  data() {
    return {
      icons: {
        trash: require("@/assets/icons/trash.svg"),
        edit: require("@/assets/icons/pencil-square.svg"),
        checked: require("@/assets/icons/task-checked.svg"),
      },
      headers: ["Employee Id", "email", "Is Participant"],
    };
  },
  computed: {
    ...mapGetters({
      company: "company/data",
      employees: "employee/data",
    }),
  },
  methods: {
    editEmployee(id) {
      this.$emit("edit", id);
    },
    deleteEmployee(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove employee!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("delete", id);
        }
      });
    },
  },
};
</script>
<template>
  <div class="w-full" v-if="employees.length > 0">
    <Table :headers="headers" :actionCols="1">
      <template v-slot:content>
        <tr
          :class="`${
            employee.deleted ? 'pointer-events-none text-gray-300' : ''
          } text-center align-middle`"
          v-for="employee in employees"
          :key="employee.name"
        >
          <td>{{ employee.companyDetails.employeeId }}</td>
          <td>{{ employee.email }}</td>
          <td class="text-center">
            <span
              class="text-green-default font-bold text-xl"
              v-if="employee.currentParticipant"
            >
              ✓
            </span>
          </td>
          <td>
            <div class="flex" v-if="!employee.deleted">
              <div @click.prevent="editEmployee(employee.id)">
                <img :src="icons.edit" class="h-5 cursor-pointer" />
              </div>
              <div class="ml-2" @click.prevent="deleteEmployee(employee.id)">
                <img :src="icons.trash" class="h-5 cursor-pointer" />
              </div>
            </div>
            <div v-else>{{ $t("employees.deleted") }}</div>
          </td>
        </tr>
      </template>
    </Table>
  </div>
</template>
