<script>
// THIRD PARTY
import { mapGetters } from "vuex";
export default {
  name: "ProgramInfo",
  computed: {
    ...mapGetters({
      programDetails: "program/data",
    }),
  },
};
</script>
<template>
  <div class="flex flex-col my-4">
    <div>
      {{ $t("charities.select.programInfo.nameLabel") }}:
      <span class="font-bold">{{
        programDetails ? programDetails.name : "..."
      }}</span>
    </div>
    <div>
      {{ $t("charities.select.programInfo.start") }}:
      <span class="font-bold">{{
        programDetails ? programDetails.startDate : "..."
      }}</span>
    </div>
    <div v-if="programDetails && programDetails.endDate">
      &#x2192;
      <span class="font-bold">{{
        programDetails && programDetails.endDate
          ? programDetails.endDate
          : "..."
      }}</span>
    </div>

    <div>
      {{ $t("charities.select.programInfo.matchingPercentage") }}:
      <span class="font-bold">{{
        programDetails ? programDetails.matchingPercentage + " %" : "..."
      }}</span>
    </div>
    <div>
      {{ $t("charities.select.programInfo.maxDonation") }}:
      <span class="font-bold"
        >{{ programDetails ? programDetails.maxDonation : "..." }} €</span
      >
    </div>
  </div>
</template>
