<script>
import Error from "@/utils/Error";
import { EMAIL_REGEX, MOBILE_REGEX, isLoggedInLocalStorage } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import BasicLayout from "../components/layout/BasicLayout.vue";
import EditDetails from "../components/EditDetails.vue";
import SignupSuccess from "../components/signup/SignupSuccess.vue";
import TitleWithSub from "../components/layout/TitleWithSub.vue";

export default {
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  name: "Signup",
  components: {
    BasicLayout,
    EditDetails,
    SignupSuccess,
    TitleWithSub,
  },
  data() {
    return {
      error: [],
      success: false,
      form: {
        company_name: "",
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      fields: [
        {
          id: "company_name",
          label: "Company name",
          type: "text",
        },
        {
          id: "name",
          label: "Your name",
          type: "text",
        },
        {
          id: "email",
          label: "Email",
          type: "email",
        },
        {
          id: "phone",
          label: "Phone number",
          type: "text",
        },
        {
          id: "password",
          label: "Password",
          type: "password",
        },
        {
          id: "password_confirmation",
          label: "Repeat password",
          type: "password",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      signUpSuccess: "user/success",
      signUpError: "user/error",
      isLoading: "user/isLoading",
    }),
  },
  watch: {
    signUpSuccess(newValue, oldValue) {
      if (newValue.store && oldValue.store === false) {
        this.success = true;
      }
    },
    signUpError(newValue) {
      if (newValue.store) {
        this.triggerToast("error", newValue.store, null);
      }
    },
  },
  methods: {
    ...mapActions({
      register: "user/register",
      logout: "user/logout",
    }),
    updateValue(id, value) {
      this.form[id] = value;
    },

    validateInput() {
      this.error = [];
      for (const [key, value] of Object.entries(this.form)) {
        if (key.includes("password") && value.length < 4) {
          this.error = [
            ...this.error,
            new Error(key, "Password should be at least 4 characters long"),
          ];
        } else if (value.length < 3) {
          this.error = [
            ...this.error,
            new Error(key, "Please provide at least 3 characters"),
          ];
        }
      }

      if (!MOBILE_REGEX.test(this.form.phone)) {
        this.error = [
          ...this.error,
          new Error("phone", "Please provide a valid phone number"),
        ];
      }
      if (!EMAIL_REGEX.test(this.form.email))
        this.error = [
          ...this.error,
          new Error("email", "Please provide a valid email address"),
        ];
      if (this.form.password !== this.form.password_confirmation)
        this.error = [
          ...this.error,
          new Error("password_confirmation", "Your passwords do not match."),
        ];
      if (this.error.length > 0) return false;
      return true;
    },
    validateAndSubmit() {
      if (this.validateInput()) {
        this.submit();
      }
    },

    async submit() {
      if (isLoggedInLocalStorage()) {
        await this.logout();
      }
      this.register({ ...this.form, role: "company" });
    },
  },
};
</script>

<template>
  <BasicLayout :hideSidebar="true" contentWrapperProps="bg-orange-light">
    <div class="max-width w-full content-wrapper-x min-h-screen h-full flex-1">
      <TitleWithSub
        :title="$t('signup.title')"
        :subTitle="$t('signup.subTitle')"
      />
      <transition name="slide-left" mode="out-in">
        <EditDetails
          :isLoading="isLoading.store"
          v-if="!success"
          :btn="{
            cancel: false,
            submit: true,
          }"
          inputClasses="bg-white"
          :gridCols="2"
          titleBtn="Sign up"
          :fields="fields"
          :form="form"
          :errorProp="error"
          @update="(values) => (this.form = values)"
          @submit="validateAndSubmit"
        >
          <template v-slot:after-form>
            <div class="mt-4">
              <span>{{ $t("signup.login") }} </span>
              <router-link to="/login">
                {{ $t("signup.loginLink") }}
              </router-link>
            </div>
          </template>
        </EditDetails>
        <SignupSuccess v-else key="success" />
      </transition>
    </div>
  </BasicLayout>
</template>
