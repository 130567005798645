const state = {
  isLoading: false,
  screenSize: "",
  activeTab: "user",
  showError: false,
};

const getters = {
  isLoading: (state) => state.isLoading,
  activeTab: (state) => state.activeTab,
  screenSize: (state) => state.screenSize,
  showError: (state) => state.showError,
};

const actions = {
  setScreenSize({ commit }, value) {
    if (value < 641) return commit("SET_SIZE", "sm");
    if (value > 640 && value < 1023) return commit("SET_SIZE", "md");
    if (value > 1023) return commit("SET_SIZE", "lg");
  },
  setActiveTab({ commit }, value) {
    commit("SET_ACTIVE_TAB", value);
  },
  setShowError({ commit }, value) {
    commit("SET_ERROR", value);
  },
};

const mutations = {
  SET_LOADING: (state, isLoading) => (state.isLoading = isLoading),
  SET_SIZE: (state, size) => (state.screenSize = size),
  SET_ACTIVE_TAB: (state, tab) => (state.activeTab = tab),
  SET_ERROR: (state, error) => (state.showError = error),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
