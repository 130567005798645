import axios from "axios";
import { CharitySerializer } from "@/serializer/CharitySerializer";
import { setQueryParams } from "../utils";

export default {
  async getAllCharities() {
    const { data } = await axios.get("/charities");
    if (data) {
      return {
        data: data.map((item) => CharitySerializer.formatGetRequest(item)),
      };
    }
    return {
      data: [],
    };
  },
  async updateCharity(id, payload) {
    return axios.put(`/charities/${id}`, payload);
  },
  async createCharity(payload) {
    return axios.post("/charities", payload);
  },
  async deleteCharity(id) {
    return axios.delete(`/charities/${id}`);
  },
  async downloadDonationByCharity(filters) {
    const addedQuery = setQueryParams(filters);

    const response = await axios.get(
      `/charity/download-donations?${addedQuery}`
    );
    return response.data;
  },
};
