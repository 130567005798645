<script>
import BasicLayout from "@/components/layout/BasicLayout";
//THRID PARTY
import VueNextSelect from "vue-next-select";
import ExportSection from "@/components/company/dashboard/ExportSection";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Data",
  components: {
    BasicLayout,
    VueNextSelect,
    ExportSection,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      selectedProgram: null,
      formType: "user",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      employees: "employee/data",
      company: "company/data",
      programs: "program/data",
      userIsLoading: "user/isLoading",
    }),
    selectablePrograms() {
      return this.programs && this.programs.length > 0
        ? this.programs.filter((item) => item.status === "active")
        : [];
    },
  },
  methods: {
    ...mapActions({
      getEmployees: "employee/getAllEmployees",
      getCompany: "company/getCompany",
      getAllPrograms: "program/getAllPrograms",
    }),
    redirectLogout() {
      this.$router.push("/logout");
    },
    showForm(type) {
      this.formType = type;
    },
    fetchData() {
      if (!this.company) {
        this.getCompany(this.user.companyDetails.id);
      }
      if (!this.employees) {
        this.getEmployees(this.user.companyDetails.id);
      }
      if (!this.programs)
        this.getAllPrograms({ company_id: this.user.companyDetails.id });
    },
  },
  watch: {
    user(newValue) {
      if (newValue && newValue.companyDetails.id) this.fetchData();
    },
  },
  mounted() {
    if (this.user && this.user.companyDetails.id) {
      this.fetchData();
    }
  },
};
</script>

<template>
  <BasicLayout
    :isLoading="userIsLoading.get"
    :title="$t('data.title')"
    :subTitle="$t('data.subTitle')"
  >
    <div>
      <div class="flex flex-col gap-12">
        <div v-if="selectablePrograms && selectablePrograms.length > 0">
          <div class="mb-8 mr-auto lg:w-1/2">
            <div class="mb-4">{{ $t("data.export.select.title") }}</div>
            <VueNextSelect
              :options="selectablePrograms"
              label-by="name"
              v-model="selectedProgram"
              :close-on-select="true"
              :multiple="false"
            ></VueNextSelect>
          </div>
          <div class="lg:w-1/2" v-if="selectedProgram">
            <ExportSection
              :triggerToast="triggerToast"
              :programId="selectedProgram.id"
            />
          </div>
        </div>
        <div v-else>{{ $t("data.export.select.default") }}</div>
      </div>
    </div>
  </BasicLayout>
</template>
