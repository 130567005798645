<script>
import { mapGetters } from "vuex";
import FirstStep from "./FirstStep.vue";
import SecondStep from "./SecondStep.vue";
import TitleWithSub from "@/components/layout/TitleWithSub.vue";
import AlertBox from "@/components/design/AlertBox.vue";

export default {
  name: "Onboarding",
  components: {
    FirstStep,
    SecondStep,
    TitleWithSub,
    AlertBox,
  },
  data() {
    return {
      step: "first",
    };
  },
  computed: {
    ...mapGetters({
      company: "company/data",
    }),
  },
  methods: {
    changeStep(targetStep) {
      this.step = targetStep;
    },
    onCompletion() {
      this.$emit("success");
    },
  },
};
</script>
<template>
  <div>
    <TitleWithSub
      :title="
        step === 'first'
          ? $t('login.company.onboarding.step1.title')
          : $t('login.company.onboarding.step2.title')
      "
      :subTitle="
        step === 'first'
          ? $t('login.company.onboarding.step1.subTitle')
          : $t('login.company.onboarding.step2.subTitle')
      "
    />
    <AlertBox v-if="step === 'first'" class="mb-8" type="info">
      <div>{{ $t("login.company.onboardingCompany") }}</div>
    </AlertBox>
    <transition name="slide-left" mode="out-in">
      <FirstStep
        v-on:on-continue="(step) => changeStep(step)"
        v-if="step === 'first'"
      />
      <SecondStep v-else-if="step === 'second'" @completed="onCompletion" />
    </transition>
  </div>
</template>
