export const DonorSerializer = {
  formatTerminateRequest(id, endDate) {
    return {
      id: id,
      end_date: endDate,
    };
  },
  formatGetRequest(data) {
    const employeeDonations = data.filter(
      (item) => item.matching_donation_id === null
    );
    return employeeDonations.map((donation) => {
      const matchDonation = data.filter(
        (item) => item.matching_donation_id === donation.id
      );
      return this.formatDonor(
        donation,
        matchDonation.length > 0 ? matchDonation[0] : null
      );
    });
  },
  formatDonor(donor, matchDonation) {
    return {
      id: donor.id,
      email: donor.email,
      programId: donor.program_id,
      userId: donor.user_id,
      charityId: donor.charity_id,
      employeeId: donor.employee_id,
      companyId: donor.company_id,
      wallet: donor.wallet,
      amount: donor.amount,
      matchedDonationId: matchDonation
        ? matchDonation.matching_donation_id
        : null,
      matchedDonationAmount: matchDonation ? matchDonation.amount : null,
      startDate: donor.start_date !== null ? donor.start_date : null,
      endDate: donor.end_date !== null ? donor.end_date : null,
      programEndDate:
        donor.program_end_date !== null ? donor.program_end_date : null,
      status: donor.status,
    };
  },
  statusCompleteDonorPayload: function (data) {
    return {
      program_id: data.programId,
      user_id: data.userId,
      charity_id: data.charityId,
      charity_name: data.charityName,
      wallet_id: null,
      amount: data.amount,
      start_date: data.startDate,
      end_date: data.endDate ?? undefined,
    };
  },
};
