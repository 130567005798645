<template>
  <div
    :class="`
      p-6 
      shadow-md
      rounded-md
      bg-white
       ${classes}`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    disabled: Boolean,
    classes: String,
  },

  methods: {},
};
</script>
