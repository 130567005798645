import axios from "../utils/interceptor";
import { CompanySerializer } from "../serializer/CompanySerializer";

export default {
  async createCompany(payload) {
    return axios.post("/companies", payload);
  },
  async updateCompany(id, payload) {
    const deserializedPayload = CompanySerializer.formatUpdateRequest(payload);

    return axios.put(`/companies/${id}`, deserializedPayload);
  },
  async getCompany(id) {
    const { data } = await axios.get(`/companies/${id}`);
    const serializedData = CompanySerializer.formatGetRequest(data);
    return serializedData;
  },
  async deleteCompany(id) {
    return axios.delete(`/companies/${id}`);
  },
};
