<script>
//COMPONENTS
import StatusButton from "@/components/StatusButton.vue";
import Button from "@/components/design/Button.vue";

export default {
  name: "DonorHistory",
  components: {
    StatusButton,
    Button,
  },
  props: {
    donor: Object,
  },
  data() {
    return {
      icons: {
        checked: require("@/assets/icons/task-checked.svg"),
        open: require("@/assets/icons/task-open.svg"),
      },
    };
  },
};
</script>
<template>
  <div class="p-6">
    <div
      class="flex flex-col lg:flex-row lg:justify-between mb-8 lg:items-center border-b-2"
    >
      <h2>
        {{ donor.email ?? "deleted" }}
      </h2>
      <div>
        <StatusButton
          :hasEndDate="donor.endDate !== null"
          :hasStarted="donor.startedAt !== null"
          :status="donor.status"
        />
      </div>
    </div>

    <div
      v-for="history in donor.history"
      :key="history.label"
      class="flex items-center w-full my-2"
    >
      <div
        :class="`${!history.value ? 'opacity-20' : 'text-green-default'} pr-4`"
      >
        {{ history.label }}
      </div>
      <div class="text-sm ml-auto w-32">
        {{ history.value }}
      </div>
    </div>
    <Button
      classes="mt-4 w-full mx-auto"
      @click.prevent="() => this.$emit('close')"
      >{{ $t("common.btn.close") }}</Button
    >
  </div>
</template>
