<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  props: {
    hideSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        logo: require("../../assets/logo/logo_mop-big.svg"),
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      programs: "program/data",
    }),
    currentRouteName() {
      return this.$route.fullPath;
    },
  },
};
</script>
<template>
  <div
    :class="[
      `z-10
      h-24
      flex
      items-center
      w-full
      text-black
      bg-white
      content-wrapper-x
      shadow-md 
      ${isLoggedIn && !hideSidebar ? 'lg:ml-36 fixed' : ''}`,
    ]"
  >
    <div
      :class="[
        `${
          isLoggedIn && hideSidebar ? '' : ''
        } header w-full flex items-center max-width`,
      ]"
    >
      <router-link :to="isLoggedIn ? '/login' : '/'">
        <div class="flex flex-col cursor-pointer text-black">
          <img :src="icons.logo" class="h-12 inline-block" />
          <div class="text-xs text-red-default">Beta</div>
        </div>
      </router-link>
      <!-- <router-link to="/movement">
        <div class="hidden md:block cursor-pointer">
          {{ $t("header.movement") }}
        </div>
      </router-link> -->
      <router-link v-if="isLoggedIn" to="/logout">
        <div class="hidden md:block cursor-pointer text-black">
          {{ $t("header.logout") }}
        </div>
      </router-link>
      <router-link v-else-if="currentRouteName === '/signup'" to="/login">
        <div class="hidden md:block cursor-pointer text-black">
          {{ $t("header.login") }}
        </div>
      </router-link>
      <router-link v-else to="/signup">
        <div class="hidden md:block cursor-pointer text-black">
          {{ $t("header.signup") }}
        </div>
      </router-link>
      <div v-if="isLoggedIn" class="ml-auto cursor-pointer text-black">
        <img :src="icons.profile" class="h-10 inline-block" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.header div {
  @apply mr-8;
}
</style>
