<script>
import Button from "../components/design/Button.vue";
import BasicLayout from "../components/layout/BasicLayout.vue";
import TitleWithSub from "../components/layout/TitleWithSub.vue";
import AuthService from "@/services/AuthService";
// UTILS
import { EMAIL_REGEX } from "@/utils";

export default {
  name: "ResetPassword",
  components: {
    BasicLayout,
    TitleWithSub,
    Button,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        password: "",
        password_confirmation: "",
        email: "",
        token: this.$route.params.token,
      },
    };
  },
  methods: {
    validateInput() {
      if (!EMAIL_REGEX.test(this.form.email)) {
        return this.triggerToast(
          "error",
          null,
          "Please provide a valid email address."
        );
      }
      if (this.form.password.length < 4) {
        return this.triggerToast(
          "error",
          null,
          "Passwords need to match and at least 4 characters long."
        );
      }
      if (!this.form.token) {
        return this.triggerToast(
          "error",
          null,
          "Please use the link that we have send you in your inbox."
        );
      }
      return true;
    },
    async resetPassword() {
      if (this.validateInput()) {
        try {
          this.isLoading = true;
          await AuthService.resetPassword({
            email: this.form.email,
            password: this.form.password,
            token: this.form.token,
          });
          this.triggerToast(
            "success",
            null,
            "Successfully created new Password. Please login!",
            () =>
              setTimeout(() => {
                window.location.href = "/login";
              }, 2000)
          );
        } catch (e) {
          this.triggerToast(
            "error",
            null,
            "Email/Token missmatch! Make sure you use the email link you have received."
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<template>
  <BasicLayout
    :isLoading="isLoading"
    :hideSidebar="true"
    contentWrapperProps="bg-orange-light"
  >
    <div class="h-full content-wrapper-x max-width flex flex-col">
      <TitleWithSub
        :title="$t('resetPassword.title')"
        :subTitle="$t('resetPassword.subTitle')"
      />
      <div class="mb-4 gap-4 flex flex-col">
        <label for="email">Provide Email Address</label>
        <input
          class="p-4 shadow-md rounded-md mr-auto h-12"
          type="email"
          id="email"
          v-model="form.email"
        />
      </div>
      <div class="mb-4 gap-4 flex flex-col">
        <label for="password">Password</label>
        <input
          class="p-4 shadow-md rounded-md mr-auto h-12"
          type="password"
          id="password"
          v-model="form.password"
        />
      </div>
      <div class="mb-4 gap-4 flex flex-col">
        <label for="password_confirmation">Confirm password</label>
        <input
          class="p-4 shadow-md rounded-md mr-auto h-12"
          type="password"
          id="password_confirmation"
          v-model="form.password_confirmation"
        />
      </div>
      <div class="flex flex-wrap gap-4 mt-8">
        <Button classes="w-1/2" @click.prevent="resetPassword">{{
          $t("resetPassword.btn")
        }}</Button>
      </div>
    </div>
  </BasicLayout>
</template>
