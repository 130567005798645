<script>
//VUEX
import { mapGetters, mapActions } from "vuex";
//COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import { UserDetails, CompanyDetails } from "@/components";
export default {
  name: "Profile",
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  components: {
    BasicLayout,
    UserDetails,
    CompanyDetails,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      company: "company/data",
      userLoading: "user/isLoading",
      companyLoading: "company/isLoading",
      updateUserSuccess: "user/success",
      updateCompanySuccess: "company/success",
    }),
  },
  watch: {
    updateUserSuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.update && newValue.update) {
          this.triggerToast("success", null, "User Details updated.");
        }
      },
      deep: true,
    },
    updateCompanySuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.update && newValue.update) {
          console.log("test");
          this.triggerToast("success", null, "Company Details updated.");
        }
      },
      deep: true,
    },
    user(newValue) {
      if (newValue && !this.company)
        this.getCompany(this.user.companyDetails.id);
      if (newValue !== null && !newValue && this.company) {
        this.isLoading = false;
      }
    },
    company(newValue) {
      if (newValue !== null && newValue && this.user) {
        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions({
      getCompany: "company/getCompany",
      updateCompany: "company/updateCompany",
      deleteCompany: "company/deleteCompany",
      resetUserSuccess: "user/resetSuccess",
      resetCompanySuccess: "company/resetSuccess",
    }),
  },
  beforeMount() {
    if (!this.company && this.user) {
      this.getCompany(this.user.companyDetails.id);
    } else if (this.company && this.user) {
      this.isLoading = false;
    }
  },
};
</script>
<template>
  <BasicLayout
    :isLoading="isLoading || companyLoading.update || userLoading.update"
    :title="$t('profile.title')"
  >
    <div class="h-full w-full">
      <div class="flex flex-col lg:flex-row gap-4 mx-auto w-full">
        <div class="lg:w-1/3 h-full">
          <UserDetails />
        </div>
        <div class="lg:w-1/3">
          <CompanyDetails :companyProp="user.companyDetails" />
        </div>
      </div>
    </div>
  </BasicLayout>
</template>
<style scoped>
li {
  @apply mt-4;
}
</style>
