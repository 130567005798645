<script>
//VUEX
import { mapActions, mapGetters } from "vuex";

import Error from "@/utils/Error";
import EditDetails from "@/components/EditDetails.vue";
export default {
  name: "UserDetailsPassword",
  components: {
    EditDetails,
  },
  data() {
    return {
      error: [],
      id: null,
      formType: "general",
      form: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      fields: [
        {
          id: "current_password",
          label: "Current Password",
          type: "password",
        },
        {
          id: "password",
          label: "New password",
          type: "password",
        },
        {
          id: "password_confirmation",
          label: "Repeat new password",
          type: "password",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
    }),
  },
  methods: {
    ...mapActions({
      updatePassword: "user/updatePassword",
    }),
    validateInput() {
      if (this.form.password !== this.form.password_confirmation)
        this.error.push(
          new Error("password_confirmation", "Your passwords do not match.")
        );
      for (const [key, value] of Object.entries(this.form)) {
        if (value.length < 4) {
          this.error.push(
            new Error(key, "Password should be at least 4 characters long")
          );
        }
      }
      if (this.error.length > 0) return false;
      return true;
    },
    savePassword() {
      this.error = [];
      if (this.validateInput()) {
        this.updatePassword({ ...this.form });
      }
    },

    resetForm() {
      this.form = {
        current_password: "",
        password: "",
        password_confirmation: "",
      };
    },

    triggerCloseOverlay() {
      this.resetForm();
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>
<template>
  <div>
    <EditDetails
      title="Edit Password"
      :fields="fields"
      :form="form"
      :errorProp="error"
      @submit="savePassword"
      @update="(values) => (this.form = values)"
      @cancel="triggerCloseOverlay"
    ></EditDetails>
  </div>
</template>
