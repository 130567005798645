<script>
export default {
  name: "Table",
  components: {},
  props: {
    headers: {
      type: String,
    },
    bodybgColor: {
      type: String,
      default: "bg-white",
    },
    actionCols: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="flex flex-col w-full justify-center overflow-hidden">
    <div class="sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
        <div class="sm:rounded-lg w-screen lg:w-auto overflow-x-auto">
          <table class="w-screen lg:w-auto divide-y divide-gray-500">
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header"
                  class="px-6 py-3 font-bold text-sm text-center uppercase tracking-wider"
                >
                  {{ header }}
                </th>
                <th
                  v-if="actionCols"
                  :colspan="this.actionCols"
                  class="relative text-left px-6 py-3"
                >
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody
              :class="[
                `${tableBgColor} divide-y divide-gray-200 custom-table text-sm`,
              ]"
            >
              <slot name="content"></slot>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.custom-table td {
  padding: 20px;
}
</style>
