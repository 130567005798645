<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    viewBox="0 0 51 51"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5 0C11.424 0 0 11.424 0 25.5C0 39.576 11.424 51 25.5 51C39.576 51 51 39.576 51 25.5C51 11.424 39.576 0 25.5 0ZM25.5 7.65C29.733 7.65 33.15 11.067 33.15 15.3C33.15 19.533 29.733 22.95 25.5 22.95C21.267 22.95 17.85 19.533 17.85 15.3C17.85 11.067 21.267 7.65 25.5 7.65ZM25.5 43.86C19.125 43.86 13.4895 40.596 10.2 35.649C10.2765 30.5745 20.4 27.795 25.5 27.795C30.5745 27.795 40.7235 30.5745 40.8 35.649C37.5105 40.596 31.875 43.86 25.5 43.86Z"
    />
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "IconProfile",
  props: {
    iconHeight: {
      type: String,
      default: "41",
    },
    iconWidth: {
      type: String,
      default: "41",
    },
  },
};
</script>
