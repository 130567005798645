export default class Error {
  id = null;
  message = "";
  constructor(id, message) {
    this.id = id;
    this.message = message;
  }
  getMessage() {
    return this.message;
  }
  getId() {
    return this.id;
  }
}

export class CustomError {
  id = null;
  message = null;
  response = "";

  constructor(response) {
    this.id = null;
    this.message = this.setMessage(response);
    this.response = response;
  }
  getMessage() {
    return this.message;
  }
  getId() {
    return this.id;
  }
  setMessage(response) {
    if (response && response.data) {
      if (response.data.errors) {
        return Object.values(response.data.errors)[0];
      } else if (response.data.message) {
        return response.data.message;
      }
    }
    return "Oops something went wrong. Sorry!";
  }
}
