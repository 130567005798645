<script>
import BasicCard from "./design/BasicCard.vue";
export default {
  name: "ShowDetails",
  components: {
    BasicCard,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
  },
};
</script>
<template>
  <BasicCard @submit="edit" :title="title" :btnTitle="$t('common.btn.edit')">
    <div>
      <div class="flex items-center"></div>
      <slot name="before-form"></slot>
      <div v-for="field in fields" :key="field.label">
        <label class="text-grey-dark underline">{{ field.label }}</label>
        <div class="mb-4 text-lg text-black">{{ values[field.id] }}</div>
      </div>
    </div>
  </BasicCard>
</template>
