<script>
// COMPONENTS
import { mapGetters, mapActions } from "vuex";
import UserDetailsPassword from "./UserDetailsPassword";
import ShowDetails from "@/components/ShowDetails.vue";
import EditDetails from "@/components/EditDetails.vue";
import Modal from "@/components/design/Modal.vue";
//UTILS
import Error from "@/utils/Error";

export default {
  name: "UserDetails",
  components: {
    UserDetailsPassword,
    ShowDetails,
    EditDetails,
    Modal,
  },
  data() {
    return {
      error: [],
      id: null,
      formType: "show",
      form: {
        name: "",
        email: "",
        phone: "",
        role: "company",
      },
      fields: [
        {
          id: "name",
          label: "Your name",
          type: "text",
        },
        {
          id: "email",
          label: "Email",
          type: "email",
        },
        {
          id: "phone",
          label: "Phone number",
          type: "text",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "user/isLoading",
      user: "user/data",
      company: "company/data",
      updateUserSuccess: "user/success",
    }),
  },
  watch: {
    updateUserSuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.update && newValue.update) {
          this.changeFormType("show");
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
      deleteUser: "user/deleteUser",
      getUser: "user/getUser",
    }),

    prePopulateForm() {
      this.id = this.user.id;
      this.form = {
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone,
      };
    },

    validateInput() {
      for (const [key, value] of Object.entries(this.form)) {
        if (value.length < 3) {
          this.error = [
            ...this.error,
            new Error(key, "Please provide at least 3 characters"),
          ];
        }
      }
      if (this.error.length > 0) return false;
      return true;
    },

    saveUser() {
      this.error = [];
      if (this.validateInput()) {
        this.updateUser({ id: this.id, data: this.form });
      }
    },
    triggerDeletePopup() {
      this.deleteUser(this.id);
    },
    changeFormType(type) {
      this.formType = type;
    },
  },
  mounted() {
    this.prePopulateForm();
  },
};
</script>
<template>
  <div class="flex-1">
    <ShowDetails
      :title="$t('profile.user.title')"
      :fields="fields"
      :values="form"
      @edit="changeFormType('edit')"
    ></ShowDetails>
    <Modal @close="changeFormType('show')" v-if="formType !== 'show'">
      <transition name="slide-left" mode="out-in">
        <div v-if="formType === 'password'" class="lg:w-96">
          <UserDetailsPassword
            @close="() => changeFormType('edit')"
          ></UserDetailsPassword>
        </div>
        <EditDetails
          v-else
          wrapperClasses="lg:w-96"
          :title="$t('profile.user.title')"
          :fields="fields"
          :form="form"
          :errorProp="error"
          @update="(values) => (this.form = values)"
          @submit="saveUser"
          @reset="(originalForm) => (form = originalForm)"
          @cancel="() => changeFormType('show')"
        >
          <template v-slot:before-form>
            <div class="mb-4">
              <div
                class="cursor-pointer pr-2 underline text-grey-dark"
                v-if="id"
                @click.prevent="changeFormType('password')"
              >
                {{ $t("profile.user.password") }} >
              </div>
            </div>
          </template>
        </EditDetails>
      </transition>
    </Modal>
  </div>
</template>
