<script>
export default {
  name: "Footer",
  props: {
    hasSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        logo: require("../../assets/logo/logo_mop-small-red.svg"),
      },
    };
  },
};
</script>
<template>
  <div
    :class="`
      h-32
      mt-auto
      flex
      items-center
      w-full
      bg-white
      content-wrapper-x
    ${hasSidebar ? 'lg:pl-40' : ''}`"
  >
    <div class="header w-full flex items-center max-width">
      <ul class="list-none">
        <li>Contact us</li>
        <li>
          <a href="mailto:stommel.joerg@gmail.com">{{ $t("home.email") }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.header div {
  @apply mr-8;
}
</style>
