<script>
import { mapGetters, mapActions } from "vuex";
import Table from "@/components/Table.vue";
import StatusButton from "@/components/StatusButton.vue";
import Modal from "@/components/design/Modal.vue";
import Button from "@/components/design/Button.vue";
import DonorTerminate from "./DonorTerminate.vue";
import DonorHistory from "./DonorHistory.vue";
import DonorService from "@/services/DonorService";
import ProgramService from "@/services/ProgramService";

export default {
  name: "DonorIndex",
  components: {
    Table,
    StatusButton,
    Button,
    Modal,
    DonorTerminate,
    DonorHistory,
  },
  props: {
    programId: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        trash: require("@/assets/icons/trash.svg"),
        edit: require("@/assets/icons/pencil-square.svg"),
        wait: require("@/assets/icons/wait.svg"),
        approve: require("@/assets/icons/approve.svg"),
        history: require("@/assets/icons/history.svg"),
      },
      selectedParticipant: null,
      formType: "show",
      headers: ["email", "status", "Start/End"],
    };
  },
  computed: {
    ...mapGetters({
      company: "company/data",
      donors: "donor/data",
      participants: "program/participants",
    }),
  },
  methods: {
    ...mapActions({
      updateDonor: "donor/updateDonor",
      deleteDonor: "donor/deleteDonor",
      resetDonorList: "donor/resetDonorList",
    }),
    changeFormType(type) {
      this.formType = type;
    },

    canDelete(status) {
      return status !== "terminated" && status !== "active";
    },

    canTerminate(participant) {
      return (
        participant.details &&
        (participant.details.status === "active" ||
          participant.details.status === "initialized")
      );
    },

    openForm(participant, type) {
      this.selectedParticipant = participant;
      this.changeFormType(type);
    },

    closeForm() {
      this.changeFormType("show");
      this.selectedParticipant = null;
    },

    openHistory(participant) {
      this.selectedParticipant = participant.details;
      this.changeFormType("history");
    },

    async sendReminder(userId) {
      await ProgramService.sendReminder(userId, this.programId);
      this.$emit("reminderSent");
    },

    async saveTermination({ id, endDate }) {
      try {
        await DonorService.terminateDonation(id, endDate);
        this.$swal(
          "Employee update saved",
          "Please dont forget to notify employee via Email",
          "success"
        );
      } catch (error) {
        this.$swal(
          "Something went wrong",
          "Please try again or contact your HR person.",
          "error"
        );
      } finally {
        this.changeFormType("show");
        this.$emit("updated");
      }
    },

    async removeDonor(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove employee from program!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteDonor(id);
        }
      });
    },
  },
};
</script>
<template>
  <div class="w-full">
    <Modal v-if="formType !== 'show'" @close="closeForm">
      <div>
        <DonorTerminate
          v-if="formType === 'terminate'"
          :donor="selectedParticipant"
          @terminate="saveTermination"
          @close="closeForm"
        />
        <DonorHistory
          v-if="formType === 'history'"
          :donor="selectedParticipant"
          @close="closeForm"
        />
      </div>
    </Modal>
    <div v-if="participants.donations.length > 0">
      <h3>Donations</h3>
      <Table tableClasses="bg-transparent" :headers="headers" :actionCols="1">
        <template v-slot:content>
          <tr
            :class="`${
              donation && donation.status === 'terminated' ? 'opacity-20' : ''
            } align-middle`"
            v-for="donation in participants.donations"
            :key="donation.id"
          >
            <td>
              {{ donation.deletedAt ? "deleted" : donation.email ?? "deleted" }}
            </td>
            <td class="gap-2" v-if="donation">
              <div class="inline-block">
                <StatusButton
                  :hasEndDate="donation.endDate !== null"
                  :hasStarted="donation.startedAt !== null"
                  :status="donation.status"
                />
              </div>
            </td>
            <td v-if="donation">
              {{
                (donation.status !== "pending" &&
                  donation.startDate + "/" + donation.endDate) ||
                "? / ?"
              }}
            </td>
            <td v-if="donation.id">
              <div class="actions flex">
                <div
                  class="cursor-pointer"
                  v-if="canTerminate(donation)"
                  @click="() => openForm(donation, 'terminate')"
                >
                  <img :src="icons.edit" class="h-6" />
                </div>
                <div
                  v-else-if="canDelete(donation.status)"
                  @click="removeDonor(donation.id)"
                  class="cursor-pointer"
                >
                  <img :src="icons.trash" class="h-6" />
                </div>
                <div
                  @click="openForm(donation, 'history')"
                  class="cursor-pointer"
                >
                  <img :src="icons.history" class="h-6" />
                </div>
              </div>
            </td>
          </tr>
        </template>
      </Table>
    </div>
    <div
      v-if="
        participants.invitees.length > 0 &&
        participants.invitees.length > participants.donations.length
      "
    >
      <h3>{{ $t("programs.index.employees.invited") }}</h3>
      <div
        v-for="participant in participants.invitees"
        :key="participant.email"
        class="flex items-center justify-between py-0.5"
      >
        <div v-if="!participant.hasDonation">{{ participant.email }}</div>
        <div v-if="!participant.hasDonation && participant.remindedAt">
          {{ "Reminder sent: " + participant.remindedAt }}
        </div>
        <Button
          size="sm"
          :disabled="participant.remindedAt"
          v-else-if="!participant.hasDonation"
          @click.prevent="() => sendReminder(participant.userId)"
          >{{ $t("programs.index.employees.btn.sendReminder") }}</Button
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.actions div {
  @apply mr-2;
}
</style>
