import { UserSerializer } from "../../serializer/UserSerializer";
import AuthService from "../../services/AuthService";
import {
  isLoggedInLocalStorage,
  loginLocalStorage,
  logoutLocalStorage,
} from "../../utils";

const state = {
  isLoggedIn: false,
  data: null,
  isLoading: {
    get: false,
    store: false,
    update: false,
    delete: false,
    authenticate: false,
  },
  error: {
    get: null,
    store: null,
    update: null,
    delete: null,
    authenticate: null,
  },
  success: {
    get: false,
    store: false,
    update: false,
    delete: false,
    authenticate: false,
  },
};

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  data: (state) => state.data,
  isLoading: (state) => state.isLoading,
  success: (state) => state.success,
  error: (state) => state.error,
};

const actions = {
  resetSuccess({ commit }) {
    commit("SET_USER_SUCCESS", {
      get: null,
      store: null,
      update: null,
      delete: null,
      authenticate: null,
    });
  },

  resetError({ commit }) {
    logoutLocalStorage();
    commit("SET_USER_ERROR", {
      get: null,
      store: null,
      update: null,
      delete: null,
      authenticate: null,
    });
  },

  async register({ commit }, payload) {
    commit("SET_USER_SUCCESS", { store: false });
    commit("SET_USER_ISLOADING", { store: true });
    try {
      await AuthService.registerUser(payload);
      commit("SET_USER_SUCCESS", { store: true });
    } catch (e) {
      commit("SET_USER_ERROR", { store: e });
    } finally {
      commit("SET_USER_ISLOADING", { store: false });
    }
  },

  async login({ commit, dispatch }, payload) {
    logoutLocalStorage();
    dispatch("setIsLoggedIn", false);
    commit("SET_USER", null);
    commit("SET_USER_ISLOADING", { authenticate: true });
    try {
      await AuthService.login(payload);
      commit("SET_USER_SUCCESS", { authenticate: true });
      await dispatch("getUser");
    } catch (e) {
      commit("SET_USER_ERROR", { authenticate: e });
    } finally {
      commit("SET_USER_ISLOADING", { authenticate: false });
    }
  },

  async logout({ dispatch, commit }) {
    commit("SET_USER_ISLOADING", { authenticate: true });
    try {
      await AuthService.logout();
      logoutLocalStorage();
      dispatch("setIsLoggedIn", false);
      commit("SET_USER", null);
    } catch (e) {
      commit("SET_USER_ERROR", { authenticate: e });
    } finally {
      commit("SET_USER_ISLOADING", { authenticate: false });
    }
  },

  setIsLoggedIn({ commit }, payload) {
    commit("SET_ISLOGGEDIN", payload);
    if (payload && !isLoggedInLocalStorage()) loginLocalStorage();
    if (!payload) logoutLocalStorage();
  },

  async getUser({ dispatch, commit }) {
    commit("SET_USER_ISLOADING", { get: true });
    try {
      const data = await AuthService.getAuthUser();
      commit("SET_USER", data);
      dispatch("setIsLoggedIn", true);
      commit("SET_USER_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_USER_ERROR", { get: e });
    } finally {
      commit("SET_USER_ISLOADING", { get: false });
    }
  },

  async updateUser({ commit }, { id, data }) {
    commit("SET_USER_SUCCESS", { update: false });
    commit("SET_USER_ISLOADING", { update: true });
    try {
      const response = await AuthService.updateUser(id, data);
      const serialized = UserSerializer.formatGetRequest(response.data);
      commit("SET_USER", serialized);
      commit("SET_USER_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_USER_ERROR", { update: e });
    } finally {
      commit("SET_USER_ISLOADING", { update: false });
    }
  },

  async updatePassword({ commit }, data) {
    commit("SET_USER_SUCCESS", { update: false });
    commit("SET_USER_ERROR", { update: null });
    commit("SET_USER_ISLOADING", { update: true });
    try {
      await AuthService.updatePassword(data);
      commit("SET_USER_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_USER_ERROR", { update: e });
    } finally {
      commit("SET_USER_ISLOADING", { update: false });
    }
  },
};

const mutations = {
  SET_USER: (state, user) => (state.data = user),
  SET_ISLOGGEDIN: (state, isLoggedIn) => (state.isLoggedIn = isLoggedIn),
  SET_USER_ISLOADING: (state, status) => (state.isLoading = status),
  SET_USER_SUCCESS: (state, success) =>
    (state.success = { ...state.success, ...success }),
  SET_USER_ERROR: (state, error) =>
    (state.error = { ...state.error, ...error }),
  SET_IS_LOADING: (state, isLoading) =>
    (state.isLoading = { ...state.isLoading, ...isLoading }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
