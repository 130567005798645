<script>
export default {
  name: "AlertBox",
  data() {
    return {
      icons: {
        info: require("../assets/icons/info-icon.svg"),
      },
    };
  },
  props: {
    type: {
      type: String,
      default: "info",
    },
    customClasses: {
      type: String,
      default: "",
    },
  },
  methods: {
    setClasses() {
      switch (this.type) {
        case "info":
          return "bg-beige-light p-2 rounded-md";
        case "error":
          return "box-error";
        case "warning":
          return "box-warning";
        case "success":
          return "box-success";
        default:
          return "";
      }
    },
  },
};
</script>
<template>
  <div
    :class="[
      setClasses(),
      'p-4 rounded-md text-sm flex items-center gap-4',
      customClasses,
    ]"
  >
    <img :src="icons.info" class="h-6" />
    <div>
      <slot></slot>
    </div>
  </div>
</template>
