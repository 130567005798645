<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    viewBox="0 0 63 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.9545 17.1429C47.7082 17.1429 51.5168 13.3143 51.5168 8.57143C51.5168 3.82857 47.7082 0 42.9545 0C38.2009 0 34.3636 3.82857 34.3636 8.57143C34.3636 13.3143 38.2009 17.1429 42.9545 17.1429ZM20.0455 17.1429C24.7991 17.1429 28.6077 13.3143 28.6077 8.57143C28.6077 3.82857 24.7991 0 20.0455 0C15.2918 0 11.4545 3.82857 11.4545 8.57143C11.4545 13.3143 15.2918 17.1429 20.0455 17.1429ZM20.0455 22.8571C13.3732 22.8571 0 26.2 0 32.8571V40H40.0909V32.8571C40.0909 26.2 26.7177 22.8571 20.0455 22.8571ZM42.9545 22.8571C42.1241 22.8571 41.1791 22.9143 40.1768 23C43.4986 25.4 45.8182 28.6286 45.8182 32.8571V40H63V32.8571C63 26.2 49.6268 22.8571 42.9545 22.8571Z"
    />
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "IconEmployees",
  props: {
    iconHeight: {
      type: String,
      default: "30",
    },
    iconWidth: {
      type: String,
      default: "53",
    },
  },
};
</script>
