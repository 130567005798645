<script>
//VUE
import { mapGetters, mapActions } from "vuex";
// COMPONENTS
import Settings from "./Settings.vue";
import ShowDetails from "@/components/ShowDetails.vue";
import EditDetails from "@/components/EditDetails.vue";
import Modal from "@/components/design/Modal.vue";
// UTILS
import Error from "@/utils/Error";
import { hasNumber } from "@/utils";

export default {
  name: "CompanyDetails",
  components: {
    Settings,
    EditDetails,
    ShowDetails,
    Modal,
  },
  props: {
    companyProp: Object,
  },
  data() {
    return {
      formType: "show",
      error: [],
      id: null,
      form: {
        name: "",
        street: "",
        city: "",
        zip: "",
        country: "",
      },
      fields: [
        {
          id: "name",
          label: "Company Name",
          type: "text",
        },
        {
          id: "street",
          label: "Street name and number",
          type: "text",
        },
        {
          id: "zip",
          label: "Zip code",
          type: "text",
        },
        {
          id: "city",
          label: "City",
          type: "text",
        },
        {
          id: "country",
          label: "Country",
          type: "text",
        },
      ],
    };
  },
  watch: {
    company(newValue) {
      if (newValue !== null) this.prePopulateForm();
    },
    id(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.resetForm();
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "company/isLoading",
      company: "company/data",
    }),
  },
  methods: {
    ...mapActions({
      createCompany: "company/createCompany",
      updateCompany: "company/updateCompany",
      deleteCompany: "company/deleteCompany",
    }),

    changeFormType(type) {
      this.formType = type;
    },

    resetForm() {
      this.form = {
        name: this.companyProp.name,
        street: "",
        city: "",
        zip: "",
        country: "",
      };
    },
    prePopulateForm() {
      this.id = this.company.id;
      this.form = {
        name: this.company.name,
        street: this.company.street,
        city: this.company.city,
        zip: this.company.zip,
        country: this.company.country,
      };
    },
    updateValue(id, value) {
      this.form[id] = value;
    },

    validateInput() {
      for (const [key, value] of Object.entries(this.form)) {
        if (value.length < 3) {
          this.error = [
            ...this.error,
            new Error(key, "Please provide at least 3 characters"),
          ];
        }
      }
      if (!hasNumber(this.form.street))
        this.error.push(new Error("street", "Please provide a street number"));
      if (this.error.length > 0) return false;
      return true;
    },

    createOrUpdateCompany() {
      this.error = [];
      if (this.validateInput()) {
        if (this.company) this.updateCompany({ id: this.id, data: this.form });
        this.changeFormType("show");
      }
    },

    async triggerDeletePopup() {
      this.$swal({
        title: "Are you sure?",
        text: "You will delete all your programs and employees!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete company!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.deleteCompany(this.id);
            this.id = null;
            this.$swal(
              "Deleted!",
              "All programs, employees and company information has been deleted.",
              "success"
            );
          } catch (error) {
            this.$swal(
              "Uppps!",
              "Can not delete your company account. Do you have still running programs? ",
              "error"
            );
          } finally {
            this.changeFormType("show");
          }
        }
      });
    },
  },
  mounted() {
    if (!this.companyProp.id) {
      this.form.name = this.companyProp.name;
    } else if (this.companyProp.id && !this.company)
      this.getCompany(this.companyProp.id);
    else this.prePopulateForm();
  },
};
</script>
<template>
  <div>
    <ShowDetails
      v-if="company"
      :title="$t('profile.company.title')"
      :fields="fields"
      :values="company"
      @edit="() => changeFormType('edit')"
    >
    </ShowDetails>
    <div class="my-4 p-4 font-bold text-sm">
      {{ $t("profile.company.info") }}
    </div>
    <Modal
      classes=""
      @close="changeFormType('show')"
      v-if="formType !== 'show'"
    >
      <transition name="slide-left" mode="out-in">
        <div v-if="formType === 'settings'" class="lg:w-96">
          <Settings
            :title="$t('profile.company.settings')"
            :settings="company.settings"
            :companyId="id"
            @reset="(originalForm) => (form = originalForm)"
            @cancel="changeFormType('edit')"
            @saved="changeFormType('edit')"
          />
        </div>
        <EditDetails
          v-else
          :isLoading="company.update"
          :gridCols="2"
          :title="$t('profile.company.title')"
          wrapperClasses=""
          :fields="fields"
          :form="form"
          :errorProp="error"
          @reset="(originalForm) => (form = originalForm)"
          @update="(values) => (this.form = values)"
          @submit="createOrUpdateCompany"
          @cancel="changeFormType('show')"
        >
          <div class="my-4 flex flex-col gap-4">
            <!-- TODO - Define business logic for settings -->
            <!-- <div
              class="cursor-pointer pr-2 underline text-grey-dark"
              v-if="company.id"
              @click.prevent="changeFormType('settings')"
            >
              {{ $t("profile.company.settings") }} >
            </div> -->
            <!-- <div
              v-if="company.id"
              @click.prevent="triggerDeletePopup"
              class="cursor-pointer pr-2 underline text-grey-dark"
              :outlined="true"
            >
              {{ $t("common.btn.delete") }} >
            </div> -->
          </div>
        </EditDetails>
      </transition>
    </Modal>
  </div>
</template>
