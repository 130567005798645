<script>
//COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import Button from "@/components/design/Button.vue";
//SERVICES
import { ROUTEPATHS } from "../constants";

export default {
  name: "Logout",
  components: {
    BasicLayout,
    Button,
  },
  data() {
    return {
      icons: {
        success: require("../assets/icons/success-check.svg"),
      },
    };
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  methods: {
    onLogin() {
      this.$router.push(ROUTEPATHS.guest.login);
    },
  },
};
</script>

<template>
  <BasicLayout
    :hideSidebar="true"
    contentWrapperProps="bg-orange-light"
    :isLoading="isLoading"
  >
    <div class="w-full h-full m-auto flex flex-col items-center justify-center">
      <img :src="icons.success" class="h-12 w-12" />
      <h1 class="text-green-dark">{{ $t("verify.success") }}</h1>
      <div class="my-4">
        {{ $t("verify.description") }}
      </div>
      <Button classes="w-44" @click="onLogin">{{
        $t("common.btn.login")
      }}</Button>
    </div>
  </BasicLayout>
</template>
