<script>
export default {
  name: "Overlay",
  props: {
    disabled: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: "",
    },
    zIndex: {
      type: String,
      default: "z-40",
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <Portal target="#overlay-target">
    <div
      :class="`${zIndex}
        lg:h-full
        fixed
        top-0
        left-0
        min-h-screen
        w-screen
        overflow-auto
        bottom-0
        bg-gray-800 bg-opacity-30`"
    >
      <div v-if="showClose" @click.prevent="onClose" class="close"></div>
      <div
        :class="`${classes} relative  min-h-screen lg:h-auto lg:mx-24 lg:py-12 flex items-center justify-center`"
      >
        <slot></slot>
      </div>
    </div>
  </Portal>
</template>
