import DonorService from "../../services/DonorService";

const state = {
  data: null,
  isLoading: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
  error: {
    get: null,
    store: null,
    update: null,
    delete: null,
  },
  success: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
};

const getters = {
  data: (state) => state.data,
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
  success: (state) => state.success,
};

const actions = {
  async saveDonation({ commit }, { data }) {
    commit("SET_DONOR_SUCCESS", { store: false });
    commit("SET_IS_LOADING", { store: true });
    try {
      await DonorService.saveDonation(data);
      commit("SET_DONOR_SUCCESS", { store: true });
    } catch (e) {
      commit("SET_DONOR_ERROR", { store: e });
    } finally {
      commit("SET_IS_LOADING", { store: false });
    }
  },

  async updateDonor({ commit }, { id, data }) {
    commit("SET_DONOR_SUCCESS", { update: false });
    commit("SET_IS_LOADING", { update: true });
    try {
      await DonorService.updateDonor(id, data);
      commit("SET_DONOR_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_DONOR_ERROR", { update: e });
    } finally {
      commit("SET_IS_LOADING", { update: false });
    }
  },

  async getAllDonations({ commit }, queryParamsObj) {
    commit("SET_DONOR_SUCCESS", { get: false });
    commit("SET_IS_LOADING", { get: true });
    try {
      const response = await DonorService.getAllDonations(queryParamsObj);
      commit("SET_DONOR_DATA", response);
      commit("SET_DONOR_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_DONOR_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async deleteDonor({ commit }, id) {
    commit("SET_DONOR_SUCCESS", { delete: false });
    commit("SET_IS_LOADING", { delete: false });
    try {
      await DonorService.deleteDonor(id);
      commit("SET_DONOR_SUCCESS", { delete: true });
    } catch (e) {
      commit("SET_DONOR_ERROR", { delete: e });
    } finally {
      commit("SET_IS_LOADING", { delete: false });
    }
  },

  resetDonorList({ commit }) {
    commit("RESET_DONOR_LIST");
  },

  resetSuccess({ commit }) {
    commit("SET_DONOR_SUCCESS", {
      get: null,
      store: null,
      update: null,
      delete: null,
    });
  },

  resetError({ commit }) {
    commit("SET_DONOR_ERROR", {
      get: null,
      store: null,
      update: null,
      delete: null,
    });
  },
};

const mutations = {
  SET_DONOR_DATA: (state, data) => (state.data = data),
  SET_DONOR_SUCCESS: (state, success) =>
    (state.success = { ...state.success, ...success }),
  RESET_DONOR_LIST: (state) => (state.data = []),
  SET_DONOR_ERROR: (state, error) =>
    (state.error = { ...state.error, ...error }),
  SET_IS_LOADING: (state, isLoading) =>
    (state.isLoading = { ...state.isLoading, ...isLoading }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
