<script>
import Button from "../components/design/Button.vue";
import BasicLayout from "../components/layout/BasicLayout.vue";
import TitleWithSub from "../components/layout/TitleWithSub.vue";

export default {
  name: "Home",
  components: {
    BasicLayout,
    TitleWithSub,
    Button,
  },
};
</script>

<template>
  <BasicLayout :hideSidebar="true" contentWrapperProps="bg-orange-light w-full">
    <div class="flex flex-col content-wrapper-x gap-8">
      <div class="flex flex-col lg:flex-row gap-8">
        <div class="lg:mr-24 lg:w-1/2">
          <TitleWithSub
            :title="$t('home.title')"
            :subTitle="$t('home.subTitle')"
          />
          <div>
            {{ $t("home.description") }}
          </div>
          <div class="mt-4 mb-14">
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1vGuCTwiqkgWjtYamtCNYqNSxHOgt5CLm/view?usp=sharing"
            >
              What is the MOP Program? ></a
            >
          </div>
        </div>
        <div>
          <h2 class="my-12 font-bold">Invited as a pilot project?</h2>
          <div class="flex flex-wrap gap-4">
            <Button
              classes="w-1/2"
              @click.prevent="() => this.$router.push('/signup')"
              >{{ $t("common.btn.signup") }}</Button
            >
            <Button
              :outlined="true"
              classes="w-1/2"
              @click.prevent="() => this.$router.push('/login')"
              >{{ $t("common.btn.login") }}</Button
            >
          </div>
        </div>
      </div>
      <div class="mt-auto lg:mr-auto lg:w-1/2">
        <div class="mb-4">{{ $t("home.contact") }}</div>
        <a href="mailto:stommel.joerg@gmail.com">{{ $t("home.email") }}</a>
      </div>
    </div>
  </BasicLayout>
</template>
