<script>
// VUEX
import { mapGetters, mapActions } from "vuex";
// COMPONENTS
import EditDetails from "@/components/EditDetails.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
import Modal from "@/components/design/Modal.vue";

// UTILS
import Error from "@/utils/Error";
import { DATES } from "@/constants";

//SERIALIZER
import { ProgramSerializer } from "@/serializer/ProgramSerializer";

export default {
  name: "MOPDefaultProgramCreate",
  components: {
    EditDetails,
    Modal,
    LoadingSpinner,
  },
  props: {
    companyId: String,
    isFirstProgram: Boolean,
  },
  data() {
    return {
      error: [],
      id: null,
      form: {
        name: "",
        startDate: DATES.today,
        endDate: null,
        matchingPercentage: 100,
      },
      fields: [
        {
          id: "name",
          label: "Program Name",
          type: "text",
        },
        {
          id: "startDate",
          label: "Start Date",
          type: "date",
          min: DATES.today,
        },
        {
          id: "matchingPercentage",
          label: "Matching Percentage",
          type: "number",
          max: 100,
          min: 1,
          hint: "The percentage you want to match each of your employees donation with",
        },
        {
          id: "maxDonation",
          label: "Max Matching Donation/Employee",
          type: "number",
          hint: "The max amount of your matching donation per employee per month. Example: 200€ -> Your matching donation per employee never exceeds 200€ per month",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      isLoading: "program/isLoading",
      program: "program/data",
      apiCallSuccess: "program/success",
    }),
  },
  watch: {
    apiCallSuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.store && newValue.store) {
          this.$emit("close");
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      createProgram: "program/createProgram",
    }),
    prePopulateForm() {
      this.form = {
        name: this.company.name,
        startDate: this.program.startDate,
        endDate: this.program.endDate,
        matchingPercentage: this.program.matchingPercentage,
      };
    },
    resetForm() {
      this.form = {
        name: "",
        startDate: "",
        matchingPercentage: "",
      };
    },

    validateInput() {
      if (this.form.startDate < DATES.today) {
        this.error = [
          ...this.error,
          new Error(
            "startDate",
            "Start date needs to be today or in the future."
          ),
        ];
      }
      if (this.form.endDate && this.form.endDate <= this.form.startDate) {
        this.error = [
          ...this.error,
          new Error(
            "endDate",
            "End date needs to be later than the start date."
          ),
        ];
      }
      if (!/^(100|[1-9]?[0-9])$/.test(this.form.matchingPercentage))
        this.error = [
          ...this.error,
          new Error(
            "matchingPercentage",
            "Please provide a number between 1 and 100."
          ),
        ];
      if (this.error.length > 0) return false;
      return true;
    },

    async saveProgram() {
      this.error = [];
      if (this.validateInput()) {
        const payloadData = {
          ...ProgramSerializer.formatPostRequest(this.form),
          company_id: this.id,
        };
        this.createProgram({
          data: payloadData,
        });
      }
    },
    triggerCloseOverlay() {
      this.resetForm();
      this.$emit("close");
    },
  },
  mounted() {
    if (this.isFirstProgram)
      this.form.name = "MOP Program " + this.user.companyDetails.name;
    this.id = this.user.companyDetails.id;
  },
};
</script>
<template>
  <div>
    <LoadingSpinner v-if="isLoading.store || isLoading.update" size="full" />
    <Modal v-else :showClose="!isFirstProgram" @close="triggerCloseOverlay">
      <EditDetails
        :gridCols="1"
        :title="$t('programs.create.firstTitle')"
        :fields="fields"
        :form="form"
        :errorProp="error"
        @update="(values) => (this.form = values)"
        @submit="saveProgram"
        @cancel="triggerCloseOverlay"
      >
        <template v-slot:before-form> <slot></slot></template>
      </EditDetails>
    </Modal>
  </div>
</template>
