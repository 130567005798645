export const UserSerializer = {
  formatLoginRequest(data) {
    return {
      email: data.email,
      password: data.password,
      role: data.isCompany ? "company" : "employee",
    };
  },
  formatForgotPasswordPayload(data) {
    return {
      email: data.email,
      role: data.isCompany ? "company" : "employee",
    };
  },
  formatSetPasswordPayload(data) {
    return {
      email: data.email,
      password_generate_token: data.token,
      password: data.password,
      agb_accepted: data.privacy && data.agb,
    };
  },
  formatGetRequest(user) {
    return {
      email: user.email,
      name: user.name ?? null,
      phone: user.phone ?? null,
      role: user.role,
      id: user.id,
      companyDetails: {
        id: user.company_id ?? null,
        name: user.company_name ?? null,
        employee_id: user.employee_id ?? null,
      },
      wallet: user.wallet ?? null,
      verified: user.email_verified_at ?? null,
    };
  },
};
