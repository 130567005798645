import { h } from "@vue/runtime-core";

/* TODO - have its own state that should be cleared after error has been shown */
const withToasts = (component) => {
  return {
    methods: {
      /* message is object with title and text in case of success. In case of error it will be build */
      triggerSwal(type, error, message, callback) {
        let toast = null;
        switch (type) {
          case "error":
            (message = error ? error.message : message),
              (toast = this.$swal.mixin({
                toast: true,
                customClass: {
                  popup: "colored-toast",
                },
                position: "top-end",
                iconColor: "white",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              }));
            break;
          case "success":
            toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            break;
          case "default":
            return;
        }
        toast.fire({
          icon: type,
          title: message,
        });
        if (callback) callback();
      },
    },

    render() {
      return h(component, {
        triggerToast: (type, error, message, callback) =>
          this.triggerSwal(type, error, message, callback),
      });
    },
  };
};

export default withToasts;
