<script>
import EditDetails from "@/components/EditDetails";
// UTILS
import Error from "@/utils/Error";

export default {
  name: "DonorTerminate",
  components: {
    EditDetails,
  },
  props: {
    donor: Object,
  },
  data() {
    return {
      error: [],
      id: null,
      form: {
        startDate: "",
        endDate: "",
      },
      fields: [
        {
          id: "startDate",
          label: "Start Date",
          type: "date",
        },
        {
          id: "endDate",
          label: "End Date",
          type: "date",
          max: "",
        },
      ],
      disabled: ["startDate"],
    };
  },
  methods: {
    prePopulateForm() {
      if (this.donor.programEndDate) {
        this.fields[1].max = this.donor.programEndDate;
      }
      this.form = {
        startDate: this.donor.startDate,
        endDate: this.donor.endDate,
      };
    },
    validateInput() {
      if (
        (this.form.endDate && this.form.endDate < this.form.startDate) ||
        !this.form.endDate
      ) {
        this.error.push(
          new Error(
            "endDate",
            "End date needs to be later than the start date."
          )
        );
      }
      if (this.error.length > 0) return false;
      return true;
    },

    terminateDonation() {
      this.error = [];
      if (this.validateInput()) {
        this.$emit("terminate", {
          id: this.donor.id,
          endDate: this.form.endDate,
        });
      }
    },
    triggerCloseForm() {
      this.$emit("close");
    },
  },
  mounted() {
    this.prePopulateForm();
  },
};
</script>
<template>
  <div>
    <EditDetails
      title="Terminate Program"
      :fields="fields"
      :form="form"
      :errorProp="error"
      :disabled="disabled"
      :showLabelCancel="false"
      @update="(values) => (this.form = values)"
      @submit="terminateDonation"
      @cancel="triggerCloseForm"
    >
      <template v-slot:before-form>
        <div class="my-4">
          Terminate for
          <span class="font-bold">{{ donor.email }}</span>
        </div>
      </template>
    </EditDetails>
  </div>
</template>
