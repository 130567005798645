<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    viewBox="0 0 62 53"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.8 53V34.2941H37.2V53H52.7V28.0588H62L31 0L0 28.0588H9.3V53H24.8Z"
    />
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "IconHome",
  props: {
    iconHeight: {
      type: String,
      default: "62",
    },
    iconWidth: {
      type: String,
      default: "53",
    },
  },
};
</script>
