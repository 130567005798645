<script>
// THIRD PARTY
import { mapGetters, mapActions } from "vuex";

//SERIALIZER
import { DonorSerializer } from "@/serializer/DonorSerializer";
import { DATES } from "@/constants";

// COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import EditDetails from "@/components/EditDetails.vue";
import StatusButton from "@/components/StatusButton.vue";
import Modal from "@/components/design/Modal.vue";
import AlertBox from "@/components/design/AlertBox.vue";
import ProgramInfo from "@/components/employee/program/ProgramInfo.vue";
import CharityCardList from "@/components/charity/CharityCardList.vue";
import CharityCard from "@/components/charity/CharityCard.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
import DonorService from "@/services/DonorService";

import Error from "@/utils/Error";

export default {
  name: "SelectCharityList",
  components: {
    BasicLayout,
    Modal,
    EditDetails,
    LoadingSpinner,
    ProgramInfo,
    CharityCardList,
    CharityCard,
    AlertBox,
    StatusButton,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      formType: "show",
      error: [],
      isLoading: true,
      highlightedCharity: {
        name: "",
        id: "",
        logo: "",
      },
      donor: this.$route.query.donor ?? null,
      form: {
        charityId: "",
        charityName: "",
        amount: 0,
        startDate: DATES.today,
        endDate: null,
      },
      fields: [
        {
          id: "amount",
          label: "Your monthly donation",
          type: "number",
          min: 0,
          hint: "How much do you want to donate per month in €? Minimum is 10€",
        },
        {
          id: "startDate",
          label: "Start Date",
          type: "date",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "user/data",
      isLoadingProgram: "program/isLoading",
      isLoadingDonation: "donor/isLoading",
      isLoadingCharities: "charity/isLoading",
      programDetails: "program/data",
      donations: "donor/data",
      success: "donor/success",
      charities: "charity/data",
    }),
    lastDonationTask() {
      return this.donations[this.donations.length - 1] ?? null;
    },
    selectedMatchingDonation() {
      if (this.programDetails && this.donations) {
        const selectedCharity = this.lastDonationTask;
        const exceedsMax =
          selectedCharity.amount *
            (this.programDetails.matchingPercentage / 100) >
          this.programDetails.maxDonation;
        if (exceedsMax) {
          return this.programDetails.maxDonation;
        }
        return (
          selectedCharity.amount *
            (this.programDetails.matchingPercentage / 100) ?? 0
        );
      }
      return 0;
    },
    matchingDonation() {
      if (this.programDetails) {
        const exceedsMax =
          this.form.amount * (this.programDetails.matchingPercentage / 100) >
          this.programDetails.maxDonation;
        if (exceedsMax) {
          return this.programDetails.maxDonation;
        }
        return (
          this.form.amount * (this.programDetails.matchingPercentage / 100) ?? 0
        );
      }
      return 0;
    },
    totalDonation() {
      if (this.form.amount) return +this.matchingDonation + +this.form.amount;
      return 0;
    },
    selectedCharity() {
      if (this.donations) return this.lastDonationTask;
      return null;
    },
    selectedCharityDetails() {
      if (this.selectedCharity) {
        return this.charities.data.filter(
          (item) => item.id === this.selectedCharity.charityId
        )[0];
      }
      return null;
    },
  },
  watch: {
    userDetails(newValue) {
      if (newValue) {
        this.retrieveSelectedCharity({
          program_id: this.$route.params.programId,
          user_id: newValue.id,
        });
      }
    },
    success(newValue, oldValue) {
      if (newValue.store === true && !oldValue.store) {
        this.triggerToast("success", null, "Successfully saved donation task!");
        this.retrieveSelectedCharity({
          program_id: this.$route.params.programId,
          user_id: this.userDetails.id,
        });
        this.changeFormType("show");
      }
    },

    programDetails(newValue) {
      if (newValue) {
        this.isLoading = false;
        this.getAllCharities();
      }
    },
  },

  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
      updateDonoation: "donor/updateDonor",
      saveDonation: "donor/saveDonation",
      retrieveSelectedCharity: "donor/getAllDonations",
      resetSuccess: "donor/resetSuccess",
      getAllCharities: "charity/getAllCharities",
    }),

    changeFormType(type) {
      this.error = [];
      this.highlightedCharity = {
        name: "",
        id: "",
        logo: "",
      };
      this.form.charityId = "";
      this.form.charityName = "";
      this.formType = type;
    },

    resetForm() {
      this.highlightedCharity = {
        name: "",
        id: "",
        picture: "",
      };
      this.form.charityId = "";
      this.form.charityName = "";
    },
    validateInput() {
      for (const [key, value] of Object.entries(this.form)) {
        if (
          key === "startDate" &&
          (value < this.programDetails.startDate ||
            value > this.programDetails.endDate)
        ) {
          this.error = [
            ...this.error,
            new Error(
              key,
              "Start Date needs to be between program start and end date"
            ),
          ];
        }
        if (
          key === "endDate" &&
          value &&
          (value < this.form.startDate || value > this.programDetails.endDate)
        ) {
          this.error = [
            ...this.error,
            new Error(
              key,
              "End Date can not be before start date and after Program End Date"
            ),
          ];
        }
        if (key === "amount" && value < 10) {
          this.error = [
            ...this.error,
            new Error(key, "Minimum of 10€ required."),
          ];
        }
      }
      if (this.error.length > 0) return false;
      return true;
    },

    async saveSelection() {
      if (this.programDetails.status !== "terminated") {
        this.error = [];
        if (this.validateInput()) {
          try {
            const payload = DonorSerializer.statusCompleteDonorPayload({
              ...this.form,
              userId: this.userDetails.id,
              programId: this.programDetails.id,
            });
            this.saveDonation({
              data: payload,
            });
          } catch (e) {
            this.triggerToast("error", e, "Oops something went wrong!");
          }
        }
      } else {
        this.triggerToast("error", null, "This program has already ended!");
      }
    },
    closeForm() {
      this.changeFormType("show");
      this.resetSuccess();
    },
    async endDonation() {
      try {
        this.$swal({
          title: "Are you sure?",
          text: "You are about to end your donation. You will be able to create a new one.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, end donation!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await DonorService.terminateDonation(
              this.lastDonationTask.id,
              DATES.today
            );
            this.triggerToast("success", null, "Donation ended!");
            this.retrieveSelectedCharity({
              program_id: this.$route.params.programId,
              user_id: this.userDetails.id,
            });
          }
        });
      } catch (error) {
        this.triggerToast("error", error, "Oops something went wrong");
      }
    },

    openForm(charity) {
      this.highlightedCharity = {
        name: charity.name,
        id: charity.id,
        picture: charity.logoUrl,
      };
      this.form.charityId = charity.id;
      this.form.charityName = charity.name;
      this.formType = "select";
      this.form.startDate =
        this.selectedCharity.startDate &&
        this.selectedCharity.startDate >= DATES.today
          ? this.selectedCharity.startDate
          : DATES.today;
      this.form.endDate = this.donor.programEndDate ?? "";
      this.form.amount = this.selectedCharity.amount ?? 0;
    },
    getBgColor(index) {
      if (index % 3 === 0) return "bg-orange-default";
      if (index % 3 === 1) return "bg-green-default";
      if (index % 3 === 2) return "bg-blue-default";
    },
  },
  mounted() {
    this.getProgram(this.$route.params.programId);
    if (this.userDetails) {
      this.retrieveSelectedCharity({
        program_id: this.$route.params.programId,
        user_id: this.userDetails.id,
      });
    }
  },
};
</script>
<template>
  <BasicLayout
    :hideSidebar="true"
    :isLoading="isLoading"
    :title="$t('charities.select.title')"
  >
    <div class="w-full">
      <Modal v-if="formType === 'select'" @close="changeFormType('show')">
        <LoadingSpinner
          size="full"
          v-if="isLoadingDonation.update || isLoadingDonation.store"
        />
        <EditDetails
          :gridCols="3"
          :showLabelCancel="false"
          :title="$t('charities.select.form.title')"
          :fields="fields"
          :form="form"
          :errorProp="error"
          @update="(values) => (this.form = values)"
          @submit="saveSelection"
          @cancel="changeFormType('show')"
        >
          <div class="w-auto mr-auto text-sm flex flex-col">
            <div class="font-bold flex justify-between items-center gap-4">
              <div>
                {{ $t("common.baseDonation") }}
              </div>
              <div class="text-xl">{{ form.amount || 0 }} €</div>
            </div>
            <div class="font-bold flex justify-between items-center gap-4">
              <div>
                {{ $t("common.matchingDonation") }}
              </div>
              <div class="text-xl ml-auto">{{ matchingDonation }} €</div>
            </div>
            <div
              class="font-bold flex justify-between items-center border-t-2 border-black mt-2 gap-4"
            >
              <div>
                {{ $t("charities.select.form.total") }}
              </div>
              <div class="text-xl">{{ totalDonation }} €</div>
            </div>
          </div>
          <template v-slot:before-form>
            <div class="flex items-center mb-4">
              <div>
                <h2 class="font-bold">{{ highlightedCharity.name }}</h2>
              </div>
            </div>
            <ProgramInfo />
          </template>
        </EditDetails>
      </Modal>
      <LoadingSpinner v-if="isLoadingCharities.get" size="xl" />
      <div v-else-if="charities.data.length > 0">
        <div v-if="!selectedCharity" class="lg:w-1/2">
          <AlertBox :type="info">
            <div>
              {{ $t("charities.select.info") }}
            </div>
          </AlertBox>
        </div>
        <div v-if="selectedCharityDetails">
          <h2 class="my-12">{{ $t("charities.select.selectedHeader") }}</h2>
          <div class="mr-4">
            <div class="flex flex-col">
              <div class="flex gap-4">
                <div class="lg:w-1/3">
                  <CharityCard
                    :terminated="selectedCharity.status === 'terminated'"
                    :isSelected="true"
                    @selected="endDonation"
                    v-if="selectedCharityDetails"
                    :key="selectedCharityDetails.name"
                    :charity="selectedCharityDetails"
                    bgColor="bg-blue-default"
                    :btn="true"
                  >
                  </CharityCard>
                </div>
                <div
                  :class="`${
                    selectedCharity.status === 'terminated' ? 'opacity-50' : ''
                  } gap-4 flex flex-col p-8`"
                >
                  <div class="mr-auto">
                    <StatusButton
                      :hasEndDate="selectedCharity.endDate !== null"
                      :hasStarted="selectedCharity.startDate !== null"
                      :status="selectedCharity.status"
                    />
                  </div>
                  <div>
                    Start Date:
                    <span class="font-bold">
                      {{ selectedCharity.startDate }}
                    </span>
                  </div>
                  <div v-if="selectedCharity.endDate">
                    End Date:
                    <span class="font-bold">
                      {{ selectedCharity.endDate }}
                    </span>
                  </div>
                  <div class="w-auto mr-auto text-sm flex flex-col">
                    <div
                      class="font-bold flex justify-between items-center gap-4"
                    >
                      <div>
                        {{ $t("common.baseDonation") }}
                      </div>
                      <div class="text-xl">
                        {{ selectedCharity.amount || 0 }} €
                      </div>
                    </div>
                    <div
                      class="font-bold flex justify-between items-center gap-4"
                    >
                      <div>
                        {{ $t("common.matchingDonation") }}
                      </div>
                      <div class="text-xl ml-auto">
                        {{ selectedMatchingDonation }} €
                      </div>
                    </div>
                    <div
                      class="font-bold flex justify-between items-center border-t-2 border-black mt-2 gap-4"
                    >
                      <div>
                        {{ $t("charities.select.form.total") }}
                      </div>
                      <div class="text-xl">
                        {{
                          +selectedCharity.amount + +selectedMatchingDonation
                        }}
                        €
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 class="mt-24 mb-12">{{ $t("charities.select.changeHeader") }}</h2>
        <CharityCardList
          :charities="charities"
          v-if="charities"
          @charity-selected="openForm"
          :btn="true"
        />
      </div>
    </div>
  </BasicLayout>
</template>
<style lang="css">
li {
  @apply mx-2;
}

.Page-active {
  @apply w-full p-2;
}
</style>
