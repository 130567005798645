<script>
import { mapActions, mapGetters } from "vuex";
import BasicLayout from "../components/layout/BasicLayout.vue";
import LoginForm from "../components/LoginForm.vue";
import Onboarding from "../components/onboarding/company/Onboarding.vue";
import TitleWithSub from "../components/layout/TitleWithSub.vue";
import { ROLES, ROUTEPATHS } from "../constants";
import { isLoggedInLocalStorage } from "@/utils";
import LoadingSpinner from "../components/design/LoadingSpinner.vue";

export default {
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  name: "Login",
  components: {
    Onboarding,
    BasicLayout,
    TitleWithSub,
    LoginForm,
    LoadingSpinner,
  },
  data() {
    return {
      step: "login",
    };
  },
  watch: {
    user(newValue) {
      if (newValue) {
        if (newValue.verified) {
          this.redirectUser();
        } else {
          this.triggerToast("error", null, "Please verify your email address");
        }
      }
    },
    isLoggedIn(newValue) {
      if (newValue) {
        this.getUser();
      }
    },
    company(newValue) {
      if (newValue) {
        if (newValue.verified) {
          this.$router.push(ROUTEPATHS.company.dashboard);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      login: "user/login",
      logout: "user/logout",
    }),
    switchForm(type) {
      this.formType = type;
    },
    async submitLogin(payload) {
      if (this.user) await this.logout();
      this.login(payload);
    },
    completedOnboarding() {
      this.getUser();
    },
    redirectUser() {
      if (this.user) {
        switch (this.user.role) {
          case ROLES.admin:
            this.$router.push(ROUTEPATHS.admin.dashboard);
            break;
          case ROLES.employee:
            this.$router.push(ROUTEPATHS.employee.dashboard);
            break;
          case ROLES.company:
            if (
              !this.user.companyDetails.id &&
              this.user.role === "company" &&
              this.user.verified
            ) {
              this.step = "companyDetails";
            } else this.$router.push(ROUTEPATHS.company.dashboard);
            break;
          default:
            console.log("No user role found");
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      loginError: "user/error",
      isLoggedIn: "user/isLoggedIn",
      userLoading: "user/isLoading",
      user: "user/data",
    }),
  },
  mounted() {
    if (isLoggedInLocalStorage() && !this.user) {
      this.getUser();
    } else if (this.user) {
      this.redirectUser();
    }
  },
};
</script>
<template>
  <BasicLayout :hideSidebar="true" contentWrapperProps="bg-orange-light">
    <div>
      <transition name="slide-left" mode="out-in">
        <div>
          <LoadingSpinner v-if="userLoading.authenticate" size="full" />
          <div class="h-full content-wrapper-x max-width flex flex-col">
            <TitleWithSub
              v-if="this.step === 'login'"
              :title="$t('login.title')"
            />
            <Onboarding
              v-if="this.step !== 'login'"
              @success="completedOnboarding"
            />
            <LoginForm v-else @login="submitLogin" />
          </div>
        </div>
      </transition>
    </div>
  </BasicLayout>
</template>
