<script>
export default {
  name: "Button",
  props: {
    size: {
      type: String,
      default: "md",
    },
    color: {
      type: String,
      default: "red-default",
    },
    classes: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSize() {
      switch (this.size) {
        case "sm":
          return "text-sm p-2";
        case "md":
          return "p-4";
        case "lg":
          return "p-8 text-lg";
      }
    },
  },
};
</script>
<template>
  <button
    :class="`${classes} ${
      outlined
        ? `text-${color} bg-white border-${color} border`
        : `text-white bg-${color}`
    }  ${setSize()} hover:shadow-md rounded-full`"
  >
    <slot></slot>
  </button>
</template>
<style scoped>
button {
  min-width: 80px;
}
</style>
