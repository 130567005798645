export const CompanySerializer = {
  getSettings(settings, type = "serialize") {
    if (type === "deserialize") {
      return {
        has_integration: settings.hasIntegration ?? false,
        integration_type: settings.integrationType ?? null,
        /* Always return true for now - not using integration */
        is_set: true,
      };
    }
    return {
      hasIntegration: settings.has_integration ?? false,
      integrationType: settings.integration_type ?? null,
      isSet: settings.is_set ?? false,
    };
  },
  formatUpdateRequest(company) {
    return {
      name: company.name ?? undefined,
      id: company.id ?? undefined,
      street: company.street ?? undefined,
      zip: company.zip ?? undefined,
      city: company.city ?? undefined,
      country: company.country ?? undefined,
      slug: company.slug ?? undefined,
      settings: company.settings
        ? this.getSettings(company.settings, "deserialize")
        : undefined,
      createdAt: company.created_at ?? undefined,
    };
  },
  formatGetRequest(company) {
    return {
      name: company.name,
      id: company.id,
      street: company.street,
      zip: company.zip,
      country: company.country,
      city: company.city,
      slug: company.slug,
      settings: this.getSettings(company.settings),
      createdAt: company.created_at
        ? new Date(company.created_at).toISOString().split("T")[0]
        : null,
    };
  },
};
