import { DonorSerializer } from "@/serializer/DonorSerializer";
import axios from "@/utils/interceptor";
import { setQueryParams } from "@/utils";

export default {
  async updateDonor(id, payload) {
    return axios.put(`/donations/${id}`, payload);
  },

  async saveDonation(payload) {
    return axios.post(`/donations`, payload);
  },

  async terminateDonation(id, endDate) {
    const payload = DonorSerializer.formatTerminateRequest(id, endDate);

    return axios.put(`/donations/${id}/terminate`, payload);
  },

  async sendEmail(id, link) {
    return axios.post(`/donations/${id}/sign-document`, { link: link });
  },

  async documentSigned(id) {
    return axios.get(`/donations/${id}/document-signed`);
  },

  async getDonor(id) {
    const response = await axios.get(`/donations/${id}`);
    return DonorSerializer.formatDonor(response.data, null);
  },

  async getAllDonations(queryParamsObj) {
    const addedQuery = setQueryParams(queryParamsObj);
    const response = await axios.get(`/donations?${addedQuery}`);
    return DonorSerializer.formatGetRequest(response.data);
  },
  async deleteDonor(id) {
    return axios.delete(`/donations/${id}`);
  },
};
