<script>
import Error from "@/utils/Error";
import { mapActions, mapGetters } from "vuex";
import EditDetails from "../../EditDetails.vue";
//UTILS
import { hasNumber } from "../../../utils";

export default {
  name: "FirstStep",
  components: {
    EditDetails,
  },
  data() {
    return {
      form: {
        name: "",
        street: "",
        city: "",
        zip: "",
        country: "",
      },
      error: [],
      fields: [
        {
          id: "name",
          label: "Company Name",
          type: "text",
        },
        {
          id: "street",
          label: "Street name and number",
          type: "text",
        },
        {
          id: "zip",
          label: "Zip code",
          type: "text",
        },
        {
          id: "city",
          label: "City",
          type: "text",
        },
        {
          id: "country",
          label: "Country",
          type: "text",
        },
      ],
    };
  },
  watch: {
    company(newValue) {
      if (newValue) {
        this.$emit("onContinue", "second");
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      isLoading: "company/isLoading",
      company: "company/data",
      user: "user/data",
    }),
  },
  mounted() {
    this.form.name = this.user.companyDetails.name;
  },
  methods: {
    ...mapActions({
      createCompany: "company/createCompany",
    }),

    async saveCompanyDetails() {
      this.error = [];
      if (this.validateInput()) {
        this.createCompany(this.form);
      }
    },
    validateInput() {
      for (const [key, value] of Object.entries(this.form)) {
        if (value.length < 3) {
          this.error = [
            ...this.error,
            new Error(key, "Please provide at least 3 characters"),
          ];
        }
      }
      if (!hasNumber(this.form.street))
        this.error = [
          ...this.error,
          new Error("street", "Please provide a street number"),
        ];
      if (this.error.length > 0) return false;
      return true;
    },
  },
};
</script>
<template>
  <EditDetails
    :isLoading="isLoading.get || isLoading.store"
    :showCancel="{
      label: false,
      btn: false,
    }"
    inputClasses="bg-white"
    :gridCols="2"
    titleBtn="Continue"
    :btn="{ cancel: false, submit: true }"
    :fields="fields"
    :form="form"
    :errorProp="error"
    @update="(values) => (this.form = values)"
    @submit="saveCompanyDetails"
  />
</template>
