export const EmployeeSerializer = {
  formatGetRequest(employee) {
    return {
      id: employee.id,
      email: employee.email,
      deleted: employee.deleted_at === null ? false : true,
      companyDetails: {
        id: employee.company_id,
        name: employee.company_name,
        employeeId: employee.employee_id,
      },
      currentParticipant: employee.is_participant ?? false,
      defaultDonation: employee.default_donation ?? 0,
    };
  },
  formatPostRequest(employee) {
    return {
      data: {
        ...this.formatEmployee(employee),
      },
    };
  },
  formatEmployee(employee) {
    return {
      company_id: employee.companyId ?? undefined,
      employee_id: employee.employeeId,
      email: employee.email,
    };
  },
};
