import axios from "axios";
import { CustomError } from "../utils/Error";
import { isLoggedInLocalStorage, logoutLocalStorage } from ".";

// import Cookies from "js-cookie";

// Request interceptor. Runs before your request reaches the server
const onRequest = (config) => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // proceed with the initial response
  if (
    config.method == "post" ||
    config.method == "put" ||
    config.method == "delete"
    // !Cookies.get("XSRF-TOKEN")
  ) {
    return setCSRFToken().then(() => config);
  }
  return config;
};

const setCSRFToken = () => {
  return axios.get("/csrf-cookie");
};

axios.interceptors.request.use(onRequest, null);

/*
 * Add a response interceptor
 */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      isLoggedInLocalStorage()
    ) {
      logoutLocalStorage();
      window.location.href = "/login";
    } else {
      return Promise.reject(new CustomError(error.response ?? null));
    }
  }
);

export default axios;
