<script>
import BasicLayout from "@/components/layout/BasicLayout";
import Card from "@/components/Card";

import { mapGetters, mapActions } from "vuex";
import { ROUTEPATHS } from "../../constants";
import { getProgramUrl } from "../../utils";

export default {
  name: "EmployeeDashboard",
  components: {
    BasicLayout,
    Card,
  },
  data() {},
  computed: {
    ...mapGetters({
      user: "user/data",
      programs: "program/data",
      isLoading: "program/isLoading",
      donations: "donor/data",
    }),
  },
  methods: {
    ...mapActions({
      getAllPrograms: "program/getAllPrograms",
    }),
    redirectLogout() {
      this.$router.push(ROUTEPATHS.guest.logout);
    },
    onClickOnProgram(program) {
      if (program.status !== "terminated")
        this.$router.push(getProgramUrl(program.id));
    },
  },
  mounted() {
    if (
      this.user &&
      this.user.companyDetails &&
      (!this.programs || this.programs.length === 0)
    )
      this.getAllPrograms({ company_id: this.user.companyDetails.id });
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue !== oldValue && newValue)
        this.getAllPrograms({ company_id: this.user.companyDetails.id });
    },
  },
};
</script>

<template>
  <BasicLayout
    :isLoading="!user || !programs || !user.email"
    :title="$t('employeeSection.dashboard.title')"
    :subTitle="$t('dashboard.subTitle')"
    :hideSidebar="true"
  >
    <div
      v-if="programs && programs.length > 0"
      class="flex gap-8 flex-wrap cursor-default"
    >
      <Card
        v-for="program in programs"
        :key="program.id"
        @click="() => onClickOnProgram(program)"
        :classes="`${
          program.status === 'terminated'
            ? 'opacity-50'
            : 'bg-beige-light cursor-pointer hover:-translate-y-1 hover:scale-110 hover:bg-blue-default duration-300'
        } md:h-44 md:w-62`"
      >
        <div class="h-full flex justify-start flex-col gap-2">
          <h3>{{ program.name }}</h3>
          <div class="text-sm">
            Matching Percentage:
            <span class="font-bold">{{ program.matchingPercentage }}</span>
          </div>
          <div class="text-sm">
            Start Date: <span class="font-bold">{{ program.startDate }}</span>
          </div>
          <div v-if="program.endDate" class="text-sm">
            End Date: <span class="font-bold">{{ program.endDate }}</span>
          </div>
        </div>
      </Card>
    </div>
    <div v-else>Sorry, no available programs for you.</div>
  </BasicLayout>
</template>
