<script>
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  name: "Sidebar",
  components: {
    BaseIcon,
  },
  data() {
    return {
      icons: {
        logo: require("@/assets/logo/logo_mop-plain.svg"),
      },
      items: {
        profile: "profile",
        employees: "employees",
        programs: "programs",
        dashboard: "dashboard",
        data: "data",
        charities: "charities",
      },
      active: null,
    };
  },
  watch: {
    $route: {
      handler: function (object) {
        const route = object.path.split("/");
        if (this.items[route[2]]) this.active = route[2];
        else this.active = null;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<template>
  <div
    class="hidden lg:fixed lg:flex shadow-md justify-center bottom-0 left-0 top-0 w-36 bg-orange-light pt-24"
  >
    <ul class="list-none pt-12 flex flex-col items-center">
      <li
        :class="`h-18 flex items-center  ${
          active == items.dashboard ? 'relative active-tab ml-12' : ''
        }`"
      >
        <router-link to="/company/dashboard">
          <BaseIcon
            name="dashboard"
            :class="`${
              active == items.dashboard
                ? 'text-red-default'
                : 'text-blue-dark w-full'
            }`"
          />
        </router-link>
      </li>
      <li
        :class="`h-18 flex items-center  ${
          active == items.profile ? 'relative active-tab ml-12' : ''
        }`"
      >
        <router-link to="/company/profile">
          <BaseIcon
            :iconWidth="51"
            name="profile"
            :class="`${
              active == items.profile
                ? 'text-red-default'
                : 'text-blue-dark w-full'
            }`"
          />
        </router-link>
      </li>
      <li
        :class="`h-18 flex items-center jsutify-center ${
          active == items.programs ? 'relative active-tab ml-12 ' : ''
        }`"
      >
        <router-link to="/company/programs">
          <BaseIcon
            :iconWidth="51"
            name="program"
            :class="`${
              active == items.programs
                ? 'text-red-default'
                : 'text-orange-light '
            }`"
          />
        </router-link>
      </li>
      <li
        :class="`h-18 flex items-center  ${
          active == items.employees ? 'relative active-tab ml-12' : ''
        }`"
      >
        <router-link to="/company/employees">
          <BaseIcon
            :iconWidth="51"
            name="employees"
            :class="`${
              active == items.employees
                ? 'text-red-default '
                : 'text-blue-dark w-full'
            }`"
          />
        </router-link>
      </li>
      <li
        :class="`h-18 flex items-center  ${
          active == items.charities ? 'relative active-tab ml-12' : ''
        }`"
      >
        <router-link to="/company/charities">
          <BaseIcon
            :iconWidth="51"
            name="charities"
            :class="`${
              active == items.charities
                ? 'text-red-default '
                : 'text-blue-dark w-full'
            }`"
          />
        </router-link>
      </li>
      <li
        :class="`h-18 flex items-center  ${
          active == items.data ? 'relative active-tab ml-12' : ''
        }`"
      >
        <router-link to="/company/data">
          <BaseIcon
            :iconWidth="51"
            name="data"
            :class="`${
              active == items.data
                ? 'text-red-default'
                : 'text-blue-dark w-full'
            }`"
          />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.header div {
  @apply mr-8;
}

li:not(:last-child) {
  @apply cursor-pointer mb-8;
}

.active-tab:before {
  @apply bg-orange-light;

  content: " ";
  position: absolute;
  display: block;
  height: 24px; /* you can do fancier stuff, but then the tringle digs into the box further... */
  width: 24px;
  bottom: 20%; /* < or = to half the fake triangle's width? */
  left: 65px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

img.light-shadow {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
}
</style>
