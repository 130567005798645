<script>
import { mapGetters } from "vuex";
import Table from "@/components/Table.vue";

export default {
  name: "CharityIndex",
  components: {
    Table,
  },
  props: {
    charities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        trash: require("@/assets/icons/trash.svg"),
        edit: require("@/assets/icons/pencil-square.svg"),
      },
      headers: ["Type", "Charity name", "Website"],
    };
  },
  computed: {
    ...mapGetters({
      charities: "charity/data",
    }),
  },
  methods: {
    editCharity(charity) {
      this.$emit("edit", charity);
    },
    removeCharity(id) {
      this.$swal({
        title: "Are you sure?",
        text: "People who have selected this charity will still be able to continue their donation!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove charity!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("delete", id);
        }
      });
    },
  },
};
</script>
<template>
  <div class="w-full" v-if="charities.length > 0">
    <Table :headers="headers" :actionCols="1">
      <template v-slot:content>
        <tr
          :class="`text-center align-middle ${
            !charity.customCreated ? 'text-grey-default' : ''
          }`"
          v-for="charity in charities"
          :key="charity.name"
        >
          <td>{{ charity.creationType }}</td>
          <td>{{ charity.name }}</td>
          <td>{{ charity.website }}</td>
          <td v-if="charity.customCreated">
            <div class="flex">
              <div @click.prevent="editCharity(charity)">
                <img :src="icons.edit" class="h-5 cursor-pointer" />
              </div>
              <div class="ml-2" @click.prevent="removeCharity(charity.id)">
                <img :src="icons.trash" class="h-5 cursor-pointer" />
              </div>
            </div>
          </td>
        </tr>
      </template>
    </Table>
  </div>
</template>
