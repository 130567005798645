import axios from "../utils/interceptor";
import { UserSerializer } from "../serializer/UserSerializer";

export default {
  async verifyUser(url) {
    return axios.get(url);
  },
  async login(payload) {
    const data = UserSerializer.formatLoginRequest(payload);
    return axios.post("/login", data);
  },
  async logout() {
    return axios.post("/logout");
  },
  async forgotPassword(payload) {
    return axios.post("/forgot-password", payload);
  },
  async getAuthUser() {
    const { data } = await axios.get("/user");
    return UserSerializer.formatGetRequest(data);
  },
  async setPassword(payload) {
    return axios.post("/employees/set-initial-password", payload);
  },
  async resetPassword(payload) {
    return axios.post("/reset-password", payload);
  },
  async updatePassword(payload) {
    return axios.put("/user/password", payload);
  },
  async registerUser(payload) {
    return axios.post("/register", payload);
  },
  async sendVerification(payload) {
    return axios.post("/email/verification-notification", payload);
  },
  async updateUser(id, payload) {
    return axios.put(`/user/${id}`, payload);
  },
  async deleteUser(id) {
    return axios.destory(`/user/${id}`);
  },
};
