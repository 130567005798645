import { createStore } from "vuex";
import user from "./modules/user";
import ui from "./modules/ui";
import company from "./modules/company";
import program from "./modules/program";
import employee from "./modules/employee";
import donor from "./modules/donor";
import charity from "./modules/charity";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    ui,
    company,
    program,
    employee,
    donor,
    charity,
  },
});
