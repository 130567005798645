import { DATES } from "@/constants";

const getHistory = (details) => {
  return {
    emailSend: {
      label: "Email has been sent",
      value: "unknown",
    },
    selectedCharity: {
      label: "Charity and donation sum selected",
      value: details && details.created_at ? details.created_at : null,
    },
    started: {
      label: "Started",
      value:
        details && details.start_date <= DATES.today
          ? details.start_date
          : null,
    },
    terminated: {
      label: "Terminated",
      value:
        details && details.end_date <= DATES.today ? details.end_date : null,
    },
  };
};

const getDonationDetails = (participant) => {
  return {
    id: participant._id,
    amount: participant.amount,
    status: participant.status,
    wallet: participant.wallet,
    startDate: participant.start_date,
    endDate: participant.end_date,
    email: participant.email ?? null,
    userId: participant.user_id ?? null,
    history: getHistory(participant),
  };
};

export const ProgramSerializer = {
  formatPostRequest(data) {
    return {
      name: data.name,
      start_date: data.startDate,
      end_date: data.endDate,
      matching_percentage: data.matchingPercentage,
      max_donation: data.maxDonation,
    };
  },
  formatTerminateRequest(endDate) {
    return {
      end_date: endDate,
    };
  },
  formatGetRequest(program) {
    return {
      name: program.name ?? undefined,
      id: program.id ?? undefined,
      isActive: program.is_active ?? undefined,
      matchingPercentage: program.matching_percentage ?? undefined,
      maxDonation: program.max_donation ?? undefined,
      employeeDonationAmount: program.employee_donation_amount ?? 0,
      companyDonationAmount: program.company_donation_amount ?? 0,
      startDate: program.start_date
        ? new Date(program.start_date).toISOString().split("T")[0]
        : null,
      endDate: program.end_date
        ? new Date(program.end_date).toISOString().split("T")[0]
        : null,
      status: this.getStatus(program) ?? null,
    };
  },
  formatReminderRequest(userId, programId) {
    return {
      user_id: userId,
      program_id: programId,
    };
  },
  formatGetParticipantsRequest(data) {
    const donationListUsers = data.donations.map((item) => {
      return item.user_id;
    });

    const invitees =
      data.invitees && data.invitees.length > 0
        ? data.invitees.map((item) => {
            return {
              userId: item.user_id,
              email: item.email,
              invitedAt: item.invited_at,
              hasDonation: donationListUsers.includes(item.user_id),
              remindedAt: item.reminded_at ?? null,
            };
          })
        : [];
    const donations =
      data.donations.length > 0
        ? data.donations.map((donation) => getDonationDetails(donation))
        : [];
    return {
      invitees,
      donations,
    };
  },

  getStatus(program) {
    if (!program.is_active) return "terminated";
    else if (new Date(program.start_date) > new Date()) return "planned";
    else return "active";
  },
  statusCompleteDonorPayload: function (data) {
    return {
      charity_id: data.charityId,
      charity_name: data.charityName,
      billing_details: {
        name: data.name,
        street: data.street,
        city: data.city,
        zip: data.zip,
        country: data.country,
      },
      amount: data.amount,
      start_date: data.startDate,
      end_date: data.endDate === "" ? undefined : data.endDate,
      email_verified_at: new Date().toISOString().slice(0, 10),
    };
  },
};
