<script>
export default {
  name: "AlertBox",
  props: {
    type: {
      type: String,
      default: "info",
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        info: require("../../assets/icons/info-icon.svg"),
      },
    };
  },
  methods: {
    setClasses() {
      switch (this.type) {
        case "info":
          return "box-info";
        case "error":
          return "box-error";
        case "warning":
          return "box-warning";
        case "success":
          return "box-success";
        default:
          return "box-info";
      }
    },
  },
};
</script>
<template>
  <div :class="[setClasses(), 'p-4 rounded-md']">
    <div class="text-sm flex gap-4 items-center">
      <img v-if="showIcon" :src="icons.info" class="h-6" />
      <slot></slot>
    </div>
  </div>
</template>
