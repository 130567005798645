<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Sidebar from "./Sidebar.vue";
import TitleWithSub from "./TitleWithSub.vue";
import LoadingSpinner from "../design/LoadingSpinner.vue";
import ErrorComponent from "./ErrorComponent.vue";
export default {
  name: "BasicLayout",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    contentWrapperProps: {
      type: String,
      default: "",
    },
    hideSidebar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Header,
    ErrorComponent,
    Footer,
    Sidebar,
    TitleWithSub,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      programs: "program/data",
    }),
  },
};
</script>
<template>
  <div class="h-full w-full max-w-screen">
    <ErrorComponent />
    <Header :hideSidebar="hideSidebar" />
    <div class="content-min-height flex">
      <Sidebar v-if="isLoggedIn && !hideSidebar" />
      <div
        :class="`${isLoggedIn && !hideSidebar ? 'lg:pl-48' : ''} ${
          isLoggedIn && !hideSidebar ? 'py-32' : 'py-12'
        } content-wrapper-x flex-1 ${contentWrapperProps}`"
      >
        <TitleWithSub
          v-if="title || subTitle"
          :title="title"
          :subTitle="subTitle"
        />
        <transition name="slide-fade" mode="out-in">
          <LoadingSpinner size="full" v-if="isLoading" />
          <slot v-else></slot>
        </transition>
      </div>
    </div>
    <Footer :hasSidebar="isLoggedIn && !hideSidebar" />
  </div>
</template>
