<script>
//VUEX
import { mapGetters, mapActions } from "vuex";
//UTILS
import Error from "@/utils/Error";

//COMPONENTS
import EditDetails from "@/components/EditDetails";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
import Modal from "@/components/design/Modal.vue";
//SERIALIZER
import { CharitySerializer } from "@/serializer/CharitySerializer";

export default {
  name: "CharityCreate",
  components: {
    EditDetails,
    LoadingSpinner,
    Modal,
  },
  props: {
    charity: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      error: [],
      id: null,
      form: {
        name: "",
        title: "",
        website: "",
        description: "",
        isNgo: false,
        paymentType: "bank",
        categories: "",
        formUrl: "",
        /* Format name - iban - bic */
        bankName: "",
        bankIban: "",
        bankBic: "",
      },
      fields: {
        general: [
          {
            id: "name",
            label: "Name",
            type: "text",
          },
          {
            id: "title",
            label: "Title",
            type: "text",
          },
          {
            id: "description",
            label: "Description",
            type: "textarea",
            fullWidth: true,
          },
          {
            id: "website",
            label: "Website",
            type: "text",
          },
          {
            id: "categories",
            label: "Categories (separated by comma)",
            type: "text",
          },
        ],
        paymentType: {
          form: [
            {
              id: "formUrl",
              label: "Form url",
              type: "text",
              hint: "If you use a Bank Details, please leave this empty. Otherwise provide url to a donation form.",
            },
          ],
          bank: [
            {
              id: "bankName",
              label: "Name",
              type: "text",
              hint: "Bank account holder name",
            },
            {
              id: "bankIban",
              label: "Iban",
              type: "text",
              hint: "Please provider your full iban",
            },
            {
              id: "bankBic",
              label: "bic",
              type: "text",
              hint: "Please provider your bic",
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "charity/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      getAllCharities: "charity/getAllCharities",
      updateCharity: "charity/updateCharity",
      createCharity: "charity/createCharity",
    }),
    resetForm() {
      this.form = {
        name: "",
        title: "",
        website: "",
        description: "",
        isNgo: false,
        categories: "",
        formUrl: "",
        bankName: "",
        bankIban: "",
        bankBic: "",
        paymentType: "bank",
      };
    },
    prePopulateForm() {
      this.id = this.charity ? this.charity.id : null;
      this.form = {
        name: this.charity.name,
        title: this.charity.title,
        website: this.charity.website,
        description: this.charity.description,
        isNgo: this.charity.isNgo,
        categories: this.charity.categories.join(","),
        formUrl: this.charity.formUrl,
        paymentType: this.charity.formUrl ? "form" : "bank",
        bankIban: this.charity.bankIban ?? "",
        bankBic: this.charity.bankBic ?? "",
        bankName: this.charity.bankName ?? "",
      };
    },
    updateValue(id, value) {
      if (this.error.length > 0) this.error = [];
      this.form[id] = value;
    },
    validateInput() {
      for (const [key, value] of Object.entries(this.form)) {
        if (
          (key === "title" ||
            key === "description" ||
            key === "name" ||
            key === "categories" ||
            key === "website") &&
          value.length < 3
        ) {
          this.error = [
            ...this.error,
            new Error(key, "Please provide at least 3 characters"),
          ];
        }
        if (key === "formUrl" && this.form.paymentType === "form") {
          if (this.form.formUrl.length < 6) {
            this.error = [
              ...this.error,
              new Error(key, "Please add a valid form url"),
            ];
          }
        }
        if (
          (key === "bankIban" || key === "bankName" || key === "bankBic") &&
          this.form.paymentType === "bank"
        ) {
          if (
            this.form.bankIban.length < 3 ||
            this.form.bankName < 3 ||
            this.form.bankBic < 3
          ) {
            this.error = [
              ...this.error,
              new Error(key, "Please add full bank Details"),
            ];
          }
        }
      }
      console.log("Error", this.error);
      if (this.error.length > 0) return false;
      return true;
    },

    createOrUpdateCharity() {
      this.error = [];
      if (this.validateInput()) {
        if (this.id) {
          this.updateCharity({
            id: this.id,
            data: CharitySerializer.formatPostRequest(this.form),
          });
        } else {
          this.createCharity(CharitySerializer.formatPostRequest(this.form));
        }
      }
    },
    triggerDeletePopup() {
      this.deleteCharity(this.id);
    },
    closeForm(success) {
      this.$emit("closed", { success: success ?? false });
      this.resetForm();
    },
  },
  mounted() {
    if (this.charity) {
      this.id = this.charity.id;
      this.prePopulateForm();
    }
  },
};
</script>
<template>
  <div class="md:w-96">
    <LoadingSpinner v-if="isLoading.store || isLoading.update" size="full" />
    <Modal v-else @close="closeForm()">
      <EditDetails
        :gridCols="2"
        :showLabelCancel="false"
        :title="
          this.charity && this.charity.id
            ? $t('charities.create.edit')
            : $t('charities.create.new')
        "
        :fields="fields.general"
        :form="form"
        :errorProp="error"
        @update="(values) => (this.form = values)"
        @submit="createOrUpdateCharity"
        @cancel="closeForm"
      >
        <div>
          <input
            class="inline-block"
            type="checkbox"
            id="checkbox"
            v-model="form.isNgo"
          />
          <label class="inline-block ml-4" for="checkbox"
            >Is registered NGO/NON-Profit</label
          >
        </div>
        <div class="flex gap-4 my-4 flex-col">
          <div>
            <label for="paymentType"
              >In order to receive donations, I will provide:</label
            >
            <select
              @change="updateValue('paymentType', $event.target.value)"
              v-model="form.paymentType"
              class="bg-orange-light ml-4 p-2 shadow-md rounded-md"
            >
              <option value="bank">Bank details</option>
              <option value="form">Link to online form</option>
            </select>
          </div>
          <div>
            <EditDetails
              :btn="{
                show: {
                  submit: false,
                  cancel: false,
                },
              }"
              title=""
              :gridCols="2"
              :showLabelCancel="false"
              :fields="
                form.paymentType === 'bank'
                  ? fields.paymentType.bank
                  : fields.paymentType.form
              "
              :form="form"
              :errorProp="error"
              @update="(values) => (this.form = values)"
              @submit="createOrUpdateCharity"
              @cancel="closeForm"
            ></EditDetails>
          </div>
        </div>
      </EditDetails>
    </Modal>
  </div>
</template>
