<script>
export default {
  name: "TagComponent",
  props: {
    bgColor: {
      type: String,
      default: "beige-light",
    },
    color: {
      type: String,
      default: "black",
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
<template>
  <div :class="`${classes} bg-${bgColor} p-2 text-xs text-black rounded-md`">
    <slot></slot>
  </div>
</template>
