<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    viewBox="0 0 288 288"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="144.319"
      cy="144.32"
      rx="31.954"
      ry="31.954"
      stroke="#404040"
      stroke-width="10"
      stroke-linecap="round"
    />
    <ellipse
      cx="251.046"
      cy="251.046"
      rx="31.954"
      ry="31.954"
      stroke="#404040"
      stroke-width="10"
      stroke-linecap="round"
    />
    <path
      d="M194.168 273.671C178.606 279.696 161.689 283 144 283C67.2324 283 5 220.768 5 144C5 67.2324 67.2324 5 144 5C220.768 5 283 67.2324 283 144C283 161.206 279.874 177.681 274.159 192.89"
      stroke="#404040"
      stroke-width="10"
      stroke-linecap="round"
    />
    <path
      d="M112.259 61.4492C122.204 57.5989 133.016 55.4873 144.32 55.4873C193.381 55.4873 233.152 95.2588 233.152 144.319C233.152 193.38 193.381 233.152 144.32 233.152C95.2595 233.152 55.488 193.38 55.488 144.319C55.488 133.324 57.4858 122.794 61.1383 113.075"
      stroke="#404040"
      stroke-width="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "IconProgram",
  props: {
    iconHeight: {
      type: String,
      default: "54",
    },
    iconWidth: {
      type: String,
      default: "36",
    },
  },
};
</script>
