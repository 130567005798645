<script>
import { mapGetters, mapActions } from "vuex";
import LoadingSpinner from "./components/design/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      userLoading: "user/isLoading",
    }),
    methods: {
      ...mapActions({
        getUser: "user/getUser",
      }),
    },
  },
};
</script>

<template>
  <div class="relative">
    <div id="overlay-target"></div>
    <div id="nav">
      <div>
        <LoadingSpinner size="full" v-if="userLoading.get" />
        <keep-alive><router-view /></keep-alive>
      </div>
    </div>
  </div>
</template>

<style></style>
