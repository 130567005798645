<script>
import Button from "../design/Button.vue";

export default {
  name: "BasicCard",
  components: {
    Button,
  },
  props: {
    btnTitle: {
      type: String,
    },
    title: {
      type: String,
    },
    bgColor: {
      type: String,
      default: "bg-blue-default",
    },
    color: {
      type: String,
      default: "text-white",
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
<template>
  <div :class="`${classes} ${bgColor} ${color} p-6 shadow-md rounded-md`">
    <div class="flex justify-between gap-4 text-black">
      <h3 v-if="title">{{ title }}</h3>
      <Button size="sm" v-if="btnTitle" @click.prevent="this.$emit('submit')">
        {{ btnTitle }}
      </Button>
    </div>
    <slot></slot>
  </div>
</template>
