import EmployeeService from "../../services/EmployeeService";

const state = {
  data: null,
  isLoading: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
  error: {
    get: null,
    store: null,
    update: null,
    delete: null,
  },
  success: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
};

const getters = {
  data: (state) => state.data,
  isLoading: (state) => state.isLoading,
  success: (state) => state.success,
  error: (state) => state.error,
};

const actions = {
  async createEmployee({ commit }, payload) {
    commit("SET_IS_LOADING", { store: true });
    commit("SET_EMPLOYEE_SUCCESS", { store: false });
    try {
      await EmployeeService.createEmployee(payload);
      commit("SET_EMPLOYEE_SUCCESS", { store: true });
    } catch (e) {
      commit("SET_EMPLOYEE_ERROR", { store: e });
    } finally {
      commit("SET_IS_LOADING", { store: false });
    }
  },

  async massCreateEmployees({ commit }, payload) {
    commit("SET_IS_LOADING", { store: true });
    setTimeout(async () => {
      commit("SET_EMPLOYEE_SUCCESS", { store: false });
      try {
        await EmployeeService.massCreateEmployees(payload);
        commit("SET_EMPLOYEE_SUCCESS", { store: true });
      } catch (e) {
        commit("SET_EMPLOYEE_ERROR", { store: e });
      } finally {
        commit("SET_IS_LOADING", { store: false });
      }
    }, 2000);
  },

  async updateEmployee({ commit }, { id, data }) {
    commit("SET_IS_LOADING", { update: true });
    commit("SET_EMPLOYEE_SUCCESS", { update: false });
    try {
      await EmployeeService.updateEmployee(id, data);
      commit("SET_EMPLOYEE_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_EMPLOYEE_ERROR", { update: e });
    } finally {
      commit("SET_IS_LOADING", { update: false });
    }
  },

  async getAllEmployees({ commit }, { id }) {
    commit("SET_IS_LOADING", { get: true });
    commit("SET_EMPLOYEE_SUCCESS", { get: false });
    try {
      const response = await EmployeeService.getAllEmployees(id);
      commit("SET_EMPLOYEE_DATA", response);
      commit("SET_EMPLOYEE_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_EMPLOYEE_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async deleteEmployee({ commit }, id) {
    commit("SET_IS_LOADING", { delete: true });
    commit("SET_EMPLOYEE_SUCCESS", { delete: false });
    try {
      await EmployeeService.deleteEmployee(id);
      commit("SET_EMPLOYEE_SUCCESS", { delete: true });
    } catch (e) {
      commit("SET_EMPLOYEE_ERROR");
    } finally {
      commit("SET_IS_LOADING", { delete: false });
    }
  },

  resetEmployee({ commit }, requestType, resetData = false) {
    commit("SET_EMPLOYEE_ERROR", { [requestType]: false });
    commit("SET_EMPLOYEE_SUCCESS", { [requestType]: false });
    if (resetData) commit("SET_EMPLOYEE_DATA", null);
  },

  resetError({ commit }) {
    commit("SET_EMPLOYEE_ERROR", {
      get: null,
      store: null,
      update: null,
      delete: null,
    });
  },
};

const mutations = {
  SET_EMPLOYEE_DATA: (state, data) => (state.data = data),
  SET_EMPLOYEE_SUCCESS: (state, success) =>
    (state.success = {
      ...Object.keys(state.success).forEach((v) => (state.success[v] = false)),
      ...success,
    }),
  SET_EMPLOYEE_ERROR: (state, error) =>
    (state.error = { ...state.error, ...error }),
  SET_IS_LOADING: (state, isLoading) =>
    (state.isLoading = { ...state.isLoading, ...isLoading }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
