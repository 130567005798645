<script>
//COMPONENTS
import LoadingSpinner from "@/components/design/LoadingSpinner";

//SERVICES
import ProgramService from "@/services/ProgramService";
import CharityService from "@/services/CharityService";
import { downloadCsv } from "@/utils";

export default {
  name: "ExportSection",
  components: {
    LoadingSpinner,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      icons: {
        download: require("@/assets/icons/download.svg"),
      },
      isLoading: false,
    };
  },
  methods: {
    async download(type, role) {
      this.isLoading = true;
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let fileName = "download-" + date;
      try {
        let csv = null;
        switch (type) {
          case "amount":
            csv = await ProgramService.downloadDonationAmountByEmployee(
              this.programId
            );
            fileName = "employeeid-with-amount-" + date;
            break;
          case "charity":
            csv = await CharityService.downloadDonationByCharity({
              program_id: this.programId,
              role: role,
            });
            fileName = `${role}-for-charities-` + date;
            break;
          default:
            console.log("No matching action type");
            break;
        }
        if (csv.length > 0)
          downloadCsv(csv, () => (this.isLoading = false), fileName);
        else {
          this.triggerToast("error", null, "No active donations to export");
          this.isLoading = false;
        }
      } catch (e) {
        this.triggerToast("error", e, "Oops something went wrong");
        this.isLoading = false;
      }
    },
  },
};
</script>
<template>
  <div class="mr-auto">
    <div class="flex flex-col gap-4">
      <div
        class="cursor-pointer"
        @click.prevent="() => download('amount', null)"
      >
        <div
          class="hover:font-bold justify-between shadow-md p-6 flex gap-4 items-center bg-orange-default rounded-md"
        >
          <div class="my-auto">
            {{ $t("data.csv.card1.title") }}
          </div>
          <img :src="icons.download" class="h-6" />
        </div>
      </div>
      <div
        class="cursor-pointer"
        @click.prevent="() => download('charity', 'employee')"
      >
        <div
          class="hover:font-bold justify-between shadow-md p-6 flex gap-4 items-center bg-green-default rounded-md"
        >
          <div class="my-auto">
            {{ $t("data.csv.card2.title") }}
          </div>
          <img :src="icons.download" class="h-6" />
        </div>
      </div>
      <div
        class="cursor-pointer"
        @click.prevent="() => download('charity', 'company')"
      >
        <div
          class="hover:font-bold justify-between shadow-md p-6 flex gap-4 items-center bg-blue-default rounded-md"
        >
          <div class="my-auto">
            {{ $t("data.csv.card3.title") }}
          </div>
          <img :src="icons.download" class="h-6" />
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <LoadingSpinner size="full" />
    </div>
  </div>
</template>
