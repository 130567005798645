<script>
import { mapGetters, mapActions } from "vuex";

//COMPONENTS
import Table from "@/components/Table.vue";
import Button from "@/components/design/Button.vue";
import StatusButton from "@/components/StatusButton.vue";
import DonorDetails from "./donations/DonorDetails.vue";
import Modal from "@/components/design/Modal.vue";

export default {
  name: "ProgramIndex",
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  components: {
    Table,
    Button,
    DonorDetails,
    StatusButton,
    Modal,
  },
  data() {
    return {
      formType: "index",
      icons: {
        chevronDown: require("@/assets/icons/chevron-down.svg"),
        chevronUp: require("@/assets/icons/chevron-up.svg"),
        participants: require("@/assets/icons/participants.svg"),
        edit: require("@/assets/icons/pencil-square.svg"),
        trash: require("@/assets/icons/trash.svg"),
      },
      showProgramId: "",
      headers: [
        "Name",
        "Start Date",
        "End Date",
        "Matching Percentage",
        "Max Matching Donation",
        "Invited Participants",
        "Status",
        "Employee Value",
        "Company Value",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      programs: "program/data",
    }),
  },
  watch: {
    showProgramId(newValue) {
      if (newValue) {
        this.getAllParticipants(newValue);
      }
    },
  },
  methods: {
    ...mapActions({
      getAllParticipants: "program/getAllParticipants",
      resetDonorList: "donor/resetDonorList",
      getAllPrograms: "program/getAllPrograms",
    }),
    terminateProgram(program) {
      this.$emit("terminate", program);
    },
    deleteProgram(program) {
      this.$emit("delete", program);
    },
    onReminderSent() {
      this.triggerToast("success", null, "Reminder sent");
      this.getAllParticipants(this.showProgramId);
    },
    onAdded() {
      this.triggerToast("success", null, "Employee added to program!");
      this.getAllParticipants(this.showProgramId);
    },
    showAndHideParticipants(id) {
      if (this.showProgramId === id) {
        this.getAllPrograms({ company_id: this.user.companyDetails.id });
        this.formType = "index";
        this.showProgramId = "";
      } else {
        this.showProgramId = id;
        this.formType = "participants";
      }
    },
  },
};
</script>
<template>
  <div v-if="programs && programs.length > 0" class="w-full">
    <div class="my-6"></div>
    <Table bodybgColor="bg-transparent" :headers="headers" :actionCols="2">
      <template v-slot:content>
        <template v-for="program in programs" :key="program.name">
          <tr
            :class="[
              program.status === 'terminated'
                ? 'opacity-30 pointer-events-none'
                : '',
            ]"
          >
            <td>{{ program.name }}</td>
            <td>
              {{ program.startDate }}
            </td>
            <td>
              {{ program.endDate ? program.endDate : "None" }}
            </td>
            <td>
              <p>{{ program.matchingPercentage }} %</p>
            </td>
            <td>
              {{ program.maxDonation ? program.maxDonation + "€" : "None" }}
            </td>
            <td>
              <div class="h-full flex justify-center items-center gap-4">
                <span v-if="program.employeeDonationAmount > 0">{{
                  program.employeeDonationAmount
                }}</span>
              </div>
            </td>
            <td>
              <StatusButton
                :hasEndDate="program.endDate !== null"
                :hasStarted="program.startDate !== null"
                :status="program.status"
              />
            </td>
            <td>
              {{ program.employeeDonationAmount }}
              €
            </td>
            <td>{{ program.companyDonationAmount }} €</td>
            <td
              v-if="program.status !== 'terminated'"
              class="flex gap-2 m-4 items-center w-52"
            >
              <div
                @click.prevent="() => showAndHideParticipants(program.id)"
                :class="[
                  `
                  flex
                  items-center
                  px-2
                  py-2
                  justify-center
                 bg-green-default
                   shadow-md rounded-full
                  ${
                    program.status === 'terminated'
                      ? 'pointer-events-none'
                      : 'cursor-pointer hover:font-bold hover:rounded-md'
                  }
                `,
                ]"
              >
                <div class="flex items-center">
                  + <img :src="icons.participants" class="h-6" />
                </div>
              </div>
              <div
                @click.prevent="terminateProgram(program)"
                class="flex gap-2 hover:font-bold cursor-pointer"
              >
                <img :src="icons.edit" class="h-5" />
                {{ $t("programs.index.btn.terminate") }}
              </div>
              <div
                v-if="program.employeeDonationAmount < 1"
                @click.prevent="deleteProgram(program)"
              >
                <img :src="icons.trash" class="h-6 cursor-pointer" />
              </div>
            </td>
          </tr>
        </template>
      </template>
    </Table>
    <div v-if="formType === 'participants'">
      <Modal
        classes="w-full flex"
        @close="() => showAndHideParticipants(showProgramId)"
      >
        <div>
          <DonorDetails
            :programId="showProgramId"
            @activated="onActivation"
            @added="onAdded"
            @reminderSent="onReminderSent"
          />
          <div class="w-full flex">
            <Button
              :outlined="true"
              classes="mr-auto"
              @click.prevent="() => showAndHideParticipants(showProgramId)"
              >{{ $t("common.btn.saveClose") }}</Button
            >
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>
<style scoped>
td {
  @apply text-center;
}
</style>
