<script>
import Button from "../../components/design/Button.vue";
export default {
  name: "SignupSuccess",
  components: {
    Button,
  },
  data() {
    return {
      icons: {
        success: require("../../assets/icons/success-check.svg"),
      },
    };
  },
};
</script>
<template>
  <div>
    <div class="flex items-center h-full">
      <img :src="icons.success" class="h-12 w-12 mr-4" />
      <h2 class="text-green-dark">{{ $t("signup.success.messageTitle") }}</h2>
    </div>
    <div class="my-4">{{ $t("signup.success.description") }}</div>
    <Button classes="w-44 mt-4" @click.prevent="this.$router.push('/')">{{
      $t("signup.success.btn")
    }}</Button>
  </div>
</template>

<style scoped></style>
