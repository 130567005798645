<script>
import Button from "../components/design/Button.vue";
import BasicLayout from "../components/layout/BasicLayout.vue";
import TitleWithSub from "../components/layout/TitleWithSub.vue";
import AuthService from "@/services/AuthService";
import { UserSerializer } from "@/serializer/UserSerializer";

export default {
  name: "ForgotPassword",
  components: {
    BasicLayout,
    TitleWithSub,
    Button,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      form: {
        email: null,
        isCompany: false,
      },
    };
  },
  methods: {
    async requestPasswordReset() {
      try {
        const payload = UserSerializer.formatForgotPasswordPayload(this.form);
        await AuthService.forgotPassword(payload);
        this.triggerToast(
          "success",
          null,
          "Link sent. Please check your inbox."
        );
      } catch (e) {
        this.triggerToast("error", e, "Oops something went wrong");
      }
    },
  },
};
</script>

<template>
  <BasicLayout :hideSidebar="true" contentWrapperProps="bg-orange-light">
    <div class="h-full content-wrapper-x max-width flex flex-col">
      <TitleWithSub
        :title="$t('forgotPassword.title')"
        :subTitle="$t('forgotPassword.subTitle')"
      />
      <div class="flex gap-4 flex-col">
        <label for="email">Provide Email Address</label>
        <input
          class="p-4 shadow-md rounded-md mr-auto h-12"
          type="email"
          id="email"
          v-model="form.email"
        />
      </div>
      <div class="my-8">
        <input
          class="inline-block"
          type="checkbox"
          id="checkbox"
          v-model="form.isCompany"
        />
        <label class="inline-block ml-4" for="checkbox">Company Account</label>
      </div>
      <div class="flex flex-wrap gap-4">
        <Button classes="w-1/2" @click.prevent="requestPasswordReset">{{
          $t("forgotPassword.btn")
        }}</Button>
      </div>
    </div>
  </BasicLayout>
</template>
