<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 64 64`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 68H68V56H72V48L68 28H8L4 48V56H8V80H44V56H60V68ZM36 72H16V56H36V72Z"
    />
    <path d="M68 16H8V24H68V16Z" />
    <path d="M80 72V60H72V72H60V80H72V92H80V80H92V72H80Z" />
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "IconCharities",
  props: {
    iconHeight: {
      type: String,
      default: "64",
    },
    iconWidth: {
      type: String,
      default: "64",
    },
  },
};
</script>
