import { CHARITYTYPES, CREATIONTYPES } from "../constants";

const getCryptoAccount = (data) => {
  return {
    btc: data.btc ?? null,
    eth: data.eth ?? null,
  };
};

export const CharitySerializer = {
  formatGetRequest: function (data) {
    return {
      id: data.id,
      isStar: false,
      name: data.name,
      title: data.title,
      formUrl: data.form_url,
      description: data.description,
      website: data.website,
      logoUrl: data.logo_url,
      bannerUrl: data.banner_url,
      profileUrl: data.profile_url,
      videoUrl: data.video_url,
      creatorId: data.creator_id,
      creationType: data.creation_type,
      isNgo: data.charity_type === CHARITYTYPES.ngo,
      customCreated: data.creation_type === CREATIONTYPES.custom,
      categories:
        data.categories && data.categories.indexOf(",") > -1
          ? data.categories.split(",")
          : [data.categories],
      tags:
        data.tags && data.tags.indexOf(",") > -1 ? data.tags.split(",") : null,
      bankName: data.bank_account ? data.bank_account.name : "",
      bankIban: data.bank_account ? data.bank_account.iban : "",
      bankBic: data.bank_account ? data.bank_account.bic : "",
      cryptoAccount: data.crypto_account
        ? getCryptoAccount(data.crypto_account)
        : null,
    };
  },
  formatPostRequest: function (data) {
    return {
      name: data.name,
      description: data.description,
      title: data.title,
      website: data.website,
      form_url: data.paymentType === "form" ? data.formUrl : "",
      charity_type: data.isNgo ? CHARITYTYPES.ngo : CHARITYTYPES.nonNgo,
      categories: data.categories,
      bank_account:
        data.paymentType === "bank"
          ? {
              name: data.bankName,
              bic: data.bankBic,
              iban: data.bankIban,
            }
          : undefined,
      creation_type: "company",
    };
  },
};
