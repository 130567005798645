<script>
//VUEX
import { mapGetters, mapActions } from "vuex";
//THRID PARTY
import VueNextSelect from "vue-next-select";
//COMPONENTS
import Button from "@/components/design/Button.vue";
import AlertBox from "@/components/AlertBox.vue";
import { ROUTEPATHS } from "../../../../constants";
export default {
  name: "DonorAdd",
  components: {
    VueNextSelect,
    Button,
    AlertBox,
  },
  props: {
    programId: String,
  },
  data() {
    return {
      icons: {
        trash: require("@/assets/icons/trash.svg"),
        info: require("@/assets/icons/info-icon.svg"),
      },
      selectedEmployees: [],
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/data",
      user: "user/data",
      isLoading: "donor/isLoading",
      donors: "donor/data",
      participantes: "program/participants",
      apiCallSuccess: "donor/success",
    }),
    selectableEmployees() {
      if (this.participantes.invitees) {
        const donorIds = this.participantes.invitees.map((item) => item.userId);
        return this.employees.filter(function (item) {
          return donorIds.indexOf(item.id) === -1;
        });
      }
      return [];
    },
    selectedEmployeesNames() {
      if (this.selectedEmployees.length > 0) {
        return this.selectedEmployees.map((item) => {
          return item.email;
        });
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getAllEmployees: "employee/getAllEmployees",
      addParticipants: "program/addParticipants",
      resetSuccess: "donor/resetSuccess",
      setActiveTab: "ui/setActiveTab",
    }),
    removeFromSelection(email) {
      this.selectedEmployees = this.selectedEmployees.filter((item) => {
        return item.email !== email;
      });
    },
    redirectToEmployees() {
      this.$router.push(ROUTEPATHS.company.employees);
    },
    unSelectAll() {
      this.selectedEmployees = [];
    },
    selectAll() {
      this.selectedEmployees = this.selectableEmployees;
    },
    async addParticipantsToProgram() {
      await this.addParticipants({
        programId: this.programId,
        employees: this.selectedEmployees,
      });
      this.$emit("added");
    },
  },
  mounted() {
    this.getAllEmployees({ id: this.user.companyDetails.id });
  },
};
</script>
<template>
  <div class="w-screen lg:w-auto">
    <div v-if="employees">
      <div
        class="my-4"
        v-if="employees.length > 0 && selectableEmployees.length > 0"
      >
        <div class="my-4">
          {{ $t("programs.index.employees.addMessage") }}
        </div>
        <VueNextSelect
          :options="selectableEmployees"
          label-by="email"
          v-model="selectedEmployees"
          :close-on-select="true"
          :multiple="true"
        ></VueNextSelect>
      </div>
      <div
        v-if="selectableEmployees.length > 0 || selectedEmployees.length > 0"
      >
        <Button
          size="sm"
          :classes="
            selectableEmployees.length === selectedEmployees.length
              ? 'bg-green-default pointer-events-none bg-opacity-50'
              : 'bg-green-default'
          "
          @click.prevent="selectAll"
        >
          {{ $t("programs.index.employees.btn.selectAll") }}</Button
        >
        <Button
          size="sm"
          :classes="[
            `ml-4 ${
              selectedEmployees.length < 1
                ? 'pointer-events-none bg-opacity-50'
                : ''
            }`,
          ]"
          @click.prevent="unSelectAll"
        >
          {{ $t("programs.index.employees.btn.unselectAll") }}</Button
        >
        <div v-if="selectedEmployeesNames.length > 0" class="mb-12">
          <ul class="mb-8">
            <transition-group name="slide-left" tag="li" mode="out-in">
              <li
                v-for="employee in selectedEmployeesNames"
                :key="employee"
                class="my-2 flex justify-between p-4 border-b-2"
              >
                <div>
                  {{ employee }}
                </div>

                <div
                  @click="removeFromSelection(employee)"
                  class="text-red-default font-bold cursor-pointer"
                >
                  <img :src="icons.trash" class="h-4" />
                </div>
              </li>
            </transition-group>
          </ul>
          <Button @click="addParticipantsToProgram">{{
            $t("programs.index.employees.btn.selection")
          }}</Button>
          <div
            v-if="selectedEmployees.length > 0"
            class="flex gap-4 items-center"
          >
            <AlertBox type="none" class="mt-4 text-xs flex items-center gap-4">
              <div>{{ $t("programs.index.employees.instruction") }}</div>
            </AlertBox>
          </div>
        </div>
      </div>
      <div class="flex lg:flex-row flex-col gap-4 px-12 lg:px-0" v-else>
        <AlertBox type="none" class="flex lg:w-72">
          <div class="text-xs">
            {{ $t("programs.index.employees.noEmployees") }}
          </div>
        </AlertBox>
        <div class="my-4">
          <Button @click="redirectToEmployees">
            {{ $t("programs.index.employees.btn.add") }}</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>
