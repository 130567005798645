<script>
import Card from "@/components/Card";
import TagComponent from "@/components/design/TagComponent";
import Button from "@/components/design/Button";

export default {
  name: "CharityCard",
  components: {
    Card,
    TagComponent,
    Button,
  },
  props: {
    terminated: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    charity: Object,
    btn: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "bg-transparent",
    },
  },
  methods: {
    selectedCard(charity) {
      this.$emit("selected", charity);
    },
  },
};
</script>
<template>
  <Card
    :classes="[
      `${bgColor}  ${
        terminated ? 'bg-opacity-50 opacity-50' : 'bg-opacity-80'
      } flex flex-col h-full  rounded-md shadow-xl relative ${
        charity.isStar
          ? 'border-red-default border-2 '
          : 'border-transparent border-2'
      } `,
    ]"
  >
    <div
      :class="`absolute -right-8 -top-8 ${
        charity.customCreated ? 'bg-green-400 ' : 'bg-beige-default'
      } rounded-md p-4 `"
    >
      <div>
        {{
          charity.creationType === "company"
            ? "Company Selection"
            : "MOP Selection"
        }}
      </div>
    </div>
    <slot></slot>
    <div :class="[`flex flex-col gap-4 px-4`]">
      <div class="flex items-center overflow-hidden">
        <div>
          <h2 class="line-clamp-2">{{ charity.name }}</h2>
          <a class="underline" :href="charity.website" target="_blank"
            >More infos >></a
          >
        </div>
      </div>
      <div class="flex flex-col">
        <h2 class="text-base">Description</h2>
        <div class="text-sm">
          {{ charity.description }}
        </div>
      </div>
      <div class="flex gap-4 lg:gap-10 flex-col lg:flex-row">
        <div
          v-if="charity.tags && charity.tags.length > 0"
          class="flex lg:w-1/2 flex-col"
        >
          <h2 class="text-base">Tags</h2>
          <div class="flex gap-2">
            <TagComponent v-for="item in charity.tags" :key="item">
              <div>{{ item }}</div>
            </TagComponent>
          </div>
        </div>
        <div class="flex flex-col">
          <h2 class="text-base">Categories</h2>
          <div class="flex gap-2">
            <TagComponent v-for="item in charity.categories" :key="item">
              <div>{{ item }}</div>
            </TagComponent>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <h2 class="text-base">Organization Type</h2>
        <div>
          {{
            charity.isNgo
              ? $t("charities.list.typeNgo")
              : $t("charities.list.typeNonNgo")
          }}
        </div>
      </div>
      <div v-if="btn && !terminated" class="mt-auto">
        <div class="w-full">
          <Button size="md" @click="selectedCard(charity)">{{
            isSelected
              ? $t("charities.select.btnRemove")
              : $t("charities.select.btnSelect")
          }}</Button>
        </div>
      </div>
    </div>
  </Card>
</template>
