<script>
import BasicLayout from "@/components/layout/BasicLayout.vue";
import TitleWithSub from "@/components/layout/TitleWithSub.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
import EditDetails from "@/components/EditDetails.vue";
import AuthService from "@/services/AuthService";
import { UserSerializer } from "@/serializer/UserSerializer";
import { mapGetters } from "vuex";

export default {
  name: "SetPassword",
  components: {
    BasicLayout,
    EditDetails,
    TitleWithSub,
    LoadingSpinner,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      isLoading: false,
      success: false,
      error: [],
      form: {
        email: "",
        token: this.$route.params.token,
        password: "",
        privacy: true,
        agb: true,
      },
      fields: [
        {
          id: "email",
          label: "Email",
          type: "text",
        },
        {
          id: "password",
          label: "Password",
          type: "password",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userLoading: "user/isLoading",
      user: "user/data",
    }),
  },
  methods: {
    async setNewPassword() {
      if (this.form.password.length < 4) {
        return (this.error = [
          ...this.error,
          new Error(
            "password",
            "Password should be at least 4 characters long"
          ),
        ]);
      }
      try {
        this.isLoading = true;
        const payload = UserSerializer.formatSetPasswordPayload(this.form);
        await AuthService.setPassword(payload);
        this.triggerToast(
          "success",
          null,
          "Success! You will be redirected to login...",
          () =>
            setTimeout(() => {
              window.location.href = "/login";
              this.isLoading = false;
            }, 2500)
        );
      } catch (e) {
        this.triggerToast(
          "error",
          null,
          "Email/Token missmatch! Make sure you use the email link you have received."
        );
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <BasicLayout :hideSidebar="true" contentWrapperProps="bg-orange-light">
    <div>
      <LoadingSpinner size="full" v-if="isLoading" />
      <div class="h-full content-wrapper-x max-width flex flex-col">
        <TitleWithSub :title="$t('setPassword.title')" />
        <div class="mb-14">
          {{ $t("setPassword.description") }}
        </div>
        <div class="flex flex-wrap gap-4">
          <div class="w-full lg:w-1/2"></div>
          <EditDetails
            :isLoading="isLoading"
            inputClasses="bg-white"
            :gridCols="1"
            :titleBtn="$t('setPassword.btn')"
            :fields="fields"
            :errorProp="error"
            :form="form"
            :btn="{
              show: { submit: true, cancel: false },
              disabled: {
                submit: form.password.length < 4 || form.email.length < 4,
                cancel: false,
              },
            }"
            @update="(values) => (this.form = values)"
            @submit="setNewPassword"
          >
            <section class="my-8 flex flex-col justify-center">
              <!-- <div>
                <input
                  id="privacy"
                  aria-describedby="privacy"
                  type="checkbox"
                  class="mr-4"
                  v-model="form.privacy"
                />
                <label for="privacy">
                  <span
                    >I agree to
                    <a href="www.google.com">the privacy terms</a> that apply
                    when using myonepercent.</span
                  ></label
                >
              </div> -->
              <!-- <div>
                <input
                  id="agb"
                  aria-describedby="agb"
                  type="checkbox"
                  class="mr-4"
                  v-model="form.agb"
                />
                <label for="agb">
                  <span
                    >I agree to <a href="www.google.com">the agbs</a> that apply
                    when using myonepercent.</span
                  >
                </label>
              </div> -->
            </section>
          </EditDetails>
        </div>
      </div>
    </div>
  </BasicLayout>
</template>
