import CharityService from "@/services/CharityService";

const state = {
  data: null,
  isLoading: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
  error: {
    get: null,
    store: null,
    update: null,
    delete: null,
  },
  success: {
    get: false,
    store: false,
    update: false,
    delete: false,
  },
};

const getters = {
  data: (state) => state.data,
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
  success: (state) => state.success,
};

const actions = {
  async createCharity({ commit }, payload) {
    commit("SET_CHARITY_SUCCESS", { store: false });
    commit("SET_IS_LOADING", { store: true });
    try {
      await CharityService.createCharity(payload);
      commit("SET_CHARITY_SUCCESS", { store: true });
    } catch (e) {
      commit("SET_CHARITY_ERROR", { store: e });
    } finally {
      commit("SET_IS_LOADING", { store: false });
    }
  },

  async updateCharity({ commit }, { id, data }) {
    commit("SET_CHARITY_SUCCESS", { update: false });
    commit("SET_IS_LOADING", { update: true });
    try {
      await CharityService.updateCharity(id, data);
      commit("SET_CHARITY_SUCCESS", { update: true });
    } catch (e) {
      commit("SET_CHARITY_ERROR", { update: e });
    } finally {
      commit("SET_IS_LOADING", { update: false });
    }
  },

  async getAllCharities({ commit }, queryParamsObj) {
    commit("SET_CHARITY_SUCCESS", { get: false });
    commit("SET_IS_LOADING", { get: true });
    try {
      const response = await CharityService.getAllCharities(queryParamsObj);
      commit("SET_CHARITY_DATA", response);
      commit("SET_CHARITY_SUCCESS", { get: true });
    } catch (e) {
      commit("SET_CHARITY_ERROR", { get: e });
    } finally {
      commit("SET_IS_LOADING", { get: false });
    }
  },

  async deleteCharity({ commit }, id) {
    commit("SET_CHARITY_SUCCESS", { delete: false });
    commit("SET_IS_LOADING", { delete: false });
    try {
      await CharityService.deleteCharity(id);
      commit("SET_CHARITY_SUCCESS", { delete: true });
    } catch (e) {
      commit("SET_CHARITY_ERROR", { delete: e });
    } finally {
      commit("SET_IS_LOADING", { delete: false });
    }
  },

  resetCharityList({ commit }) {
    commit("RESET_CHARITY_LIST");
  },

  resetSuccess({ commit }) {
    commit("SET_CHARITY_SUCCESS", {
      get: null,
      store: null,
      update: null,
      delete: null,
    });
  },

  resetError({ commit }) {
    commit("SET_CHARITY_ERROR", {
      get: null,
      store: null,
      update: null,
      delete: null,
    });
  },
};

const mutations = {
  SET_CHARITY_DATA: (state, data) => (state.data = data),
  SET_CHARITY_SUCCESS: (state, success) =>
    (state.success = { ...state.success, ...success }),
  RESET_CHARITY_LIST: (state) => (state.data = []),
  SET_CHARITY_ERROR: (state, error) =>
    (state.error = { ...state.error, ...error }),
  SET_IS_LOADING: (state, isLoading) =>
    (state.isLoading = { ...state.isLoading, ...isLoading }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
