<script>
import Overlay from "../layout/Overlay.vue";
import { mapGetters } from "vuex";

export default {
  name: "Modal",
  components: {
    Overlay,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "bg-blue-default",
    },
    overlayClasses: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      showError: "ui/showError",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<template>
  <Overlay
    :showClose="showClose"
    @onClose="close"
    v-if="!showError"
    :classes="`${overlayClasses} overflow-hidden`"
  >
    <div
      :class="`${bgColor} ${classes} w-screen min-h-screen lg:min-h-0 lg:w-auto lg:h-auto rounded-md shadow-md px-12 py-12 lg:px-12`"
    >
      <slot></slot>
    </div>
  </Overlay>
</template>
