import { ProgramSerializer } from "../serializer/ProgramSerializer";
import axios from "../utils/interceptor";
import { setQueryParams } from "../utils";

export default {
  async getAllPrograms(filters) {
    const addedQuery = setQueryParams(filters);
    const response = await axios.get(`/programs?${addedQuery}`);
    return response.data
      .map((item) => {
        return ProgramSerializer.formatGetRequest(item);
      })
      .reverse();
  },
  async createProgram(payload) {
    return axios.post("/programs", payload);
  },
  async updateProgram(id, payload) {
    return axios.put(`/programs/${id}`, payload);
  },
  async getProgram(id) {
    const response = await axios.get(`/programs/${id}`);
    return ProgramSerializer.formatGetRequest(response.data);
  },
  async deleteProgram(id) {
    return axios.delete(`/programs/${id}`);
  },
  async terminateProgram(id, endDate) {
    const payload = ProgramSerializer.formatTerminateRequest(endDate);

    return axios.post(`/programs/${id}/terminate`, payload);
  },

  async addParticipants(programId, donors) {
    const payload = donors.map((item) => item.id);
    return axios.post(`/programs/${programId}/add-participants`, {
      employees: payload,
    });
  },

  async getAllParticipants(programId) {
    const response = await axios.get(`/programs/${programId}/get-participants`);
    return ProgramSerializer.formatGetParticipantsRequest(response.data);
  },

  async sendReminder(userId, programId) {
    const payload = ProgramSerializer.formatReminderRequest(userId, programId);
    return axios.post(`/programs/${programId}/send-reminder`, payload);
  },

  async downloadDonationsByProjects(id, role) {
    await axios("/csrf-cookie");
    const addedQuery = setQueryParams({ role });
    const response = await axios.get(
      `/donations/program/${id}/download-donations-charity?${addedQuery}`
    );
    return response.data;
  },

  async downloadDonationAmountByEmployee(id) {
    const response = await axios.get(
      `/donations/program/${id}/download-employee-amount-list`
    );
    return response.data;
  },

  // TODO - REVISE
  // async downloadDonations(id, role) {
  //
  //   const addedQuery = setQueryParams({ role });
  //   const response = await axios.get(
  //     `/donations/program/${id}/download-donations-role?${addedQuery}`
  //   );
  //   return response.data;
  // },
};
