<template>
  <component
    :is="iconComponent"
    role="img"
    class="inline-block fill-current"
    :iconHeight="iconHeight"
    :iconWidth="iconWidth"
  />
</template>

<script>
import IconEmployees from "./icons/iconEmployees.vue";
import IconProgram from "./icons/IconProgram.vue";
import IconProfile from "./icons/IconProfile.vue";
import IconHome from "./icons/IconHome.vue";
import IconDataExport from "./icons/IconDataExport.vue";
import IconCharities from "./icons/IconCharities.vue";

const icons = {
  employees: IconEmployees,
  program: IconProgram,
  profile: IconProfile,
  dashboard: IconHome,
  data: IconDataExport,
  charities: IconCharities,
};

export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },
    iconHeight: {
      type: String,
      required: false,
    },
    iconWidth: {
      type: String,
      required: false,
    },
  },
  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
};
</script>
