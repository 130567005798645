<script>
export default {
  name: "TitleWithSub",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
};
</script>
<template>
  <div class="flex flex-col flex-wrap mb-8">
    <div v-if="subTitle" class="uppercase font-bold">{{ subTitle }}</div>
    <h1>{{ title }}</h1>
  </div>
</template>

<style scoped></style>
