<script>
import AlertBox from "@/components/design/AlertBox.vue";
import EditDetails from "@/components/EditDetails.vue";
// UTILS
import { EMAIL_REGEX } from "@/utils";
import Error from "@/utils/Error";

export default {
  props: {
    formType: {
      type: String,
    },
  },
  name: "LoginForm",
  components: {
    EditDetails,
    AlertBox,
  },
  data() {
    return {
      showVerified:
        this.$route.query &&
        this.$route.query.verified &&
        this.$route.query.verified === "1",
      form: {
        email: "",
        password: "",
      },
      isCompany: false,
      error: [],
      fields: [
        {
          id: "email",
          label: "Email",
          type: "text",
        },
        {
          id: "password",
          label: "Password",
          type: "password",
        },
      ],
    };
  },
  methods: {
    validateInput() {
      if (!EMAIL_REGEX.test(this.form.email)) {
        this.error = [
          ...this.error,
          new Error("email", "Please provide a valid email"),
        ];
      }
      if (this.form.password.length < 4) {
        this.error = [
          ...this.error,
          new Error("password", "Please provide a valid password"),
        ];
      }
      if (this.error.length > 0) return false;
      return true;
    },
    submitLogin() {
      this.error = [];
      if (this.validateInput())
        this.$emit("login", { ...this.form, isCompany: this.isCompany });
    },
  },
};
</script>
<template>
  <div class="h-full content-wrapper-x max-width flex flex-col">
    <AlertBox class="mb-8" type="success" v-if="showVerified" :showIcon="false">
      <div>{{ $t("login.company.verifiedInfo") }}</div>
    </AlertBox>
    <div class="w-full lg:w-1/2"></div>
    <EditDetails
      :isLoading="isLoading"
      :btn="{
        show: {
          submit: true,
          cancel: false,
        },
      }"
      inputClasses="bg-white"
      :gridCols="1"
      titleBtn="Login"
      :fields="fields"
      :form="form"
      :errorProp="error"
      @update="(values) => (this.form = values)"
      @submit="submitLogin"
    >
      <div>
        <input
          class="inline-block"
          type="checkbox"
          id="checkbox"
          v-model="isCompany"
        />
        <label class="inline-block ml-4" for="checkbox"
          >Sign in as company</label
        >
      </div>
      <template v-slot:after-form>
        <div class="mt-4">
          <span>{{ $t("login.signup") }} </span>
          <router-link to="/signup">
            {{ $t("login.signupLink") }}
          </router-link>
        </div>
      </template>
    </EditDetails>
    <div class="my-8">
      <div class="my-2">{{ $t("login.forgotPassword.title") }}</div>
      <router-link to="/forgot-password"> Click here > </router-link>
    </div>
  </div>
</template>
