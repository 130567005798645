import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/app.scss";
import axios from "axios";
import i18n from "./i18n";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.myonepercent.org/api"
    : "http://localhost:8080/api";
axios.defaults.headers.common = {
  Accept: "application/json",
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(i18n)
  .mount("#app");
