<script>
import BasicLayout from "@/components/layout/BasicLayout";
import Button from "@/components/design/Button";
import CharityCreate from "@/components/company/charity/CharityCreate.vue";
import CharityIndex from "@/components/company/charity/CharityIndex.vue";
//THRID PARTY
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ManageCharities",
  components: {
    BasicLayout,
    Button,
    CharityCreate,
    CharityIndex,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      selectedCharity: null,
      formType: "show",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      charities: "charity/data",
      isLoading: "charity/isLoading",
      apiCallSuccess: "charity/success",
    }),
    changeableCharities() {
      return this.charities && this.charities.length > 0
        ? this.programs.filter((item) => item.customCreated)
        : [];
    },
  },
  watch: {
    apiCallSuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.store && newValue.store) {
          this.showForm("show");
          this.triggerToast("success", null, "Charity created!");
          this.getAllCharities();
        }
        if (!oldValue.update && newValue.update) {
          this.showForm("show");
          this.triggerToast("success", null, "Charity updated!");
          this.getAllCharities();
        }
        if (!oldValue.delete && newValue.delete) {
          this.triggerToast("success", null, "Charity deleted!");
          this.getAllCharities();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getCompany: "company/getCompany",
      getAllCharities: "charity/getAllCharities",
      createCharity: "charity/createCharity",
      updateCharity: "charity/updateCharity",
      deleteCharity: "charity/deleteCharity",
    }),
    closedForm() {
      this.showForm("show");
    },
    showForm(type, charity = null) {
      if (charity) {
        this.selectedCharity = charity;
      } else {
        this.selectedCharity = null;
      }
      this.formType = type;
    },
  },
  mounted() {
    if (this.user && !this.charities) {
      this.getAllCharities();
    }
  },
};
</script>

<template>
  <BasicLayout :isLoading="isLoading.get" :title="$t('companyCharities.title')">
    <div>
      <div class="flex flex-col gap-12">
        <div class="mb-4">{{ $t("companyCharities.subTitle") }}</div>
        <div className="w-44">
          <Button @click.prevent="showForm('add', null)">{{
            $t("companyCharities.btn")
          }}</Button>
        </div>
        <CharityIndex
          :charities="charities.data"
          v-if="charities"
          @edit="(charity) => showForm('edit', charity)"
          @delete="(id) => deleteCharity(id)"
        />
        <CharityCreate
          @closed="closedForm"
          v-if="formType !== 'show'"
          :charity="selectedCharity"
        />
      </div>
    </div>
  </BasicLayout>
</template>
