<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    viewBox="0 0 52 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M56 24H40V0H16V24H0L28 52L56 24ZM0 60V68H56V60H0Z" />
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "IconDataExport",
  props: {
    iconHeight: {
      type: String,
      default: "48",
    },
    iconWidth: {
      type: String,
      default: "60",
    },
  },
};
</script>
