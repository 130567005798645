<script>
// VUEX
import { mapGetters, mapActions } from "vuex";
//COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import EmployeeIndex from "@/components/company/employee/EmployeeIndex.vue";
import EmployeeCreate from "@/components/company/employee/EmployeeCreate.vue";
import EmployeeUpload from "@/components/company/employee/EmployeeUpload.vue";
import Button from "@/components/design/Button.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";

export default {
  name: "Employees",
  components: {
    BasicLayout,
    Button,
    EmployeeIndex,
    EmployeeCreate,
    EmployeeUpload,
    LoadingSpinner,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      isLoading: true,
      formType: "show",
      selectedEmployee: null,
      icons: {
        upload: require("@/assets/icons/bulk-upload.svg"),
      },
    };
  },
  watch: {
    apiCallSuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.store && newValue.store) {
          this.triggerToast("success", null, "Employee added.");
          this.triggerGetEmployees();
          this.closeForm();
        } else if (!oldValue.delete && newValue.delete) {
          this.triggerToast("success", null, "Employee deleted.");
          this.triggerGetEmployees();
          this.closeForm();
        } else if (!oldValue.update && newValue.update) {
          this.triggerToast("success", null, "Employee updated.");
          this.triggerGetEmployees();
          this.closeForm();
        }
      },
      deep: true,
    },
    user(newValue) {
      if (newValue) {
        if (!this.comapny) this.getCompany(this.user.companyDetails.id);
        if (!this.employees) this.triggerGetEmployees();
        this.isLoading = false;
      }
    },
    employees(newValue) {
      if (newValue !== null && this.isLoading && this.company) {
        // if (!this.company.settings.isSet) this.formType = "settings";
        this.isLoading = false;
      }
    },
    company(newValue) {
      if (newValue && this.isLoading && this.employees && this.company) {
        // if (!newValue.settings.isSet) this.formType = "settings";
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/data",
      saveEmployeeError: "employee/error",
      apiCallSuccess: "employee/success",
      user: "user/data",
      company: "company/data",
      employeeIsLoading: "employee/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      getAllEmployees: "employee/getAllEmployees",
      getCompany: "company/getCompany",
      deleteEmployee: "employee/deleteEmployee",
    }),
    editEmployee(id) {
      this.selectedEmployee = this.employees.filter(
        (employee) => employee.id === id
      )[0];
      this.changeFormType("edit");
    },
    triggerGetEmployees() {
      this.getAllEmployees({
        id: this.user.companyDetails.id,
        showTrashed: true,
      });
    },
    async triggerDeleteEmployee(id) {
      await this.deleteEmployee(id);
      this.triggerGetEmployees();
    },
    changeFormType(type, refetch = false) {
      this.formType = type;
      if (refetch) {
        this.triggerGetEmployees();
      }
    },
    closeForm() {
      this.changeFormType("show");
      this.selectedEmployee = null;
    },
  },
  /* TODO -> MAKE A PROMISE ALL HERE */
  mounted() {
    if (this.company && this.employees && this.user) {
      this.isLoading = false;
    } else if (!this.company && this.user) {
      this.getCompany(this.user.companyDetails.id);
    }
    if (this.user) {
      this.triggerGetEmployees();
    }
  },
};
</script>
<template>
  <BasicLayout
    :isLoading="isLoading"
    :title="formType !== 'settings' ? $t('employees.title') : undefined"
  >
    <div class="h-full overflow-hidden">
      <LoadingSpinner
        size="full"
        v-if="
          employeeIsLoading.store ||
          employeeIsLoading.update ||
          employeeIsLoading.delete
        "
      />
      <!-- <div class="flex flex-col gap-4 mb-8" v-if="formType === 'settings'">
          <TitleWithSub
            :title="$t('settings.title')"
            :subTitle="$t('settings.subTitle')"
          />
          <div class="md:w-1/2">
            <Settings :showCancel="false" @saved="changeFormType('success')" />
          </div>
        </div> -->
      <div v-else>
        <div
          class="flex flex-col gap-4 mb-8"
          v-if="employees && employees.length === 0"
        >
          {{ $t("employees.empty") }}
        </div>
        <div class="flex gap-8">
          <Button @click="changeFormType('create')">
            {{ $t("employees.btnAdd") }}
          </Button>
          <Button @click="changeFormType('upload')">
            <div class="flex items-center">
              <img :src="icons.upload" class="h-5 px-2 inline-block" />
              <div>{{ $t("employees.btnUpload") }}</div>
            </div>
          </Button>
          <EmployeeUpload
            v-if="formType === 'upload'"
            @close="() => changeFormType('show', true)"
          />
        </div>
        <div v-if="formType === 'create' || formType === 'edit'">
          <EmployeeCreate
            :employee="selectedEmployee"
            :companyId="user.companyDetails.id"
            @closed="closeForm"
            @cancel="closeForm"
          />
        </div>
        <div class="my-12 w-full" v-if="employees && employees.length > 0">
          <EmployeeIndex
            @edit="(id) => editEmployee(id)"
            @delete="(id) => triggerDeleteEmployee(id)"
          />
        </div>
        <div
          v-else-if="employeeIsLoading.get"
          class="flex justify-start my-16 mx-8"
        >
          <LoadingSpinner size="xl" />
        </div>
      </div>
    </div>
  </BasicLayout>
</template>
