<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ErrorComponent",
  props: {
    hasSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        logo: require("../../assets/logo/logo_mop-small.svg"),
      },
      error: null,
      requestType: null,
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/error",
      program: "program/error",
      donor: "donor/error",
      employee: "employee/error",
      company: "company/error",
      charity: "charity/error",
      // showError: "ui/showError",
    }),
  },
  methods: {
    ...mapActions({
      programReset: "program/resetError",
      companyReset: "company/resetError",
      donorReset: "donor/resetError",
      employeeReset: "employee/resetError",
      charityReset: "employee/resetError",
      // setShowError: "ui/setShowError",
      userReset: "user/resetError",
    }),
    /* message is object with title and text in case of success. In case of error it will be build */
    triggerErrorSwal() {
      let toast = null;
      toast = this.$swal.mixin({
        toast: true,
        customClass: {
          popup: "colored-toast",
        },
        position: "top-end",
        iconColor: "white",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      toast.fire({
        icon: "error",
        title: this.error,
      });
      this.resetError();
    },
    setError(model) {
      Object.entries(this[model])
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => {
          this.model = model;
          this.requestType = key;
          this.error = value.message;
          this.triggerErrorSwal();
          return;
        });
    },
    resetError() {
      switch (this.model) {
        case "user":
          this.userReset();
          break;
        case "program":
          this.programReset();
          break;
        case "donor":
          this.donorReset();
          break;
        case "company":
          this.companyReset();
          break;
        case "charity":
          this.charityReset();
          break;
        case "employee":
          this.employeeReset();
          break;
        default:
          console.log("No reset for model: ", this.model);
      }
    },
  },
  watch: {
    program: {
      handler(newValue) {
        if (newValue) {
          this.setError("program");
        }
      },
      deep: true,
    },
    employee: {
      handler(newValue) {
        if (newValue) {
          this.setError("employee");
        }
      },
      deep: true,
    },
    company: {
      handler(newValue) {
        if (newValue) {
          this.setError("company");
        }
      },
      deep: true,
    },
    donor: {
      handler(newValue) {
        if (newValue) {
          this.setError("donor");
        }
      },
      deep: true,
    },
    charity: {
      handler(newValue) {
        if (newValue) {
          this.setError("charity");
        }
      },
      deep: true,
    },
    user: {
      handler(newValue) {
        if (newValue) {
          this.setError("user");
        }
      },
      deep: true,
    },
  },
};
</script>
