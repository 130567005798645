import { getErrorMessage } from "./getErrorMessage";
import { getProgramUrl } from "./router";
export const EMAIL_REGEX =
  /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

export const MOBILE_REGEX = /^(?=.*\d)[\d ]+$/;

export const isLoggedInLocalStorage = () => {
  const now = new Date();
  return (
    localStorage.getItem("isLoggedIn") &&
    JSON.parse(localStorage.getItem("isLoggedIn")).value &&
    JSON.parse(localStorage.getItem("isLoggedIn")).expiry >
      now.getTime() - 100000000
  );
};

export const loginLocalStorage = () => {
  const now = new Date();
  const item = {
    value: true,
    expiry: now.getTime() + 100000000,
  };
  localStorage.setItem("isLoggedIn", JSON.stringify(item));
};

export const logoutLocalStorage = () => {
  localStorage.removeItem("isLoggedIn");
};

export const getToday = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
};

export const hasNumber = (string) => {
  return /[0-9]+/.test(string);
};

export const debounce = (func) => {
  let timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 300, event);
  };
};

export const getScreenSize = (value) => {
  if (value < 641) return "sm";
  if (value > 640 && value < 1023) return "md";
  if (value > 1023) return "lg";
};

/* Expects an object based on which the query string will be build */
export const setQueryParams = (queryParamObject) => {
  let esc = encodeURIComponent;
  let query = Object.keys(queryParamObject)
    .map((k) => esc(k) + "=" + esc(queryParamObject[k]))
    .join("&");
  return query;
};

const transformJsonToCsv = (json) => {
  const fields = Object.keys(json[0]);
  const replacer = function (key, value) {
    return value === null ? "" : value;
  };
  let csv = json.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(","));
  csv = csv.join("\r\n");
  return csv;
};

export const downloadCsv = async (json, callback, name) => {
  const csv = transformJsonToCsv(json);
  const downloadLink = document.createElement("a");
  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${name}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  setTimeout(() => {
    callback();
  }, 2000);
};

export { getProgramUrl, getErrorMessage };
