import { EmployeeSerializer } from "../serializer/EmployeeSerializer";
import axios from "../utils/interceptor";

export default {
  async createEmployee(payload) {
    return axios.post("/employees", payload);
  },
  async massCreateEmployees(payload) {
    return axios.post("/employees/mass-upload", payload);
  },
  async updateEmployee(id, payload) {
    return axios.put(`/employees/${id}`, payload);
  },
  async getEmployee(id) {
    return axios.get(`/employees/${id}`);
  },
  async getAllEmployees(id) {
    const { data } = await axios.get(`/employees?company_id=${id}`);
    if (data)
      return data.map((item) => EmployeeSerializer.formatGetRequest(item));
    return [];
  },
  async deleteEmployee(id) {
    return axios.delete(`/employees/${id}`);
  },
};
