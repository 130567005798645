<script>
import { mapGetters, mapActions } from "vuex";
//COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import Button from "@/components/design/Button.vue";
import ProgramIndex from "@/components/company/program/ProgramIndex.vue";
import ProgramTerminate from "@/components/company/program/ProgramTerminate.vue";
import MOPDefaultProgramCreate from "@/components/company/program/MOPDefaultProgramCreate.vue";

export default {
  name: "Programs",
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  components: {
    BasicLayout,
    ProgramIndex,
    ProgramTerminate,
    MOPDefaultProgramCreate,
    Button,
  },
  data() {
    return {
      // tabs: [],
      // activeTab: "all",
      isLoading: true,
      formType: "show",
      selectedProgram: null,
      notPermitted: false,
    };
  },
  watch: {
    apiCallSuccessDonor: {
      handler(newValue, oldValue) {
        if (!oldValue.store && newValue.store) {
          this.triggerToast(
            "success",
            null,
            "Employee added and invitation email sent."
          );
        } else if (!oldValue.delete && newValue.delete) {
          this.triggerToast("success", null, "Employee removed from program.");
        }
      },
      deep: true,
    },
    user: {
      handler(newValue) {
        if (newValue && !this.programs) {
          this.getAllPrograms({
            company_id: this.user.companyDetails.id,
          });
        }
      },
      deep: true,
    },
    apiCallSuccessProgram: {
      handler(newValue, oldValue) {
        if (!oldValue.delete && newValue.delete) {
          this.triggerToast("success", null, "Program deleted.", () => {
            this.resetSuccessAndClose();
          });
        } else if (!oldValue.store && newValue.store) {
          this.triggerToast(
            "success",
            null,
            "Program Created. Add employees!",
            () => this.resetSuccessAndClose()
          );
        }
      },
      deep: true,
    },
    programs(newValue) {
      if (newValue && this.isLoading) {
        this.isLoading = false;
      }
      if (newValue && newValue.length < 1) {
        console.log("test");
        this.changeFormType("firstProgram");
      }
    },
  },
  computed: {
    ...mapGetters({
      programs: "program/data",
      errorProgram: "program/error",
      user: "user/data",
      apiCallSuccessProgram: "program/success",
      apiCallSuccessDonor: "donor/success",
      programLoading: "program/isLoading",
    }),
    hasPrograms() {
      if (!this.isLoading) {
        return this.programs.length > 0;
      }
      return false;
    },
    hasActivePrograms() {
      if (!this.isLoading && this.programs) {
        return (
          this.programs.filter((program) => program.status === "terminated")
            .length !== this.programs.length
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getAllPrograms: "program/getAllPrograms",
      deleteProgram: "program/deleteProgram",
      resetSuccess: "program/resetSuccess",
    }),
    resetSuccessAndClose() {
      this.resetSuccess();
      this.programSaved();
    },
    changeFormType(type) {
      this.selectedProgram = null;
      this.formType = type;
    },
    async programSaved() {
      this.getAllPrograms({
        company_id: this.user.companyDetails.id,
      });
      this.changeFormType("show");
    },
    async triggerTerminateProgram(program) {
      this.selectedProgram = program;
      this.formType = "terminate";
    },
    onProgramTermination() {
      this.triggerToast("success", null, "Program Termination Date updated");
      this.programSaved();
    },
    async triggerDeleteProgram(program) {
      this.$swal({
        title: "You are about to delete the program!",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete Program!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteProgram(program.id);
        }
      });
    },
  },
  mounted() {
    if (this.user && this.user.companyDetails) {
      this.getAllPrograms({
        company_id: this.user.companyDetails.id,
      });
      if (this.programs && this.programs.length < 1) {
        this.changeFormType("firstProgram");
      }
    }
  },
};
</script>
<template>
  <BasicLayout :isLoading="isLoading" :title="$t('programs.title')">
    <div class="h-full">
      <div v-if="formType === 'firstProgram'">
        <MOPDefaultProgramCreate
          :isFirstProgram="true"
          @close="changeFormType('show')"
        >
          <div class="flex flex-col gap-4 mb-8">
            {{ $t("programs.empty") }}
          </div>
        </MOPDefaultProgramCreate>
      </div>
      <div v-else>
        <div>
          <Button @click="changeFormType('create')">
            {{ $t("programs.btnAdd") }}
          </Button>
        </div>
        <MOPDefaultProgramCreate
          :isFirstProgram="false"
          v-if="formType === 'create'"
          @close="changeFormType('show')"
        />
        <div v-if="formType === 'terminate'">
          <ProgramTerminate
            :program="selectedProgram"
            @cancel="changeFormType('show')"
            @saved="onProgramTermination"
          />
        </div>
        <div class="my-12 w-full" v-if="programs">
          <ProgramIndex
            :triggerToast="triggerToast"
            @terminate="(program) => triggerTerminateProgram(program)"
            @delete="(program) => triggerDeleteProgram(program)"
          />
        </div>
      </div>
    </div>
  </BasicLayout>
</template>
