<script>
import Button from "./design/Button.vue";
import LoadingSpinner from "./design/LoadingSpinner.vue";
import Modal from "@/components/design/Modal.vue";

import { debounce } from "../utils";
//VUE
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditDetails",
  components: {
    Button,
    LoadingSpinner,
    Modal,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    wrapperClasses: {
      type: String,
      default: "w-full",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      required: true,
    },
    errorProp: {
      type: Array,
    },
    disabled: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
    },
    titleBtn: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      defaul: false,
    },
    btn: {
      type: Object,
      default: () => {
        return {
          show: {
            submit: true,
            cancel: true,
          },
          disabled: {
            submit: false,
            cancel: false,
          },
        };
      },
    },
    gridCols: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      icons: {
        info: require("../assets/icons/info-icon.svg"),
      },
      originalForm: {},
      error: [],
      showHint: "",
    };
  },
  watch: {
    errorProp() {
      this.error = this.errorProp;
    },
  },
  computed: {
    ...mapGetters({
      screenSize: "ui/screenSize",
    }),
    btnProps() {
      return {
        show: {
          submit: true,
          cancel: true,
        },
        disabled: {
          submit: false,
          cancel: false,
        },
        ...this.btn,
      };
    },
  },

  methods: {
    ...mapActions({
      setScreenSize: "ui/setScreenSize",
    }),
    updateHint(index) {
      this.showHint = this.fields[index].hint;
    },
    removeHint() {
      this.showHint = "";
    },
    createOriginal() {
      this.originalForm = this.form;
    },
    /* Not working for checkboxes yet */
    updateValue(id, value) {
      this.$emit("update", {
        ...this.form,
        [id]: value,
      });
    },
    resetForm() {
      this.$emit("reset", this.originalForm);
    },
    hasError(id) {
      return this.error.filter((item) => item.id === id).length > 0;
    },
    resetError(id) {
      this.error = this.error.filter((item) => item.id !== id);
    },
    getErrorMessage(id) {
      const errorForId = this.error.filter((item) => item.id === id);
      if (errorForId.length > 0) {
        return errorForId[0].getMessage();
      }
      return null;
    },

    submit() {
      this.$emit("submit");
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
  },
  mounted() {
    window.addEventListener(
      "resize",
      debounce(() => {
        this.setScreenSize(window.innerWidth);
      })
    );
    this.error = this.errorProp;
    this.createOriginal();
  },
};
</script>
<template>
  <div v-if="fields && form" :class="`${wrapperClasses}`">
    <Modal
      bgColor="bg-orange-light"
      classes="flex flex-col h-screen justify-center items-center"
      v-if="showHint"
      @close="removeHint"
    >
      <div
        class="mb-4 bg-orange-light text-white-default text-xs lg:w-64 text-center"
      >
        {{ showHint }}
      </div>
      <Button @click.prevent="removeHint" size="sm">Got it</Button>
    </Modal>
    <h1 class="pb-4" v-if="title">{{ title }}</h1>
    <slot name="before-form"></slot>
    <form @submit.prevent="submit" class="flex flex-col">
      <div :class="[`grid lg:grid-cols-${gridCols} gap-6 my-form`]">
        <div
          :class="[
            `${gridCols > 1 ? 'mr-4' : ''}
            ${
              field.type === 'checkbox'
                ? 'flex-row-reverse gap-4 items-center justify-end'
                : 'flex-col'
            }
           lg:mr-5 flex w-full flex-grow-0 relative`,
          ]"
          v-for="(field, pos) in fields"
          :key="pos"
          v-show="!field.hide"
        >
          <label class="relative" :for="field.id"
            >{{ field.label
            }}<span
              @click.prevent="updateHint(pos)"
              class="ml-2 hidden md:inline-block cursor-pointer"
              v-if="field.hint"
              ><img :src="icons.info" class="h-4"
            /></span>
            <div
              @click.prevent="updateHint(pos)"
              class="ml-2 md:hidden inline"
              v-if="field.hint"
            >
              <img :src="icons.info" class="h-4" /></div
          ></label>
          <textarea
            :cols="40"
            :rows="5"
            v-if="field.type === 'textarea'"
            :class="[
              inputClasses ? inputClasses : 'bg-orange-light w-full',
              hasError(field.id) ? 'border border-red-500' : '',
              field.type === 'checkbox' ? 'w-auto' : 'w-full',
            ]"
            :type="field.type"
            :id="field.id"
            :value="form[`${field.id}`]"
            :disabled="disabled.includes(field.id) || isLoading"
            v-on:input="
              (event) => updateValue(field.id, event.target.value, field.type)
            "
          />
          <input
            v-else
            :class="[
              inputClasses ? inputClasses : 'bg-orange-light p-2',
              hasError(field.id) ? 'border border-red-500' : '',
              field.type === 'checkbox' ? 'w-auto' : 'w-full',
            ]"
            @focus="resetError(field.id)"
            :max="field.max ?? undefined"
            :min="field.min ?? undefined"
            :type="field.type"
            :id="field.id"
            :value="form[`${field.id}`]"
            :disabled="disabled.includes(field.id) || isLoading"
            v-on:input="
              (event) => updateValue(field.id, event.target.value, field.type)
            "
            :placeholder="field.label"
          />
          <div
            class="text-red-default flex text-xs md:absolute -bottom-4 md:top-20 p-2 w-full rounded-md"
            v-if="error.length > 0 && hasError(field.id)"
          >
            {{ getErrorMessage(field.id) }}
          </div>
        </div>
      </div>
      <slot></slot>
      <div
        :class="`${
          !btnProps.show.cancel ? 'justify-center lg:justify-start' : ''
        } flex flex-col gap-4 lg:flex-row w-full mt-8 `"
      >
        <Button
          :disabled="btnProps.disabled.submit"
          v-if="btnProps.show.submit"
          classes="w-full lg:w-1/2"
          @click.prevent="this.$emit('submit')"
        >
          <LoadingSpinner size="sm" color="white" v-if="isLoading" />
          <span v-else>{{ titleBtn || "Save" }}</span>
        </Button>
        <Button
          :disabled="btnProps.disabled.cancel"
          v-if="btnProps.show.cancel"
          :outlined="true"
          classes="w-full lg:ml-12 lg:w-1/2"
          @click.prevent="cancel"
          >{{ $t("common.btn.cancel") }}</Button
        >
      </div>
    </form>
    <slot name="after-form"></slot>
  </div>
</template>

<style scoped></style>
