import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Home from "@/views/Home.vue";
import Signup from "@/views/Signup.vue";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/company/Dashboard.vue";
import EmployeeDashboard from "@/views/employee/Dashboard.vue";
import AdminDashboard from "@/views/admin/Dashboard.vue";
import ManageCharities from "@/views/company/ManageCharities.vue";

import Programs from "@/views/company/Programs.vue";
import Logout from "@/views/Logout.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import CharityList from "@/views/charity/CharityList.vue";
import SelectCharityList from "@/views/employee/SelectCharityList.vue";
import SetPassword from "@/views/employee/SetPassword.vue";
import Profile from "@/views/company/Profile.vue";
import Employees from "@/views/company/Employees.vue";
import Data from "@/views/company/Data.vue";
import VerifyUser from "@/views/VerifyUser.vue";
import withToasts from "@/components/hoc/withToasts";
import { ROUTEPATHS } from "@/constants";
import { isLoggedInLocalStorage } from "@/utils";

// check to avoid user land on login: https://stackoverflow.com/questions/52653337/vuejs-redirect-from-login-register-to-home-if-already-loggedin-redirect-from

const routes = [
  {
    path: ROUTEPATHS.guest.signup,
    name: "Signup",
    component: withToasts(Signup),
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.guest.charities,
    name: "CharityList",
    component: CharityList,
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.guest.login,
    name: "Login",
    component: withToasts(Login),
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.guest.logout,
    name: "Logout",
    component: Logout,
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.guest.forgotPassword,
    name: "ForgotPassword",
    component: withToasts(ForgotPassword),
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.guest.resetPassword,
    name: "ResetPassword",
    component: withToasts(ResetPassword),
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.company.dashboard,
    name: "Dashboard",
    component: withToasts(Dashboard),
    meta: {
      roles: ["company"],
    },
  },
  {
    path: ROUTEPATHS.company.programs,
    name: "Programs",
    component: withToasts(Programs),
    meta: {
      roles: ["company"],
    },
  },
  {
    path: ROUTEPATHS.company.data,
    name: "Data",
    component: withToasts(Data),
    meta: {
      roles: ["company"],
    },
  },
  {
    path: ROUTEPATHS.company.employees,
    name: "Employees",
    component: withToasts(Employees),
    meta: {
      roles: ["company"],
    },
  },
  {
    path: ROUTEPATHS.company.profile,
    name: "Profile",
    component: withToasts(Profile),
    meta: {
      roles: ["company"],
    },
  },
  {
    path: ROUTEPATHS.company.charities,
    name: "ManageCharities",
    component: withToasts(ManageCharities),
    meta: {
      roles: ["company"],
    },
  },
  {
    path: ROUTEPATHS.guest.home,
    name: "Home",
    component: Home,
    meta: {
      roles: [],
    },
  },
  // {
  //   path: "/about-us",
  //   name: "AboutUs",
  //   component: Dashboard,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: ROUTEPATHS.employee.dashboard,
    name: "EmployeeDashboard",
    component: EmployeeDashboard,
    meta: {
      roles: ["employee"],
    },
  },
  {
    path: ROUTEPATHS.employee.charities,
    name: "SelectCharityList",
    component: withToasts(SelectCharityList),
    meta: {
      roles: ["employee"],
    },
  },
  {
    path: ROUTEPATHS.employee.setPassword,
    name: "SetPassword",
    component: withToasts(SetPassword),
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.guest.verifyUser,
    name: "VerifyUser",
    component: withToasts(VerifyUser),
    meta: {
      roles: [],
    },
  },
  {
    path: ROUTEPATHS.admin.dashboard,
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      roles: ["admin"],
    },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched[0].meta.roles.length > 0) {
    if (!isLoggedInLocalStorage()) {
      next({ path: "/login" });
    } else if (isLoggedInLocalStorage() && !store.state.user.data) {
      store.dispatch("user/getUser");
      next();
    } else if (
      (store.state.user.data &&
        !isLoggedInLocalStorage() &&
        to.matched[0].meta.roles.length > 0 &&
        !to.matched[0].meta.roles.includes(store.state.user.data.role)) ||
      !store.state.user.data.verified
    ) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
