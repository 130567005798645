<script>
export default {
  name: "StatusButton",
  props: {
    status: {
      type: String,
      default: "pending",
    },
    hasStarted: {
      type: Boolean,
      default: false,
    },
    hasEndDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        wait: require("@/assets/icons/wait.svg"),
        canceled: require("@/assets/icons/canceled.svg"),
        check: require("@/assets/icons/approve.svg"),
      },
    };
  },
  methods: {
    getStyles() {
      switch (this.status) {
        case "active":
          return {
            classes: "font-bold text-green-dark",
            icon: this.icons.check,
          };

        case "terminated":
          return {
            classes: "",
            icon: this.icons.canceled,
          };
        default:
          return {
            classes: "",
          };
      }
    },
  },
};
</script>
<template>
  <div
    :class="`${
      getStyles().classes
    } flex items-center justify-center rounded-md cursor-default text-center px-4`"
  >
    <div class="inline-block pr-2">{{ status }}</div>
    <img
      v-if="getStyles().icon"
      :src="getStyles().icon"
      class="h-5 flex items-center"
    />
  </div>
</template>
