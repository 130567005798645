export const DONORSTATUSES = {
  pending: "pending",
  invited: "invited",
  active: "active",
  terminated: "terminated",
};

export const PROGRAMSTATUS = {
  active: "active",
  planned: "planned",
  terminated: "terminated",
};

export const CREATIONTYPES = {
  default: "default",
  custom: "company",
};

export const CHARITYTYPES = {
  ngo: "ngo",
  nonNgo: "non_ngo",
};

export const DATES = {
  today: new Date().toISOString().split("T")[0],
};

export const ROLES = {
  employee: "employee",
  company: "company",
  admin: "admin",
};

export const ROUTEPATHS = {
  guest: {
    home: "/",
    login: "/login",
    signup: "/signup",
    logout: "/logout",
    charities: "/charities",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password/:token",
    verifyUser: "/verify-email",
  },
  company: {
    dashboard: "/company/dashboard",
    profile: "/company/profile",
    programs: "/company/programs",
    employees: "/company/employees",
    data: "/company/data",
    charities: "/company/charities",
  },
  employee: {
    charities: "/employee/programs/:programId/charities",
    setPassword: "/employee/set-password/:token",
    dashboard: "/employee/dashboard",
  },
  admin: {
    dashboard: "/admin/dashboard",
    projects: "/admin/projects",
  },
};

export const DONATIONSTATUS = {
  pending: "pending",
  active: "active",
  terminated: "terminated",
};
