<script>
// COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import CharityCardList from "@/components/charity/CharityCardList.vue";

/* TODO - adapt according new charitycardlist requirements */
export default {
  name: "CharityList",
  components: {
    CharityCardList,
    BasicLayout,
  },
};
</script>
<template>
  <BasicLayout
    :hideSidebar="true"
    :isLoading="isLoading"
    :title="$t('charities.list.title')"
  >
    <CharityCardList />
  </BasicLayout>
</template>
<style lang="css">
li {
  @apply mx-2;
}

.Page-active {
  @apply w-full p-2;
}
</style>
