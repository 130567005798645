<script>
//COMPONENTS
import BasicLayout from "@/components/layout/BasicLayout.vue";
import TitleWithSub from "@/components/layout/TitleWithSub.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Logout",
  components: {
    BasicLayout,
    TitleWithSub,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      logout: "user/logout",
    }),
  },
  watch: {
    userData(newValue) {
      if (!newValue) {
        window.location.href = "/login";
      }
    },
  },
  computed: {
    ...mapGetters({
      userData: "user/data",
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  mounted() {
    if (this.userData) {
      setTimeout(() => {
        this.isLoading = true;
        this.logout();
      }, 500);
    } else window.location.href = "/login";
  },
};
</script>

<template>
  <BasicLayout
    :hideSidebar="true"
    contentWrapperProps="bg-orange-light"
    :isLoading="isLoading"
  >
    <div class="h-full content-wrapper-x max-width flex flex-col">
      <TitleWithSub :title="$t('logout.title')" />
    </div>
  </BasicLayout>
</template>
