<script>
import { mapGetters } from "vuex";
//COMPONENTS
import DonorIndex from "./DonorIndex.vue";
import DonorAdd from "./DonorAdd.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";

export default {
  name: "DonorDetails",
  components: {
    DonorAdd,
    DonorIndex,
    LoadingSpinner,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
    programId: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        addParticipant: require("@/assets/icons/add-participant.svg"),
      },
      formType: "show",
    };
  },
  computed: {
    ...mapGetters({
      participants: "program/participants",
      loading: "program/isLoading",
    }),
  },
  methods: {
    changeFormType(type) {
      this.formType = type;
    },
    triggerReminderSendSuccess() {
      this.triggerToast("success", "Reminder sent successfully");
    },
  },
};
</script>
<template>
  <div class="mb-8">
    <h1 class="pb-6">{{ $t("programs.index.employees.title") }}</h1>
    <div>
      <LoadingSpinner v-if="loading.update || loading.get" size="xl" />
      <div v-else>
        <DonorAdd :programId="programId" @added="() => this.$emit('added')" />
        <div
          class="w-full"
          v-if="participants && participants.invitees.length > 0"
        >
          <DonorIndex
            :programId="programId"
            @reminderSent="() => this.$emit('reminderSent')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
