<script>
// THIRD PARTY
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

// COMPONENTS
import CharityCard from "@/components/charity/CharityCard.vue";

export default {
  name: "CharityCardList",
  props: {
    charities: {
      type: Object,
      required: true,
    },
    btn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // VPagination,
    CharityCard,
  },

  methods: {
    selectedCharity(charity) {
      this.$emit("charity-selected", charity);
    },
    async updatePage(page) {
      console.log("Getting new page", page);
      // this.isLoading = true;
      // this.filters.page = page;
      // const organisations = await OrganisationService.getAllOrganisations(
      //   this.filters
      // );
      // this.setOrganisations(organisations);
      // window.scrollTo(0, 0);
      // this.isLoading = false;
    },
    getBgColor(index) {
      if (index % 3 === 0) return "bg-orange-default";
      if (index % 3 === 1) return "bg-green-default";
      if (index % 3 === 2) return "bg-blue-default";
    },
  },
};
</script>
<template>
  <div class="w-full">
    <div class="my-4 ml-2 text-sm">
      You are on page
      <span class="font-bold">{{ charities.page ?? 1 }}</span>
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 w-full"
      v-if="charities.data"
    >
      <CharityCard
        :btn="btn"
        v-for="(charity, index) in charities.data"
        :key="charity.name"
        :charity="charity"
        @selected="selectedCharity"
        :bgColor="getBgColor(index)"
      />
    </div>
    <!-- <div
      v-if="updatePage && charities.data && charities.page"
      class="w-full my-4 flex-wrap"
    >
      <v-pagination
        v-model="charities.page"
        :pages="charities.totalPages"
        :range-size="2"
        activeColor="#FBEEE9"
        @update:modelValue="updatePage"
      />
    </div> -->
  </div>
</template>
<style lang="css">
li {
  @apply mx-2;
}

.Page-active {
  @apply w-full p-2;
}
</style>
