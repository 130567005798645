<script>
//VUEX
import { mapGetters, mapActions } from "vuex";
//THIRD PARTY
import {
  VueCsvToggleHeaders,
  VueCsvMap,
  VueCsvInput,
  VueCsvErrors,
  VueCsvImport,
} from "vue-csv-import";
//COMPONENTS
import Modal from "@/components/design/Modal.vue";
import Button from "@/components/design/Button.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
import { EmployeeSerializer } from "@/serializer/EmployeeSerializer";

export default {
  components: {
    Modal,
    Button,
    LoadingSpinner,
    VueCsvToggleHeaders,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors,
    VueCsvImport,
  },
  name: "EmployeeUpload",
  data() {
    return {
      csv: null,
      processedEmployees: 0,
      duplicates: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      employees: "employee/data",
      success: "employee/success",
      isLoading: "employee/isLoading",
    }),
  },
  watch: {
    csv(newValue) {
      if (newValue && newValue.length > 0) {
        this.duplicates = [];
        this.removeDuplicates();
      }
    },
    success(newValue) {
      if (newValue.store) {
        this.$swal(
          "Uploaded!",
          "All employees have been added and received an email.",
          "success"
        );
        this.closeUploadModal();
      }
    },
  },
  methods: {
    ...mapActions({
      massCreateEmployees: "employee/massCreateEmployees",
      getAllEmployees: "employee/getAllEmployees",
    }),
    removeDuplicates() {
      const csv = this.csv;
      const duplicates = [];
      for (let i = 0, len = this.employees.length; i < len; i++) {
        for (let j = 0, len2 = csv.length; j < len2; j++) {
          if (this.employees[i].email === csv[j].email) {
            duplicates.push(csv[j]);
            csv.splice(j, 1);
            len2 = csv.length;
          }
        }
      }
      this.duplicates = duplicates;
      return csv;
    },

    closeUploadModal() {
      this.$emit("close");
    },

    formatData() {
      return this.csv.map((item) => {
        return EmployeeSerializer.formatEmployee({
          email: item.email,
          employeeId: item.employeeId,
        });
      });
    },
    addUploadedEmployees() {
      this.massCreateEmployees({
        data: this.formatData(),
        company_id: this.user.companyDetails.id,
      });
    },
  },
};
</script>
<template>
  <Modal @close="() => this.$emit('close')">
    <div class="lg:w-96">
      <div class="flex flex-col">
        <vue-csv-import
          v-model="csv"
          :fields="{
            employeeId: { required: false, label: 'Employee Id' },
            email: { required: true, label: 'Email' },
          }"
        >
          <div class="flex flex-col gap-4">
            <vue-csv-toggle-headers></vue-csv-toggle-headers>
            <vue-csv-errors></vue-csv-errors>
            <vue-csv-input></vue-csv-input>
            <div class="my-8">
              <vue-csv-map></vue-csv-map>
            </div>
          </div>
        </vue-csv-import>
        <div v-if="csv">
          <h3>{{ $t("employees.upload.acceptedTitle") }}</h3>
          <div class="flex" v-for="employee in csv" :key="employee.name">
            <div>{{ employee.email }}</div>
          </div>
        </div>
        <div v-if="duplicates.length > 0" class="mt-4 text-red-default">
          <h3>{{ $t("employees.upload.duplicatesTitle") }}</h3>
          <div class="flex" v-for="employee in duplicates" :key="employee.name">
            <div>{{ employee.email }}</div>
          </div>
        </div>
        <div class="flex justify-between">
          <Button
            classes="mt-8"
            @click.prevent="addUploadedEmployees"
            :disabled="!csv || csv.length < 1 || isLoading.store"
          >
            <LoadingSpinner size="sm" color="white" v-if="isLoading.store" />
            <span v-else>{{ $t("common.btn.submit") }}</span>
          </Button>
          <Button
            :outlined="true"
            classes="mt-8"
            @click.prevent="this.$emit('close')"
          >
            <span>{{ $t("common.btn.cancel") }}</span>
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>
