<script>
//VUEX
import { mapGetters, mapActions } from "vuex";
//THIRD PARTY
import VueNextSelect from "vue-next-select";
//COMPONENTS
import AlertBox from "@/components/design/AlertBox.vue";
import Button from "@/components/design/Button.vue";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
// UTILS
import Error from "@/utils/Error";

export default {
  name: "Settings",
  components: {
    AlertBox,
    LoadingSpinner,
    Button,
    VueNextSelect,
  },
  props: {
    wrapperClasses: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: [],
      id: null,
      isLoading: false,
      form: {
        hasIntegration: false,
        integrationType: "Personio",
      },
      fields: [
        {
          id: "integrationType",
          label: "Tool Name",
          type: "text",
          hide: true,
        },
      ],
      disabled: [],
    };
  },
  watch: {
    "form.hasIntegration": function (newVal) {
      if (newVal) this.fields[0].hide = false;
      else this.fields[0].hide = true;
    },
    company(newValue) {
      if (newValue !== null) this.prePopulateForm();
    },
    updateSuccess(newValue, oldValue) {
      if (newValue.update && !oldValue.update) this.$emit("saved", "edit");
    },
    isLoadingCompany(newValue) {
      if (!newValue.update && !newValue.get) {
        this.isLoading = false;
        this.$emit("saved");
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoadingCompany: "company/isLoading",
      company: "company/data",
      updateSuccess: "company/success",
    }),
    settings() {
      return this.company ? this.company.settings : null;
    },
    settingType() {
      if (this.form.hasIntegration)
        return `Has 3rd Party integration with: ${this.form.integrationType}`;
      else {
        return "Add employees manually or with bulk upload.";
      }
    },
    selectableIntegration() {
      return ["Personio", "HR Works"];
    },
  },
  methods: {
    ...mapActions({
      updateSettings: "company/updateCompany",
    }),
    prePopulateForm() {
      this.id = this.company.id;
      this.form = {
        hasIntegration: this.settings.hasIntegration,
        integrationType: this.settings.integrationType,
      };
    },
    updateValue(id, value) {
      if (id === "hasIntegration") {
        this.form[id] = value.checked;
        if (value.checked) this.form.integrationType = "";
      } else {
        this.form[id] = value.value;
      }
    },
    validateInput() {
      if (
        this.form.hasIntegration === true &&
        (!this.form.integrationType || this.form.integrationType.length < 3)
      ) {
        this.error = [
          ...this.error,
          new Error("integrationType", "Please provide at least 3 characters"),
        ];
        return false;
      }
      return true;
    },
    saveSettings() {
      this.error = [];
      this.isLoading = true;
      if (this.validateInput()) {
        this.$swal({
          title: "Are you sure?",
          html: `<div>Once selected, you wont be able to switch without losing all your data. <div>Your selection:</div><b>${this.settingType}</b>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, confirm!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.updateSettings({
              id: this.id,
              data: {
                settings: { ...this.form, isSet: true },
              },
            });
          } else {
            this.isLoading = false;
          }
        });
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.id = this.companyId;
    this.prePopulateForm();
    if (!this.form.hasIntegration) {
      this.disabled.push("integrationType");
    }
  },
};
</script>
<template>
  <div :class="wrapperClasses">
    <LoadingSpinner size="full" v-if="isLoading" />
    <h1 v-if="title">{{ title }}</h1>
    <div class="w-full" v-for="(field, pos) in fields" :key="pos">
      <div class="mr-2 mb-8">
        <AlertBox type="info">
          <div>
            {{ $t("settings.info") }}
          </div>
        </AlertBox>
      </div>
      <div class="flex flex-col gap-2 mb-8">
        <div class="flex items-center">
          <input
            v-on:input="
              (event) => updateValue('hasIntegration', { checked: false })
            "
            class="mr-2 cursor-pointer"
            type="radio"
            name="integrationType"
            value="0"
            id="has-integration-false"
            :checked="!form.hasIntegration"
          />
          <label for="has-integration-false" class="cursor-pointer">{{
            $t("settings.item1")
          }}</label>
        </div>
        <div class="flex items-center">
          <input
            v-on:input="
              (event) => updateValue('hasIntegration', { checked: true })
            "
            class="mr-2 cursor-pointer"
            type="radio"
            id="has-integration-true"
            name="integrationType"
            value="1"
            :checked="form.hasIntegration"
          />
          <label for="has-integration-true" class="cursor-pointer">{{
            $t("settings.item2")
          }}</label>
        </div>
      </div>
      <div class="flex-col flex my-4">
        <VueNextSelect
          v-if="form.hasIntegration"
          :options="selectableIntegration"
          v-model="form.integrationType"
          :close-on-select="true"
          :multiple="false"
        ></VueNextSelect>
      </div>
      <Button classes="w-1/2" @click.prevent="saveSettings">
        <LoadingSpinner size="sm" color="white" v-if="isLoading" />
        <span v-else>{{ $t("common.btn.continue") }}</span>
      </Button>
    </div>
  </div>
</template>
<style>
.vue-dropdown-item {
  margin: 0 !important;
}
.highlighted {
  background-color: #fbeee9 !important;
  width: 100%;
}
.selected {
  background-color: #df4d30 !important;
  width: 100%;
}
</style>
