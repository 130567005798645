<script>
//VUEX
import { mapActions, mapGetters } from "vuex";

//CONST
import { ROUTEPATHS } from "../../../constants";

//COMPONENTS
import Button from "../../design/Button.vue";
export default {
  name: "SecondStep",
  components: {
    Button,
  },
  data() {
    return {
      icons: {
        success: require("../../../assets/icons/success-check.svg"),
      },
    };
  },
  computed: {
    ...mapGetters({
      company: "company/data",
    }),
  },
  watch: {
    company(newValue) {
      if (newValue) this.$router.push(ROUTEPATHS.company.dashboard);
    },
  },
  methods: {
    ...mapActions({
      getCompany: "company/getCompany",
      getUser: "user/getUser",
    }),
    onSuccess() {
      this.$emit("completed");
    },
  },
};
</script>
<template>
  <div>
    <div class="flex items-center h-full">
      <img :src="icons.success" class="h-12 w-12 mr-4" />
      <h2 class="text-green-dark">
        {{ $t("login.company.onboarding.step2.successMsg") }}
      </h2>
    </div>
    <div class="my-4">
      {{ $t("login.company.onboarding.step2.description") }}
    </div>
    <Button classes="w-44 mt-4" @click.prevent="onSuccess">{{
      $t("login.company.onboarding.step2.btn")
    }}</Button>
  </div>
</template>
