<script>
//VUEX
import { mapGetters, mapActions } from "vuex";
//UTILS
import Error from "@/utils/Error";
import { EMAIL_REGEX } from "@/utils";

//COMPONENTS
import EditDetails from "@/components/EditDetails";
import LoadingSpinner from "@/components/design/LoadingSpinner.vue";
import Modal from "@/components/design/Modal.vue";
//SERIALIZER
import { EmployeeSerializer } from "@/serializer/EmployeeSerializer";

export default {
  name: "EmployeeCreate",
  components: {
    EditDetails,
    LoadingSpinner,
    Modal,
  },
  props: {
    employee: Object,
    companyId: String,
  },
  data() {
    return {
      error: [],
      id: null,
      form: {
        companyId: null,
        employeeId: "",
        companyName: "",
        userId: "",
        email: "",
      },
      updateDefaultDonations: false,
      fields: [
        {
          id: "email",
          label: "Email",
          type: "email",
        },
        {
          id: "employeeId",
          label: "Employee Id",
          type: "string",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/data",
      isLoading: "employee/isLoading",
      apiCallSuccess: "employee/success",
    }),
  },
  watch: {
    apiCallSuccess: {
      handler(newValue, oldValue) {
        if (!oldValue.store && newValue.store) {
          this.closeForm();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateEmployee: "employee/updateEmployee",
      createEmployee: "employee/createEmployee",
      getAllEmployees: "employee/getAllEmployees",
      resetEmployee: "employee/resetEmployee",
    }),

    resetForm() {
      this.form = {
        companyId: this.user.companyDetails.id,
        companyName: this.user.companyDetails.name,
        employeeId: "",
        userId: null,
        email: "",
      };
    },
    prePopulateForm() {
      this.id = this.employee ? this.employee.id : null;
      this.form = {
        companyId: this.user.companyDetails.id,
        companyName: this.user.companyDetails.name,
        employeeId: this.employee.companyDetails.employeeId,
        userId: this.employee.id ?? null,
        email: this.employee ? this.employee.email : "",
      };
    },
    updateValue(id, value) {
      this.form[id] = value;
    },
    validateInput() {
      if (!EMAIL_REGEX.test(this.form.email))
        this.error = [
          ...this.error,
          new Error("email", "Please provide a valid email address"),
        ];
      if (this.error.length > 0) return false;
      return true;
    },

    createOrUpdateEmployee() {
      this.error = [];
      if (this.validateInput()) {
        if (this.id) {
          this.updateEmployee({
            id: this.id,
            data: EmployeeSerializer.formatPostRequest(
              this.form,
              this.updateDefaultDonations
            ),
          });
        } else {
          this.createEmployee(EmployeeSerializer.formatPostRequest(this.form));
        }
      }
    },
    triggerDeletePopup() {
      this.deleteUser(this.id);
    },
    closeForm() {
      this.resetForm();
      this.$emit("closed");
    },
  },
  mounted() {
    this.form.employeeId = this.user.companyDetails.employeeId;
    this.form.companyId = this.user.companyDetails.id;
    this.form.companyName = this.user.companyDetails.name;
    if (this.employee) {
      this.prePopulateForm();
    }
  },
};
</script>
<template>
  <div class="md:w-96">
    <LoadingSpinner v-if="isLoading.store || isLoading.update" size="full" />
    <Modal v-else @close="() => this.$emit('closed')">
      <EditDetails
        :gridCols="1"
        :showLabelCancel="false"
        :title="
          this.employee && this.employee.id
            ? $t('employees.create.edit')
            : $t('employees.create.new')
        "
        :fields="fields"
        :form="form"
        :errorProp="error"
        @update="(values) => (this.form = values)"
        @submit="createOrUpdateEmployee"
        @cancel="closeForm"
      >
      </EditDetails>
    </Modal>
  </div>
</template>
