<script>
import BasicLayout from "@/components/layout/BasicLayout";
import BaseIcon from "@/components/BaseIcon.vue";
//THRID PARTY

export default {
  name: "Dashboard",
  components: {
    BasicLayout,
    BaseIcon,
  },
  props: {
    triggerToast: {
      type: Function,
      default: () => console.log("test"),
    },
  },
  data() {
    return {
      sections: [
        {
          icon: "profile",
          title: "Edit your profile",
          description: "Change your user profile data and company details.",
          iconWidth: 40,
        },
        {
          icon: "program",
          title: "Add & edit Programs",
          description:
            "Create or edit a program and intive or manage participants (employees) for a program.",
          iconWidth: 40,
        },
        {
          icon: "employees",
          title: "Add & edit Employees",
          description:
            "Add or edit employees individually or in a bulk upload. Once that is done you can add them to programs.",
          iconWidth: 40,
        },
        {
          icon: "charities",
          title: "Manage custom charities",
          description:
            "Add, edit or delete custom charities. Motivate your employees to suggest charities/projects that you can add.",
          iconWidth: 35,
          iconHeight: 50,
        },
        {
          icon: "data",
          title: "Export Data",
          iconWidth: 40,
        },
      ],
    };
  },
};
</script>

<template>
  <BasicLayout
    :title="$t('dashboard.title')"
    :subTitle="$t('dashboard.subTitle')"
  >
    <div class="flex flex-col gap-12 items-start lg:w-2/3">
      <div
        v-for="section in sections"
        :key="section.icon"
        class="flex gap-4 items-center"
      >
        <div class="flex flex-col items-start justify-start gap-4">
          <div class="flex gap-4 items-center">
            <BaseIcon
              :iconWidth="section.iconWidth"
              :iconHeight="section.iconHeight ?? null"
              :name="section.icon"
              :class="`${
                section.icon === 'program' ? 'text-white' : 'text-blue-dark'
              }`"
            />
            <div class="font-bold">{{ section.title }}</div>
          </div>
          <div v-if="section.description">
            {{ section.description }}
          </div>
          <div v-else>
            Once a month you can export your data that will help you to:
            <ul class="list-disc ml-4">
              <li>
                Import the donation amount per employee in your payroll tool
              </li>
              <li>
                Download donation amounts per charity for employees (base
                donations)
              </li>
              <li>
                Download donation amounts per charity for your company (matching
                donations)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </BasicLayout>
</template>
